import {
  ActiveUsersModel,
  AnalyticsModel,
  IChartTaskProgressModel,
  IProjectOverviewDetailsModel,
  OptionReport,
} from '@atlas-workspace/shared/models';

import { DefaultStateModel } from '../default-state';

export class ProjectOverviewStateModel extends DefaultStateModel {
  projectOverview!: IProjectOverviewDetailsModel | null;
}

export class TimelineProgressStateModel extends DefaultStateModel {
  timelineProgress!: IChartTaskProgressModel | null;
}

export class ActiveUsersStateModel extends DefaultStateModel {
  activeUsers!: ActiveUsersModel | null;
}

export class OptionReportsStateModel extends DefaultStateModel {
  optionReports!: OptionReport | null;
}

export class OverviewAnalyticsStateModel extends DefaultStateModel {
  analytics!: AnalyticsModel;
}
