import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTableModule } from '@atlas-workspace/data-table';
import { FileDragNDropModule, SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import {
  CustomTextEditorModule,
  EditorFileUploadModule,
  SharedFormModule,
  TextEditorMultiFileUploadModule,
} from '@atlas-workspace/shared/form';
import {
  TimePassedAgoModule,
} from '@atlas-workspace/shared/pipes';
import { ReclamationAdminDetailModule } from '@atlas-workspace/shared/reclamation';
import {
  CustomIconComponent, FirmIconComponent, HeaderIconComponent,
  NewestDocumentCardComponent,
  TableSortModule
} from '@atlas-workspace/shared/ui';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';

import { CreateThreadComponent } from './components/create-thread/create-thread.component';
import { EditListItemsModalComponent } from './components/edit-list-items-modal/edit-list-items-modal.component';
import { MentionsTextEditorModule } from './components/mentions-text-editor/mentions-text-editor.module';
import { ThreadAttachmentsComponent } from './components/thread-attachments/thread-attachments.component';
import { ThreadHeaderSearchComponent } from './components/thread-header-search/thread-header-search.component';
import { ThreadViewModule } from './components/thread-view/thread-view.module';
import { TimePassedFromMessageModule } from './pipes/time-passed-from-message.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedFormModule,
    ReactiveFormsModule,
    FormsModule,
    TableSortModule,
    DataTableModule,
    SharedDirectivesModule,
    EditorModule,
    CustomTextEditorModule,
    EditorFileUploadModule,
    NgbTooltipModule,
    TimePassedAgoModule,
    TimePassedFromMessageModule,
    FileDragNDropModule,
    ReclamationAdminDetailModule,
    ThreadViewModule,
    TextEditorMultiFileUploadModule,
    MentionsTextEditorModule,
    NewestDocumentCardComponent,
    CustomIconComponent,
    HeaderIconComponent,
    FirmIconComponent
  ],
  declarations: [
    CreateThreadComponent,
    ThreadAttachmentsComponent,
    ThreadHeaderSearchComponent,
    EditListItemsModalComponent,
  ],
  exports: [
    CreateThreadComponent,
    ThreadAttachmentsComponent,
    ThreadHeaderSearchComponent,
  ],
})
export class SharedThreadsModule {}
