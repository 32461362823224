import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { ConfirmDropdownComponent, SharedFormModule } from '@atlas-workspace/shared/form';
import {
  ContractorService,
  ModalFacadeService,
  ReclamationAdminService
} from '@atlas-workspace/shared/service';
import {
  CustomTooltipModule, FirmIconComponent, NewestDocumentCardComponent,
  NgbModalFooterComponent, NgbModalHeaderComponent,
  SimpleTabsComponent,
  TooltipComponent
} from '@atlas-workspace/shared/ui';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { ProtocolReclamationCreateComponent } from './protocol-reclamation-create/protocol-reclamation-create.component';
import { ProtocolReclamationEditComponent } from './protocol-reclamation-edit/protocol-reclamation-edit.component';
import { ReclamationAdminCreateComponent } from './reclamation-admin-create/reclamation-admin-create.component';
import { ReclamationAdminDeclineComponent } from './reclamation-admin-decline/reclamation-admin-decline.component';
import { ReclamationAdminDetailModule } from './reclamation-admin-detail/reclamation-admin-detail.module';

const components = [
  ReclamationAdminCreateComponent,
  ReclamationAdminDeclineComponent,
  ProtocolReclamationCreateComponent,
  ProtocolReclamationEditComponent,
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedFormModule,
    SharedDirectivesModule,
    ReclamationAdminDetailModule,
    NgbTooltipModule,
    TooltipComponent,
    CustomTooltipModule,
    NgbModalFooterComponent,
    NgbModalHeaderComponent,
    SimpleTabsComponent,
    NewestDocumentCardComponent,
    FirmIconComponent,
    ConfirmDropdownComponent
  ],
  providers: [ModalFacadeService, ReclamationAdminService, ContractorService],
})
export class SharedReclamationAdminModule {}
