<div *ngIf="headerData" class="modal-header">
  <div *ngIf="headerData.title" class="modal-header__title-wr">
    <h4 *ngIf="showTitle" class="modal-header__title ellipsis">
      {{ constructionPhoto ? ('Shared.Title.Construction_photo' | translate) : headerData.title }}
    </h4>
    <p *ngIf="headerData.subtitle" class="fz-12 modal-header__subtitle ellipsis" [class.text-black-810]="!isClient">
      {{ (headerData.subtitle | date: 'MMM d, y') + (constructionPhoto ? ' - ' + headerData.title : '') }}
    </p>
  </div>
  <div class="modal-actions">
    <atl-header-icon
      *ngIf="commentBtn"
      (click)="onToggleComment()"
      [icon]="isClient ? '/assets/comment-icon-black.svg' : '/assets/comment-icon-new.svg'"
      [ngbTooltip]="'Shared.Entity.Comment' | translate"
      [openDelay]="tooltipDelay"
      placement="bottom"
      triggers="hover touchstart"
      [class.client]="isClient"
    ></atl-header-icon>
    <ng-template [ngIf]="headerData.copyImage">
      <atl-header-icon
        [icon]="isClient ? '/assets/copy-icon-v2.svg' : '/assets/copy-link.svg'"
        [ngbTooltip]="'Shared.Description.Copy_image' | translate"
        [openDelay]="tooltipDelay"
        placement="bottom"
        triggers="hover touchstart"
        [class.client]="isClient"
        (click)="onCopyImage()"
      ></atl-header-icon>
    </ng-template>
    <a class="modal-actions__link" *ngIf="headerData.downloadLink" [href]="headerData.downloadLink" download>
      <atl-header-icon
        [icon]="isClient ? '/assets/download-icon-v2.svg' : '/assets/download-icon-new.svg'"
        [ngbTooltip]="'Shared.Entity.Download' | translate"
        [openDelay]="tooltipDelay"
        placement="bottom"
        triggers="hover touchstart"
        [class.client]="isClient"
      ></atl-header-icon>
    </a>
    <img src="/assets/separator.svg" alt="icon" class="ml-8 mr-8" />
    <atl-header-icon
      [icon]="isClient ? '/assets/close-icon-v2.svg' : '/assets/close_newest_gray.svg'"
      (click)="close()"
      [ngbTooltip]="'Alt.Close' | translate"
      [openDelay]="tooltipDelay"
      placement="bottom"
      triggers="hover touchstart"
      [class.client]="isClient"
    ></atl-header-icon>
  </div>
</div>

<div class="modal-body">
  <ng-content></ng-content>
</div>
