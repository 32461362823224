/* eslint-disable sonarjs/no-duplicate-string */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileValidators } from '@atlas-workspace/shared/form';
import {
  ETablePagination,
  FileModel,
  IFormUpdate,
  ImageModel,
  IRequestTaskTemplate,
  ITablePagination,
  TaskTemplate,
  TemplateUpdateModel,
} from '@atlas-workspace/shared/models';
import { AuthAdminService, DataTableHelperService, PaginationUtil } from '@atlas-workspace/shared/service';
import { environment } from '@environment-admin';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

@Injectable()
export class TemplatesService {
  public tasksPagination$ = new BehaviorSubject<ITablePagination | null>(null);
  public updatesPagination$ = new BehaviorSubject<ITablePagination | null>(null);

  private showSearch$ = new BehaviorSubject<boolean>(false);
  private showCreationButton$ = new BehaviorSubject<boolean>(false);

  public onSearch$ = new Subject<string>();
  public onCreationButton$ = new Subject<void>();
  public creationButtonTitle?: string;

  constructor(
    private http: HttpClient,
    private authAdminService: AuthAdminService,
    private tableService: DataTableHelperService
  ) {}

  get showSearch(): boolean {
    return this.showSearch$.value;
  }

  get showCreationButton(): boolean {
    return this.showCreationButton$.value;
  }

  displayCreationButton(translateKey: string): void {
    this.creationButtonTitle = translateKey;
    this.showCreationButton$.next(true);
  }

  hideCreationButton(): void {
    this.creationButtonTitle = undefined;
    this.showCreationButton$.next(false);
  }

  displaySearchControl(): void {
    this.showSearch$.next(true);
  }

  hideSearchControl(): void {
    this.showSearch$.next(false);
  }

  resetState(): void {
    this.tasksPagination$.next(null);
    this.updatesPagination$.next(null);
    this.showSearch$.next(false);
    this.showCreationButton$.next(false);
    this.onSearch$.next('');
    this.creationButtonTitle = undefined;
  }

  getTemplateTasks(): Observable<TaskTemplate[]> {
    return of<IRequestTaskTemplate[]>([
      {
        id: 1,
        name: 'Service quality #1',
        created: '6 oct 2020',
        created_by: 'Theresa Webb',
      },
      {
        id: 2,
        name: 'Service quality #2',
        created: '6 oct 2020',
        created_by: 'Theresa Webb',
      },
      {
        id: 3,
        name: 'Service quality #3',
        created: '6 oct 2020',
        created_by: 'Theresa Webb',
      },
      {
        id: 4,
        name: 'Service quality #4',
        created: '6 oct 2020',
        created_by: 'Theresa Webb',
      },
      {
        id: 5,
        name: 'Service quality #5',
        created: '6 oct 2020',
        created_by: 'Theresa Webb',
      },
      {
        id: 6,
        name: 'Service quality #6',
        created: '6 oct 2020',
        created_by: 'Theresa Webb',
      },
    ]).pipe(map((templates) => plainToClass(TaskTemplate, templates)));
  }

  public getTemplateUpdates(search = '', sort = '', paginate?: ITablePagination): Observable<TemplateUpdateModel[]> {
    const params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    return this.http
      .get<any>(`${environment.apiBaseUrl}api/v1/firms/${this.authAdminService.firm?.firmId}/update_templates`, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((result) => {
          if (result.headers) {
            const pagination: ITablePagination = {
              currentPage: PaginationUtil.convertPaginationType(result.headers, ETablePagination.CurrentPage),
              pageItems: PaginationUtil.convertPaginationType(result.headers, ETablePagination.PageItems),
              totalCount: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalCount),
              totalPages: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalPages),
            };
            this.updatesPagination$.next(pagination);
          }
        }),
        map((res) => res.body.data.update_templates),
        map((data: []) => plainToClass(TemplateUpdateModel, data))
      );
  }

  public createUpdateTemplate(formControls: IFormUpdate, draft: boolean | undefined): Observable<TemplateUpdateModel> {
    const body = this.createBodyFormData(formControls, draft);
    return this.http
      .post(`${environment.apiBaseUrl}api/v1/firms/${this.authAdminService.firm?.firmId}/update_templates`, body)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(TemplateUpdateModel, data))
      );
  }

  public deleteTemplateUpdate(id: number): Observable<unknown> {
    return this.http.delete(
      `${environment.apiBaseUrl}api/v1/firms/${this.authAdminService.firm?.firmId}/update_templates/${id}`
    );
  }

  public deleteTemplatesUpdate(ids: number[]): Observable<unknown> {
    const firmId = this.authAdminService.firm?.firmId;
    const httpOptions = {
      params: new HttpParams(),
      body: { ids: ids },
    };
    return this.http.delete(
      `${environment.apiBaseUrl}api/v1/firms/${firmId}/update_templates/batch_destroy`,
      httpOptions
    );
  }

  putTemplateUpdate(
    id: number | undefined,
    formControls: IFormUpdate,
    draft: boolean | undefined,
    removeFiles: (FileModel | ImageModel | File)[] = []
  ): Observable<TemplateUpdateModel> {
    const createBody = this.createBodyFormData(formControls, draft);
    const removeBody = this.removeBodyFormData(formControls, draft, removeFiles);
    const firmId = this.authAdminService.firm?.firmId ?? '';
    return this.http.put(`${environment.apiBaseUrl}api/v1/firms/${firmId}/update_templates/${id}`, createBody).pipe(
      mergeMap(() =>
        this.http.put(`${environment.apiBaseUrl}api/v1/firms/${firmId}/update_templates/${id}`, removeBody)
      ),
      map((res: any) => res.data),
      map((data) => plainToClass(TemplateUpdateModel, data))
    );
  }

  createBodyFormData(formControls: IFormUpdate, isDraft: boolean | undefined): FormData {
    const body = new FormData();
    body.append('update_template[name]', formControls.name);
    body.append('update_template[description]', formControls.text);
    body.append('update_template[user_type]', formControls.type);
    body.append('update_template[draft]', (isDraft ?? '').toString());
    formControls.document?.forEach((file: Partial<FileModel | ImageModel>) => {
      if (!file.id) {
        const formFile: File = file as File;
        if (FileValidators.isImage(formFile)) {
          body.append('update_template[images_attributes][][filename]', formFile, formFile.name);
        } else {
          body.append('update_template[file_resources_attributes][][filename]', formFile, formFile.name);
        }
      }
    });

    return body;
  }

  removeBodyFormData(
    formControls: IFormUpdate,
    isDraft: boolean | undefined,
    removeFiles: (FileModel | ImageModel | File)[] = []
  ): FormData {
    const body = new FormData();
    body.append('update_template[name]', formControls.name);
    body.append('update_template[description]', formControls.text);
    body.append('update_template[user_type]', formControls.type);
    body.append('update_template[draft]', (isDraft ?? '').toString());

    removeFiles.forEach((file: Partial<FileModel | ImageModel>) => {
      if (file.id) {
        const attr = FileValidators.isImage(file) ? 'images_attributes' : 'file_resources_attributes';
        body.append(`update_template[${attr}][][id]`, file.id.toString());
        body.append(`update_template[${attr}][][_destroy]`, 'true');
      }
    });
    return body;
  }
}
