export const sharedPreProdEnvironment = {
  production: true,
  appVersion: '0.1',
  apiBaseUrl: 'https://api.journeyapp.prod.scrij.com/',
  apiShowroomUrl: 'https://digital-showroom-api.dev.scrij.com/',
  environment: 'preprod',
  tinyMceApiKey: '3sz0v4q6pw6iew3ndhgk58gy8kccvujcm1nmtrmjaox21pmr',
  threadsWssUrl: 'wss://atlas-anycable.prod.scrij.com',
  googleApiKey: 'AIzaSyAyHQYUAe3Q3dq5giAaaQeGrr3XiXHiVHo',
  accessCode: 'F8341366D62E509243E0970EDD961D5572B22D3FE7C68F290308FFDDC9A197F5',
  analyticsIdClient: '',
  analyticsIdAdmin: '',
  mixpanelToken: '',
  apiClientUrl: 'https://client.journeyapp.prod.scrij.com/',
};
