import {HttpHeaders, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {share} from 'rxjs/operators';

const BD_NAME = 'TrayTrackingPostDB';
const TX_NAME = 'postrequest';

export enum EIndexKey {
  PersonId= 'person_id',
  AttendeesId = 'attendees_id'
}

const indexedName = {
  answersFieldId: {
    key: 'answers_field_id',
    value: 'protocol[answers_attributes][][field_id]'
  },
  answersId: {
    key: 'answers_id',
    value: 'protocol[answers_attributes][][id]'
  },
  otherRecipients: {
    key: 'other_recipients',
    value: 'protocol[other_recipients][]'
  },
  otherAttendees: {
    key: 'other_attendees',
    value: 'protocol[other_attendees]'
  },
  connectedId: {
    key: 'connected_id',
    value: 'protocol[connected_to_protocol_ids][]'
  },
  personId: {
    key: 'person_id',
    value: 'protocol[attendees_attributes][][person_id]'
  },
  attendeesId: {
    key: 'attendees_id',
    value: 'protocol[attendees_attributes][][id]'
  },
  attendeesDestroy: {
    key: 'attendees_destroy',
    value: 'protocol[attendees_attributes][][_destroy]'
  },
  attendeesSignature: {
    key: 'attendees_signature',
    value: 'protocol[attendees_attributes][][signature_required]'
  }
}

@Injectable({
  providedIn: 'root'
})
export class IndexedDbService {
  private message$ = new Subject<{success: boolean, message: string}>();

  get messageTransaction(): Observable<{success: boolean, message: string}> {
    return this.message$.asObservable().pipe(share());
  }

  private initOpenDB(): IDBOpenDBRequest {
    return indexedDB.open(BD_NAME);
  }

  private getStore(db:  IDBDatabase): IDBObjectStore {
    const tx = db.transaction(TX_NAME, 'readwrite');
    return tx.objectStore(TX_NAME);
  }

  public removePersonIdIndex(personId: number, key: EIndexKey, protocolId: number): void {
    const request = this.initOpenDB();
    request.onsuccess = (event: Event): void => {
      const db = (event.target as IDBOpenDBRequest).result;
      const tx = db.transaction(TX_NAME, 'readwrite');
      const store = tx.objectStore(TX_NAME);
      const fieldId = store.index(key);
      const req = fieldId.getAll(personId.toString());
      req.onsuccess = (e: Event): void => {
        const fieldIdRes = (e.target as IDBOpenDBRequest).result as unknown as any[];
        const findResult = fieldIdRes.find(item => item.url.includes(`meetings_protocols/${protocolId}`));
        if (findResult) {
          store.delete(findResult.id);
        }
      }
    }
  }

  public async initSaveData(request: HttpRequest<unknown>): Promise<void> {
    const isContentType = !(request.body instanceof FormData || request.body === null);
    const body: any = isContentType ? request.body :  await this.extractFormData(request.body);
    const header = this.getHeader(request.headers, isContentType);

    this.saveIntoIndexedDb(request.urlWithParams, request.method, header, body, !isContentType).then();
  }

  private async saveIntoIndexedDb(reqUrl: string, method: string, header: any, payload: any, isFormData: boolean): Promise<void> {
    const myRequest: any = {};
    myRequest.url = reqUrl;
    myRequest.method = method;
    myRequest.isFormData = isFormData;
    myRequest.authHeader = JSON.stringify(header);

    const indexDB = this.initOpenDB();

    indexDB.onsuccess = (event: Event): void => {
      this.saveTransaction(event, myRequest, payload);
    }

    indexDB.onupgradeneeded = (event) => {
      const db = (event.target as any).result;
      db.onerror = () => {
        console.log("Why didn't you allow my web app to use IndexedDB?!");
      };

      if (!db.objectStoreNames.contains(TX_NAME)) {
        const objectStore = db.createObjectStore(TX_NAME, {keyPath: 'id', autoIncrement: true});
        objectStore.createIndex(indexedName.answersFieldId.key, indexedName.answersFieldId.key, { unique: false });
        objectStore.createIndex(indexedName.answersId.key, indexedName.answersId.key, { unique: false });
        objectStore.createIndex(indexedName.otherRecipients.key, indexedName.otherRecipients.key, { unique: false });
        objectStore.createIndex(indexedName.otherAttendees.key, indexedName.otherAttendees.key, { unique: false });
        objectStore.createIndex(indexedName.connectedId.key, indexedName.connectedId.key, { unique: false });
        objectStore.createIndex(indexedName.personId.key, indexedName.personId.key, { unique: false });
        objectStore.createIndex(indexedName.attendeesId.key, indexedName.attendeesId.key, { unique: false });
        objectStore.createIndex(indexedName.attendeesSignature.key, indexedName.attendeesSignature.key, { unique: false });
      } else {
        db.objectStoreNames.get(TX_NAME);
      }

      this.saveTransaction(event, myRequest, payload);
    }

    indexDB.onerror = (e) => {
      console.log('ERROR INIT DB', e);
    }
  }

  private saveTransaction(event: Event, myRequest: any, payload: any): void {
    const db = (event.target as IDBOpenDBRequest).result;

    //TODO: reproduce the case and check
    // if (!db.objectStoreNames.contains(TX_NAME)) {
    //   indexedDB.deleteDatabase(BD_NAME);
    //   const indexDB = this.initOpenDB();
    //   indexDB.onsuccess = (e: Event): void => {
    //     this.saveTransaction(e, myRequest, payload);
    //   }
    //   return;
    // }

    const tx = db.transaction(TX_NAME, 'readwrite');
    const store = tx.objectStore(TX_NAME);

    if (myRequest.isFormData) {
      myRequest.payload = payload;
      myRequest[indexedName.answersFieldId.key] = payload[0][indexedName.answersFieldId.value] || 0;
      myRequest[indexedName.answersId.key] = payload[0][indexedName.answersId.value] || 0;
      myRequest[indexedName.personId.key] = payload[0][indexedName.personId.value] || 0;
      myRequest[indexedName.otherRecipients.key] = +payload.some((item: any) => item[indexedName.otherRecipients.value]);
      myRequest[indexedName.otherAttendees.key] = +payload.some((item: any) => item[indexedName.otherAttendees.value]);
      myRequest[indexedName.connectedId.key] = +payload.some((item: any) => item[indexedName.connectedId.value]);
      myRequest[indexedName.attendeesId.key] = payload.some((item: any) =>
        item[indexedName.attendeesId.value]) && payload.some((item: any) => item[indexedName.attendeesDestroy.value])
        ? payload[0][indexedName.attendeesId.value] : 0;
      myRequest[indexedName.attendeesSignature.key] = payload.some((item: any) =>
        item[indexedName.attendeesId.value]) && payload.some((item: any) => item[indexedName.attendeesSignature.value])
        ? payload[0][indexedName.attendeesId.value] : 0;
    } else {
      myRequest.payload = payload ? JSON.stringify(JSON.parse(payload)) : [];
    }

    let searchIndex = '';
    if (+myRequest[indexedName.answersFieldId.key]) searchIndex = indexedName.answersFieldId.key;
    if (+myRequest[indexedName.answersId.key]) searchIndex = indexedName.answersId.key;
    if (+myRequest[indexedName.otherRecipients.key]) searchIndex = indexedName.otherRecipients.key;
    if (+myRequest[indexedName.otherAttendees.key]) searchIndex = indexedName.otherAttendees.key;
    if (+myRequest[indexedName.connectedId.key]) searchIndex = indexedName.connectedId.key;
    if (+myRequest[indexedName.attendeesSignature.key]) searchIndex = indexedName.attendeesSignature.key;

    if (searchIndex) {
      const fieldId = store.index(searchIndex);
      const value = myRequest[searchIndex];
      const req = fieldId.getAll(value);
      req.onsuccess = (e) => {
        const fieldIdRes = (e.target as IDBRequest).result;
        const findResult = fieldIdRes.find((item: any) => item.url === myRequest.url);
        if (findResult) {
          findResult.payload = myRequest.payload;
          const resPut = store.put(findResult);
          resPut.onerror = (error: Event) => {
            console.log('ERROR PUT', error);
          }
          return;
        }
        const resAdd = store.add(myRequest);
        resAdd.onerror = (error: Event) => {
          console.log('ERROR ADD FOR INDEX', error);
        }
      };
    } else {
      const resAdd = store.add(myRequest);
      resAdd.onerror = (error: Event) => {
        console.log('ERROR ADD NO INDEX', error);
      }
    }
  }

  public sendOfflinePostRequestsToServer(): void {
    const request = this.initOpenDB();

    request.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      const tx = db.transaction(TX_NAME, 'readwrite');
      const store = tx.objectStore(TX_NAME);

      const allKeys = store.getAllKeys()
      allKeys.onsuccess = () => {
        if (allKeys.result && allKeys.result.length > 0) {
          this.message$.next({success: false, message: 'SW.Message.Start'});
          const keys: IDBValidKey[] = allKeys.result;
          this.sendFetchRequestsToServer(keys, db);
        }
      };
    };
  }

  private sendFetchRequestsToServer(keys: IDBValidKey[], db: IDBDatabase): void {
    const store = this.getStore(db);
    const data = store.get(keys[0]);
    data.onsuccess = () => {
      const record = data.result;
      const payload = record.isFormData ? this.formDataPayload(record.payload) : record.payload;
      fetch(record.url, {
        method: record.method,
        headers: JSON.parse(record.authHeader),
        body: payload
      }).then(() => {
        this.responseProcessing(keys, db);
      }).catch(e => {
        if (!navigator.onLine) return;
        console.log('ERROR FETCH', e);
        this.responseProcessing(keys, db);
      });
    };

    data.onerror = (e) => {
      console.log('ERROR GET DATA', e);
    }
  }

  private responseProcessing(keys: IDBValidKey[], db: IDBDatabase): void {
    const store = this.getStore(db);
    const remove = store.delete(keys[0]);
    keys.splice(0, 1);
    if (!keys.length) {
      this.sendOfflinePostRequestsToServer();
      this.message$.next({success: true, message: 'SW.Message.Complete'});
    } else {
      this.sendFetchRequestsToServer(keys, db);
    }

    remove.onerror = (e) => {
      console.log('ERROR REMOVE DATA', e);
    }
  }

  private getHeader(header: HttpHeaders, isContentType = false): any {
    const authHeader: any = {};
    const headerKey = ['Access-Token', 'Client', 'Uid'];

    headerKey.forEach(key => {
      authHeader[key] = header.get(key);
    });

    if (isContentType) {
      authHeader['Content-Type'] = 'application/json'
    }
    return authHeader;
  }

  private async extractFormData(formData: FormData | null): Promise<any> {
    if (!formData) return [];

    const dataPromises: Promise<any>[] = [];

    // @ts-ignore
    for (const [key, value] of formData.entries()) {
      if (key.includes('[filename]')) {
        const fileData = {
          buffer: await (value as File).arrayBuffer(),
          name: (value as File).name,
          type: (value as File).type,
        };
        dataPromises.push(Promise.resolve({ [key]: fileData }));
      } else {
        dataPromises.push(Promise.resolve({ [key]: value }));
      }
    }
    return await Promise.all(dataPromises);
  }

  private formDataPayload(data: any): FormData {
    const fd = new FormData();
    for (const key in data) {
      const k = Object.keys(data[key])[0];
      if (k.includes('[filename]')) {
        const {buffer, name, type} = data[key][k];
        const blob = new Blob([buffer], {type});
        const file = new File([blob], name, {type});
        fd.append(k, file);
      } else {
        fd.append(k, data[key][k]);
      }
    }
    return fd;
  }
}
