import { Blockable } from '@agilie/angular-helpies/decorators';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapterService } from '@atlas-workspace/shared/form';
import { ModalHelpersService } from '@atlas-workspace/shared/modals';
import {
  acceptedGlobalExtensions,
  AdminProjectModel,
  ContractorCompany,
  EAccessTag,
  EFirmRoles,
  EProjectRoles,
  EReclamationStatusKey,
  FileModel,
  FirmContractorModel,
  FloorModel,
  ImageModel,
  IMark,
  IReclamationCategory,
  IReclamationCategoryTypes,
  ITablePagination,
  PreloadedFile,
  ProductModel,
  ReclamationsModel,
  reclamationStatusList,
  TReclamationsModelFormGroup,
  UnitFloorModel,
  UnitUserModel,
} from '@atlas-workspace/shared/models';
import {
  AdminProfileService,
  AuthAdminService,
  CompaniesService,
  ContractorService, NetworkService,
  PaginationUtil,
  ProjectMembersService,
  RandomColorService,
  ReclamationAdminService,
  ReclamationHelperService
} from '@atlas-workspace/shared/service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { plainToClass } from 'class-transformer';
import dayjs from 'dayjs';
import { EMPTY, forkJoin, Observable, of, Subscription, zip } from 'rxjs';
import { catchError, expand, map, mergeMap, reduce, take } from 'rxjs/operators';

import { createReclamationStatusList } from '../../../helpers/reclamations.helper';

export interface IWrapFile {
  filename: FileModel | ImageModel;
  position: number;
}

@UntilDestroy()
@Component({
  selector: 'atl-reclamation-admin-create',
  templateUrl: './reclamation-admin-create.component.html',
  styleUrls: ['./reclamation-admin-create.component.scss'],
  providers: [CompaniesService],
})
export class ReclamationAdminCreateComponent implements OnInit, AfterViewInit {
  @Input() modalRef!: NgbModalRef;
  @Input() projectId!: string;
  @Input() protocolId: number | undefined;
  @Input() protocolUnitId: number | undefined;
  @Input() isLoading = false;
  @Input() unitRedirect = false;
  @Input() customUnitId?: number;
  @Input() modalMod = true;

  @Input() markData: IMark[] | undefined;

  @Output() private readonly createReclamationEvent = new EventEmitter<Partial<ReclamationsModel>>();
  @Output() private readonly redirect = new EventEmitter();

  public isLoadingFiles = false;
  public fileLoading = false;
  public isDraftLoading = false;

  public form!: FormGroup;
  public today: Date = new Date();
  readonly acceptedExtensions = acceptedGlobalExtensions;
  readonly createReclamationStatusList = createReclamationStatusList;
  public readonly statusList = reclamationStatusList;

  private unitId!: number;
  public units: UnitUserModel[] = [];
  public categories: any[] = []; // type to be announced
  public types: any[] = []; // type to be announced
  public rooms: any[] = []; // type to be announced
  public contractors: (ContractorCompany | FirmContractorModel)[] = [];
  public products: Partial<ProductModel>[] = [];
  public users: AdminProjectModel[] = [];
  public floorPlanData!: UnitFloorModel[] | FloorModel[] | undefined;
  public selectedUnit!: UnitUserModel;
  public attachmentsCount = 0;
  public attachmentsAddedCount = 0;
  public preloadedFiles: PreloadedFile[] = [];
  public files: File[] = [];

  public mark: IMark[] = [];
  private readonly daysDelay = 21;
  public defaultDueDate = dayjs(dayjs().add(this.daysDelay, 'day')).format('D.MM.YYYY');

  private readonly perPage = 50;
  public readonly longNameTruncate = 50;
  public readonly longEmailTruncate = 50;
  public readonly tooltipDelay = 500;
  private formValue!: Partial<TReclamationsModelFormGroup>;
  public isOnline = true;

  constructor(
    protected reclamationService: ReclamationAdminService,
    protected fb: FormBuilder,
    protected authService: AuthAdminService,
    protected randomColorService: RandomColorService,
    protected route: ActivatedRoute,
    protected dateAdapter: DateAdapterService,
    protected router: Router,
    protected cdr: ChangeDetectorRef,
    protected renderer: Renderer2,
    protected reclamationHelperService: ReclamationHelperService,
    protected contractorService: ContractorService,
    protected companiesService: CompaniesService,
    protected profileService: AdminProfileService,
    protected modalHelpersService: ModalHelpersService,
    protected readonly network: NetworkService,
    protected readonly projectMembersService: ProjectMembersService
  ) {}

  ngOnInit(): void {
    if (!this.projectId) {
      this.projectId = this.route.snapshot?.parent?.parent?.parent?.params?.projectId;
    }
    this.initForm();
    if (this.protocolId && this.protocolUnitId) {
      this.initProtocolUnit();
    }
    this.initFormListeners();
    if (this.customUnitId) {
      this.unitId = this.customUnitId as number;
      this.form.controls.unit.setValue([{ id: this.unitId }]);
    }
    this.networkStatus();
    this.cdr.detectChanges();
  }

  private networkStatus(): void {
    this.network.isOnline.pipe(untilDestroyed(this)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.getUnits();
      this.getUsers();
      this.getCategories();
      this.getProfile();
    });
  }

  private initProtocolUnit(): void {
    this.unitId = this.protocolUnitId as number;
    this.form.controls.unit.setValue([{ id: this.unitId }], { emitEvent: false });
    this.getUnitDetails(this.unitId);
  }

  ngAfterViewInit(): void {
    try {
      const textArea = this.renderer.selectRootElement('#expanded-textarea');
      if (textArea) {
        textArea.focus();
      }
    } catch (error) {
      catchError(() => of(null));
    }
  }

  get enableSaveAsDraft(): number {
    return (
      this.form.get('unit')?.value.length &&
      (this.form.get('type')?.value.length || this.form.get('description')?.value)
    );
  }

  public initForm(): void {
    this.form = this.fb.group(<TReclamationsModelFormGroup>{
      status: [[createReclamationStatusList[0]], Validators.required],
      contractor: [null],
      description: ['', [Validators.required, Validators.maxLength(500)]],
      unit: [[], Validators.required],
      category: [{ value: [], disabled: false }, [Validators.required]],
      type: [{ value: [], disabled: true }, [Validators.required]],
      room: [{ value: [], disabled: true }],
      product: [{ value: [], disabled: true }],
      responsible: [[]],
      reported: [{ value: this.today, disabled: true }],
      dueDate: ['', []],
      attachments: [[], []],
      floorId: [null, []],
      floorType: ['', []],
      pointX: [null, []],
      pointY: [null, []],
    });
  }

  private initFormListeners(): void {
    this.form
      .get('unit')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((v: any[]) => {
        if (v.length) {
          if (!this.form.get('responsible')?.value.length) {
            this.getResponsibleForRecl(v[0].id, undefined)
          }
          this.unitId = v[0].id;
          this.floorPlanData = [];
          this.getUnitDetails(v[0].id);
          this.form.get('room')?.enable();
          this.form.get('room')?.setValue([]);
          this.form.get('category')?.enable({ emitEvent: false });
          this.form.get('product')?.enable();
          this.form.get('product')?.setValue([]);
        } else {
          this.form.get('room')?.disable();
          this.form.get('room')?.setValue([]);
          this.rooms = [];
          this.form.get('product')?.disable();
          this.form.get('product')?.setValue([]);
          this.products = [];
        }
      });

    this.form
      .get('category')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((v: any[]) => {
        if (v.length) {
          if (!this.form.get('responsible')?.value.length) {
            this.getResponsibleForRecl(undefined, v[0].id)
          }
          this.types = this.categories.find(c => c.id === v[0].id)?.types;
          if (this.types.length === 1) this.form.get('type')?.setValue(this.types);
          this.form.get('type')?.enable({ emitEvent: false });
          this.form.get('type')?.setValue([], { emitEvent: false });
        } else {
          this.form.get('type')?.disable();
          this.types = [];
          this.form.get('type')?.setValue([]);
        }
      });
  }

  getResponsibleForRecl(unitId?: number, categoryId?: number): void {
    this.reclamationService.getResponsibleForReclamation(+this.projectId, unitId, categoryId).pipe(take(1)).subscribe((res) => {
      if (res) {
        this.form.get('responsible')?.setValue([res]);
      }
    })
  }

  setAttachmentCount(e: File[]): void {
    this.attachmentsCount = e.length;

    if (this.attachmentsCount === this.attachmentsAddedCount) {
      this.fileLoading = false;
      this.attachmentsAddedCount = 0;
    }
  }

  redirectToUnit(): void {
    if (this.modalMod) {
      this.unitRedirect = true;
      const url = `/base-layout/projects/specific_project/${this.projectId}/units/view/units?unitId=${this.unitId}`;
      void this.router.navigateByUrl(url);
    } else {
      this.modalRef.dismiss();
      this.redirect.emit();
    }
  }

  private getUnits(page: number = 1, allUnits: UnitUserModel[] = []): void {
    if (!this.isOnline) return;
    this.reclamationService
      .getUnits(this.projectId, page)
      .pipe(untilDestroyed(this))
      .subscribe((units: UnitUserModel[]) => {
        allUnits = allUnits.concat(units);
        if (units.length === this.perPage) {
          this.getUnits(page + 1, allUnits);
        } else {
          this.units = allUnits;
        }
      });
  }

  protected getUnitDetails(id: number): void {
    this.reclamationService
      .getUnitDetails(this.projectId, id)
      .pipe(untilDestroyed(this))
      .subscribe((unit) => {
        this.selectedUnit = unit;
        this.getRooms(unit);
        this.getFloorPlan(unit);
      });
  }

  private getCategories(): void {
    this.reclamationService
      .getCategories(true)
      .pipe(untilDestroyed(this))
      .subscribe((categories: IReclamationCategory[]) => {
        this.categories = categories;
      });
  }

  private getProfile(): void {
    this.profileService.profile.pipe(take(1)).subscribe((member) => {
      if (member?.role !== EFirmRoles.Contractor) {
        this.getContractorFieldData();
      }
    });
  }

  private getContractors(id?: number): Observable<FirmContractorModel[]> {
    const perPage = 20;
    let page = 1;
    const contractorMembers: FirmContractorModel[] = [];
    return this.contractorService.getFirmContractors(page, perPage, id).pipe(
      untilDestroyed(this),
      expand((data) => {
        if (data.length === perPage) {
          ++page;
          return this.contractorService.getFirmContractors(page, perPage);
        }
        return EMPTY;
      }),
      map((val) => val.map((v) => ({ ...v, id: v.adminId.toString() +'_'+ + v.companyId }))),
      map((data) => {
        contractorMembers.push(...data);
        return contractorMembers;
      })
    );
  }

  private getCompanies(): Observable<ContractorCompany[]> {
    return this.companiesService.getCompaniesList(1, 1000, undefined, undefined, true).pipe(
      untilDestroyed(this),
      map((data) => data.filter((d) => d.firstKeyContact))
    );
  }

  private getContractorFieldData(id?: number): void {
    forkJoin({
      companies: this.getCompanies(),
      contractors: this.getContractors(id),
    })
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.contractors = [...data.companies, ...data.contractors];

        const contractor = this.contractors.filter((c) => {
          // @ts-ignore
          if (c?.adminId && c?.companyId) {
            // @ts-ignore
            return (
              // @ts-ignore
              c.adminId === this.reclamation?.contractor?.id && c.companyId === this.reclamation?.contractorCompany?.id
            );
          }
          return false;
        });
        if (contractor?.length) {
          this.form.get('contractor')?.setValue(contractor[0]);
        }
      });
  }

  private getTypes(categoryId: number): void {
    this.reclamationService
      .getTypes(categoryId)
      .pipe(untilDestroyed(this))
      .subscribe((types: IReclamationCategoryTypes[]) => {
        this.types = types;
        if (this.types.length === 1) this.form.get('type')?.setValue(this.types);
      });
  }

  private getRooms(unit_user: UnitUserModel): void {
    this.rooms = [];
    if (!unit_user.layoutType) return;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.rooms = unit_user.rooms!;
  }

  getProjectAdminsRequest(pagination: ITablePagination | undefined): Observable<AdminProjectModel[]> {
    return this.projectMembersService.getProjectAdmins(
      this.projectId.toString(),
      undefined,
      undefined,
      pagination,
      [EAccessTag.Reclamations],
      [EProjectRoles.Custom, EProjectRoles.ProjectOwner, EProjectRoles.ProjectAdmin, EFirmRoles.Admin, EFirmRoles.PrimaryOwner],
      true
    );
  }

  private getUsers(): void {
    const usersPagination = { ...PaginationUtil.defaultPagination };
    this.getProjectAdminsRequest(usersPagination)
      .pipe(
        untilDestroyed(this),
        expand((admins) => {
          if (admins.length === usersPagination.pageItems) {
            usersPagination.currentPage++;
            return this.getProjectAdminsRequest(usersPagination);
          }
          return EMPTY;
        }),
        map((users: AdminProjectModel[]) =>
          users.map((x) => {
            const name = x.name || x.email;
            const { iconBorderColor, iconColor } = this.randomColorService.getUserColors(name, 55, 50, true);
            return {
              ...x,
              name,
              color: iconColor,
              borderColor: iconBorderColor,
            };
          })
        ),
        reduce((allAdmins: AdminProjectModel[], currentAdmins: AdminProjectModel[]) => allAdmins.concat(currentAdmins), [])
      )
      .subscribe((users) => {
        this.users = users;
      });
  }

  private getFloorPlan(unit_user: UnitUserModel): void {
    this.floorPlanData = [];
    this.floorPlanData = unit_user.mixinFloors;
    if (this.floorPlanData?.some(floor => floor?.plan)) {
      this.form.get('pointX')?.setValidators(Validators.required);
      this.form.get('pointY')?.setValidators(Validators.required);
    } else {
      this.form.get('pointX')?.removeValidators(Validators.required);
      this.form.get('pointY')?.removeValidators(Validators.required);
    }
  }

  public onRemoveSelectedItem(control: string, item: any, compareProp: string = 'id'): void {
    const items: any[] = this.form.get(control)?.value;
    const foundIndex = items.findIndex((a) => a[compareProp] === item[compareProp]);
    if (foundIndex !== -1) {
      items.splice(foundIndex, 1);
      this.form.get(control)?.setValue([...items]);
    }
  }

  public setMark(markArr: IMark[]): void {
    this.mark = markArr;
    this.form.patchValue({
      floorId: markArr.length ? markArr[0].floorId : null,
      floorType: markArr.length ? markArr[0].floorType : null,
      pointX: markArr.length ? markArr[0].mark[0].x : null,
      pointY: markArr.length ? markArr[0].mark[0].y : null,
    });
  }

  removeExistingFile(index: number): void {
    this.form.get('attachments')?.value.splice(index, 1);
    this.updateFormAttachmentsPos();
  }

  updateFilePositions(files: (FileModel | ImageModel)[]): void {
    const newestFiles: PreloadedFile[] = [];
    const oldFiles = this.form.get('attachments')?.value ?? [];
    files.forEach((f: FileModel | ImageModel, index: number) => {
      const foundIndex = oldFiles.findIndex((file: PreloadedFile) => file.title === f.name);
      if (~foundIndex) {
        oldFiles[foundIndex].position = index + 1;
        newestFiles.push(oldFiles[foundIndex]);
      }
    });
    this.form.get('attachments')?.setValue(newestFiles);
  }

  updateFormAttachmentsPos(): void {
    const arr = this.form.get('attachments')?.value.map((file: IWrapFile, index: number) => {
      return {
        filename: file.filename,
        position: index + 1,
      };
    });
    this.form.get('attachments')?.setValue(arr);
  }

  private createFormData(draft = false): FormData {
    this.formValue = this.form.getRawValue();
    const due_date = this.dateAdapter.toModelYYYYMMDD(this.formValue.dueDate);

    const body = new FormData();

    if (this.protocolId) {
      body.append('reclamation[protocol_id]', this.protocolId.toString());
    }
    if (this.formValue.contractor) {
      if (this.formValue.contractor instanceof ContractorCompany && this.formValue.contractor.defaultRecipient ) {
        body.append('reclamation[contractor_id]', String(this.formValue.contractor.defaultRecipient.id));
        body.append('reclamation[contractor_company_id]',  String(this.formValue.contractor.id));
      } else if (this.formValue.contractor?.firstKeyContact) {
        body.append('reclamation[contractor_id]', this.formValue.contractor.firstKeyContact.mainAdminId);
        body.append('reclamation[contractor_company_id]', this.formValue.contractor.id);
      } else {
        body.append('reclamation[contractor_id]', this.formValue.contractor.adminId);
        body.append('reclamation[contractor_company_id]', this.formValue.contractor.companyId);
      }
    }
    body.append('reclamation[status]', this.formValue.status[0].value);
    body.append('reclamation[description]', this.formValue.description);
    body.append('reclamation[unit_id]', this.formValue.unit[0]?.id);
    body.append('reclamation[type_id]', this.formValue.type[0]?.id || '');
    body.append('reclamation[room_id]', this.formValue.room[0]?.id || '');
    body.append('reclamation[wishlist_item_id]', this.formValue.product[0]?.id || '');
    body.append('reclamation[rem_responsible_in_hand_mode]', 'true');
    body.append('reclamation[responsible_id]', this.formValue.responsible[0]?.id || '');
    body.append('reclamation[due_date]', due_date || '');
    if (!this.markData) {
      if (this.formValue.floorId && this.formValue.floorType) {
        body.append('reclamation[floor_id]', this.formValue.floorId);
        body.append('reclamation[floor_type]', this.formValue.floorType);
        body.append('reclamation[point_x]', this.formValue.pointX);
        body.append('reclamation[point_y]', this.formValue.pointY);
      }
    } else {
      if (!this.markData.length) {
        return body;
      }

      this.markData.forEach((el: IMark) => {
        body.append('reclamation[floor_id]', el.floorId.toString());
        body.append('reclamation[floor_type]', el.floorType.toString());
        const [mark] = el.mark;
        body.append('reclamation[point_x]', mark.x.toString());
        body.append('reclamation[point_y]', mark.y.toString());
      });
    }

    if (draft) {
      body.append('reclamation[status]', EReclamationStatusKey.Draft);
    }

    this.formValue.attachments?.forEach((file: PreloadedFile | File) => {
      if (this.isOnline && file instanceof PreloadedFile) {
        body.append('reclamation[file_resources_attributes][][filename_remote_url]', file.link);
        body.append('reclamation[file_resources_attributes][][position]', file.position.toString());
      } else {
        body.append('reclamation[file_resources_attributes][][filename]', file as File);
      }
    });

    return body;
  }

  @Blockable()
  public createReclamation(draft = false): Subscription {
    this.isLoading = true;
    if (draft) {
      this.isDraftLoading = true;
    }
    return this.reclamationService
      .createProjectReclamation(this.projectId, this.createFormData(draft))
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          this.createReclamationEvent.emit(response);
          this.modalRef.close();
          this.isLoading = false;
          this.isDraftLoading = false;
          this.reclamationHelperService.triggerUnitListCounter();
        },
        error: () => {
          this.isLoading = false;
          this.isDraftLoading = false;
          if (!navigator.onLine) {
            if (this.markData) {
              this.formValue.floorId = this.markData[0].floorId;
              this.formValue.floorType = this.markData[0].floorType;
              this.formValue.floorNumber = this.markData[0].floorNumber;
              this.formValue.pointX = this.markData[0].mark[0].x;
              this.formValue.pointY = this.markData[0].mark[0].y;
            }
            this.createReclamationEvent.emit(this.formValue);
            this.modalRef.close();
          }
      }});
  }

  public openPreview(index: number): void {
    const files = this.form.controls.attachments.value;
    this.modalHelpersService.previewDocument({
      ...files[index],
      fileExtension: files[index].format,
      name: files[index].title,
      createdAt: files[index].expiredAt,
      fileName: {
        url: files[index].link,
        downloadUrl: files[index].link,
      },
    });
  }

  public removeTempFile(index: number): void {
    this.preloadedFiles.splice(index, 1);
  }

  public tempFileUpload(files: File[]): void {
    if (!this.isOnline) {
      this.files = [...this.files, ...files];
      this.form.get('attachments')?.setValue(this.files);
      this.isLoadingFiles = false;
      return;
    }
    this.isLoadingFiles = true;
    const requests = files.map((file) => {
      return this.reclamationService.generateDocumentUrlsS3(file);
    });
    zip(...requests)
      .pipe(
        mergeMap((values) => {
          const uploads = values.map((value, index) => {
            this.preloadedFiles.push(this.buildFile(value[0].publicUrl, files[index], index + 1));
            return this.reclamationService.uploadFileToS3(value[0], files[index]);
          });
          return zip(...uploads);
        }),
        take(1)
      )
      .subscribe(() => {
        this.form.get('attachments')?.setValue(this.preloadedFiles);
        this.isLoadingFiles = false;
      });
  }

  private buildFile(url: string, file: File, index: number): PreloadedFile {
    return plainToClass(PreloadedFile, {
      id: null,
      format: file.type,
      expired_at: new Date(),
      link: url,
      size: file.size,
      title: file.name,
      position: index,
    });
  }
}
