<div class="newest-input-image-info__header position-relative">
  <div class="newest-input-image-info__header-cover">
    <ng-container *ngIf="imagePreview; else defaultImage">
      <img class="newest-input-image-info__header-cover__img" [src]="imagePreview" alt="Project cover" />
    </ng-container>
    <ng-template #defaultImage>
      <label id="cy-change-cover">
        <div atlFileDragDrop (dropEvent)="cropNewImage($event)" class="newest-input-image-info__header-cover__default">
          <h3>{{ 'Project.Cover.Title' | translate }}</h3>
          <p>
            {{ 'Project.Cover.Description' | translate }}
            <span>
              <span>{{ 'Description._browse_files' | translate }}</span>
            </span>
          </p>
        </div>
        <ng-container *ngTemplateOutlet="FileInput"></ng-container>
      </label>
    </ng-template>
  </div>

  <div class="newest-input-image-info__header-cover__options">
    <div
      class="newest-input-image-info__item-menu"
      atlClickOutside
      *ngIf="imagePreview"
      (click)="onToggleMenu()"
      (clickOutside)="onCloseMenu()"
    >
      <label>
        <span>{{ 'Button.Edit_cover' | translate }}</span>
      </label>

      <div class="newest-input-image__menu-actions" *ngIf="imagePreview && isShowModal">
        <label (click)="$event.stopPropagation()">
          <div class="newest-input-image__menu-actions-item">
            <div>
              <span>{{ 'Title.Upload_image' | translate }}</span>
              <ng-container *ngTemplateOutlet="FileInput"></ng-container>
            </div>
          </div>
        </label>

        <div class="newest-input-image__menu-actions-item" (click)="cropCurrentImage()">
          <div>{{ 'Shared.Entity.Crop_image' | translate }}</div>
        </div>
        <div
          class="newest-input-image__menu-actions-item newest-input-image__menu-actions-item--deletion"
          (click)="removeFile()"
        >
          <div>
            {{ 'Button.Remove_image' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #FileInput>
  <input
    #inputFileRef
    id="file-input"
    type="file"
    name="uploadImage"
    class="d-none"
    (change)="cropNewImage($event)"
    accept="{{ imagesDefaultListOfMimeTypes }}"
  />
</ng-template>
