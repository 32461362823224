import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import {DocumentModel, EDocumentType, IBreadCrumbs} from '@atlas-workspace/shared/models';
import { ProjectDocumentsService } from '@atlas-workspace/shared/service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { MoveToModalComponent } from '../move-to-modal/move-to-modal.component';

// ToDO: move to shared models, since it is duplicated in admin documents.
enum EFilterDocument {
  ToBuyers = 'to_buyers',
  AllUnits = 'all_units',
  UnitGroups = 'unit_groups',
  Units = 'units',
  Internal = 'internal',
}

type FilterTitles = {
  [key in EFilterDocument]: string;
};

@Component({
  selector: 'atl-move-to-modal-v3',
  templateUrl: './move-to-modal-v3.component.html',
  styleUrls: ['./move-to-modal-v3.component.scss'],
})
export class MoveToModalV3Component extends MoveToModalComponent implements OnInit, OnChanges {
  @Input() moveSelectedParentIds: number | null = null;
  @Input() maxNestingLvl!: number;
  @Input() currentDocument!: DocumentModel;
  @Input() readonly filterValue!: keyof typeof EFilterDocument;
  @Input() breadcrumbs: IBreadCrumbs[] = [];
  @Input() selectedItems!: DocumentModel[];
  @Input() multiple = false;

  @Output() private readonly showRootFolders = new EventEmitter();
  @Output() private readonly loadParentFolder = new EventEmitter();

  readonly truncateLimit = 22;
  public dropdownDocuments: IBreadCrumbs[] = [];
  private readonly filterTitles: FilterTitles = {
    [EFilterDocument.ToBuyers]: 'Entity.To_buyer',
    [EFilterDocument.AllUnits]: 'Entity.All_units',
    [EFilterDocument.UnitGroups]: 'Title.Unit_Groups',
    [EFilterDocument.Units]: 'Title.Units',
    [EFilterDocument.Internal]: 'Entity.Internal',
  };
  public parentDirectory!: string;
  public title!: string;

  constructor(
    documentsService: ProjectDocumentsService,
    cdr: ChangeDetectorRef,
    public translateService: TranslateService,
    private activeModal: NgbActiveModal
  ) {
    super(documentsService, cdr);
  }

  ngOnInit(): void {
    this.breadcrumbs = this.breadcrumbs.filter((item) => item.id !== null);
    this.createDropdownForBreadcrumbs();
    this.parentDirectory = this.filterTitles[this.filterValue as EFilterDocument];
    this.setTitle();

    if (this.multiple) {
      this.setDisabledFolders();
    }
  }

  private setTitle(): void {
    if (this.multiple) {
      this.title =
        this.translateService.instant('Shared.Button.Move') +
        ' ' +
        this.translateService
          .instant(this.selectedItems.length === 1 ? 'Shared.Entity.Item' : 'Shared.Entity.Items')
          .toLowerCase();
    } else {
      this.title = this.translateService.instant('Shared.Button.Move') + ' "' + this.currentDocument?.title + '"';
    }
  }

  private setDisabledFolders(): void {
    this.folders.forEach((folder, index) => {
      this.folders[index].disabled = this.selectedItems.some((i) => i.id === folder.id);
    });
  }

  get disabledMoveIn(): boolean {
    if (!this.breadcrumbs?.length && this.currentDocument?.parents?.length) {
      return false;
    }

    return this.selectedFolderId === null;
  }

  loadParetFolder(parentFolderId: number): void {
    const index = this.breadcrumbs.findIndex((item: any) => item.id === parentFolderId);
    if (index !== -1) {
      const unitOrLayout = this.breadcrumbs[index].unitOrLayout;
      this.selectedFolderId = parentFolderId;
      this.loadParentFolder.emit({ parentFolderId, unitOrLayout });

      this.breadcrumbs.splice(index + 1);
    }
    this.createDropdownForBreadcrumbs();
  }

  openNestedFolder(folder: DocumentModel): void {
    this.breadcrumbs.push({ id: folder.id, title: folder.localizedTitle });
    this.selectedFolderId = folder.id;
    this.selectedFolderItem = folder;
    this.folders = folder.children?.filter((x) => x.documentType === EDocumentType.Folder) || [];
    this.createDropdownForBreadcrumbs();
  }

  openRootFolder(): void {
    this.selectedFolderItem = null;
    this.selectedFolderId = null;
    this.showRootFolders.emit();
    this.breadcrumbs = [];
  }

  closeModal(): void {
    this.activeModal.close();
  }

  hasDocumentsInDropdown(id: number | null): boolean {
    if (!id || !this.dropdownDocuments.length) {
      return false;
    }

    return this.dropdownDocuments.some((x) => x.id === id);
  }

  trackByFn: (i: number, item: DocumentModel) => number = (i: number, item: DocumentModel): number => item.id;

  private createDropdownForBreadcrumbs(): void {
    this.dropdownDocuments = this.breadcrumbs.length > 2 ? this.breadcrumbs.slice(0, -2) : [];
  }

  public stopPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }
}
