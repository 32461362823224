import {
  ActiveUsersModel,
  AnalyticsModel,
  IChartTaskProgressModel,
  IProjectOverviewDetailsModel,
  OptionReport,
} from '@atlas-workspace/shared/models';

export class SetProjectOverview {
  static readonly type = '[Project overview] save project overview data';
  constructor(public projectOverview: IProjectOverviewDetailsModel) {}
}

export class SetTimelineProgress {
  static readonly type = '[Timeline proggres] save project overview timeline progress';
  constructor(public timelineProgress: IChartTaskProgressModel) {}
}

export class SetActiveUsers {
  static readonly type = '[Active users] save project overview active users';
  constructor(public activeUsers: ActiveUsersModel) {}
}

export class SetOptionReports {
  static readonly type = '[Option reports] save project overview option reports';
  constructor(public optionReports: OptionReport) {}
}

export class SetOverviewAnalytics {
  static readonly type = '[Project overview analytics] set analytics';
  constructor(public analytics: AnalyticsModel) {}
}
