import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EFileType, FileModel, ImageModel, PositionedFile } from '@atlas-workspace/shared/models';
import { ModalFacadeService, ToasterService } from '@atlas-workspace/shared/service';
import { TranslateService } from '@ngx-translate/core';
import * as mime from 'mime';

import { InputFileDragDropComponent } from '../input-file-drag-drop/input-file-drag-drop.component';

@Component({
  selector: 'atl-text-editor-multi-file-upload',
  templateUrl: './text-editor-multi-file-upload.component.html',
  styleUrls: ['./text-editor-multi-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorMultiFileUploadComponent),
      multi: true,
    },
  ],
})
export class EditorMultiFileUploadComponent extends InputFileDragDropComponent {
  constructor(
    cdr: ChangeDetectorRef,
    modalFacadeService: ModalFacadeService,
    toasterService: ToasterService,
    translateService: TranslateService,
  ) {
    super(cdr, modalFacadeService, toasterService, translateService);
  }

  @Input() set enforceSelection(files: File[]) {
    if (!files || !files.length) {
      return;
    }
    const _e = new Event('change', { bubbles: true });
    const dataTransfer = new DataTransfer();
    for (const _file of files) {
      dataTransfer.items.add(_file);
    }
    Object.defineProperty(_e, 'target', { writable: false, value: {} });
    (<HTMLInputElement>_e.target).files = dataTransfer.files;

    this.selectedFile(_e);
  }

  fileLoadingPreprocessing(files: File[]): void {
    const acceptableFiles: File[] = this.filterByFormatAndMaxLength(files, this.inputAcceptString);
    if (acceptableFiles.length) {
      this.saveFile(acceptableFiles);
    }
  }

  filterByFormatAndMaxLength(files: File[], format: string): File[] {
    const countFiles = files.length;
    const fileName: string[] = [];
    if (format) {
      files = files.filter((file) => {
        if (file.type && (format.includes(String(mime.getExtension(file.type))) || format.includes(file.type))) {
          return true;
        } else {
          const ext = file.name.split('.');
          if (format.includes(ext[ext.length - 1].toLowerCase())) {
            return true;
          } else {
            fileName.push(file.name);
            return false;
          }
        }
      });

      if (files.length !== countFiles) {
        this.toasterService.openErrorToast(
          `${fileName.join(', ')} ${this.translateService.instant('Shared.Entity.Format_not_supported')}`,
        );
      }
    }
    const maxUploadedFiles = this.maxUploadedFiles - this.editFiles?.length - this.countFilesInArray(this.files);
    if (maxUploadedFiles <= 0) {
      return [];
    }
    return files.slice(0, maxUploadedFiles);
  }

  writeValue(value: (FileModel | ImageModel)[] = []): void {
    if (!value?.length) {
      this.listOfPreview = [];
      this.files = [];
      this.editFiles = [];
      return;
    }
    this.listOfPreview = [];
    value = value.filter(Boolean);
    this.files.length = value.length;
    this.editFiles = value;
    this.editFiles.forEach((file: FileModel | ImageModel) => {
      if (file.type !== 'image' && file.type !== 'file') {
        this.onPreparationPreview(file as unknown as File).then(() => null);
      } else if (file.type === 'image') {
        this.listOfPreview.push({
          type: EFileType.Image,
          previewImage: file.fileName.url,
          previewImageW260: file.fileName.w260?.url || file.fileName.url,
          editFile: file,
        });
      } else {
        this.listOfPreview.push({
          type: EFileType.File,
          editFile: file,
        });
      }
    });
    this.cdr.detectChanges();
  }

  private countFilesInArray(files: (PositionedFile | File | undefined)[]): number {
    return files.filter((file) => file instanceof File).length;
  }

  saveFile(files: PositionedFile[]): void {
    this.files ||= [];
    this.files = this.files.filter(Boolean);
    if (this.maximumNumberOfFilesHasBeenReached()) {
      return;
    }
    const countOfMaxAcceptableFiles: number = Math.min(
      this.maxUploadedFiles - (this.editFiles?.length as number) - this.countFilesInArray(this.files),
      files.length,
    );
    if (countOfMaxAcceptableFiles <= 0) {
      return;
    }
    files = files.slice(0, countOfMaxAcceptableFiles);
    const promises = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const promise = this.onPreparationPreview(file).then(() => {
        let fakePosI;
        if (files.length > 1) {
          fakePosI = i + 1;
        } else {
          fakePosI = this.listOfPreview.length + i;
        }
        const positionedFile = file;
        positionedFile.position = fakePosI;
        this.files.push(positionedFile);
        this.fileAddition.emit(this.listOfPreview[this.listOfPreview.length - 1]);
      });
      promises.push(promise);
    }

    Promise.all(promises).then(() => {
      if (this.autoSave) {
        this.addNewFilesToExisting.emit(files);
      }
      if (!this.onlyNew) {
        this.onChange([...this.editFiles, ...this.files]);
      } else {
        this.onChange(this.files);
      }
      this.cdr.detectChanges();
    });
  }

  removingFile(index: number, editFile?: FileModel | ImageModel): void {
    if (editFile) {
      this.editFiles?.splice(index, 1);
      this.listOfPreview?.splice(index, 1);
      this.files.shift();
      if (this.autoSave) {
        this.deleteExistingFile.emit(editFile);
      }
    } else {
      this.editFiles?.splice(index, 1);
      this.files?.splice(index, 1);
      this.listOfPreview?.splice(index, 1);
    }
    this.onChange([...this.editFiles, ...this.files]);
    if (index === this.draggableGallerySelectedIndex) {
      this.draggableGallerySelectedIndex = Math.max(index - 1, 0);
    }
    this.fileDeletion.emit(index);
  }

  onPreviewImageHandler(base64: string, name: string, extension: string): void {
    if (this.showPreview) {
      if (!extension) {
        const matches = base64.match(/\/(.*?);/);
        extension = matches ? matches[1] : '';
      }
      this.previewImageHandler.emit({ src: base64, name, extension });
    }
  }
}
