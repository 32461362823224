<!--
  ******************************************************
  @Cypress
  - interaction with konva buttons
  - interaction with konva canvas
  - interaction with footer buttons
  ******************************************************
-->
<div class="konva">
  <div
    class="konva__header d-flex flex-column justify-content-center"
    [class.konva__header--plans]="!reuse && showTab && navList?.length > 1"
  >
    <div class="d-inline-flex justify-content-between w-100">
      <atl-ngb-modal-header
        class="w-100"
        (closeModalEvent)="closeModal()"
        [title]="isEditFlow ? (reuse ? newAlternativeTitle : newVersionTitle) : floorPlan.name"
        [isClient]="client"
      >
      </atl-ngb-modal-header>
    </div>

    <ng-container *ngIf="!reuse">
      <div *ngIf="showTab && navList?.length > 1" class="konva__header-action">
        <atl-simple-tabs
          [activeTab]="activeTab"
          [tabs]="navList"
          [routeNavigation]="false"
          (selectingTab)="selectTab($event, konvaContainer)"
        ></atl-simple-tabs>
      </div>
    </ng-container>
  </div>

  <atl-floor-plan-version-select
    [class.d-none]="!reuse"
    [modalView]="false"
    [floors]="floorPlans"
    [floorDrawVersions]="floorDrawVersions"
    [reuseDrawVersion]="true"
    [title]="'Shared.Entity.Reuse_version'"
    [description]="'Shared.Change_Request.Floor_Plan_Select.Reuse'"
    (reuseVersionHandler)="reuseDrawData($event)"
  ></atl-floor-plan-version-select>

  <div [class.d-none]="reuse" class="konva__control" *ngIf="isEditFlow">
    <div
      class="konva-button"
      (click)="setSelection(controlButton.Arrow)"
      [class.active]="selectedButton[controlButton.Arrow]"
      [ngbTooltip]="'Shared.Drawing.Button.Arrow' | translate"
      tooltipClass="drawing-panel"
      [openDelay]="tooltipDelay"
      placement="left"
      triggers="hover touchstart"
    >
      <span class="konva-icon konva-icon__arrow"></span>
    </div>
    <div
      class="konva-button"
      (click)="setSelection(controlButton.Line)"
      [class.active]="selectedButton[controlButton.Line]"
      [ngbTooltip]="'Shared.Drawing.Button.Line' | translate"
      tooltipClass="drawing-panel"
      [openDelay]="tooltipDelay"
      placement="left"
      triggers="hover touchstart"
    >
      <span class="konva-icon konva-icon__line"></span>
    </div>
    <div
      class="konva-button"
      (click)="setSelection(controlButton.Square)"
      [class.active]="selectedButton[controlButton.Square]"
      [ngbTooltip]="'Shared.Drawing.Button.Rectangle' | translate"
      tooltipClass="drawing-panel"
      [openDelay]="tooltipDelay"
      placement="left"
      triggers="hover touchstart"
    >
      <span class="konva-icon konva-icon__square"></span>
    </div>
    <div
      class="konva-button"
      (click)="setSelection(controlButton.Circle)"
      [class.active]="selectedButton[controlButton.Circle]"
      [ngbTooltip]="'Shared.Drawing.Button.Ellipse' | translate"
      tooltipClass="drawing-panel"
      [openDelay]="tooltipDelay"
      placement="left"
      triggers="hover touchstart"
    >
      <span class="konva-icon konva-icon__circle"></span>
    </div>
    <div
      class="konva-button"
      (click)="setSelection(controlButton.Pencil)"
      [class.active]="selectedButton[controlButton.Pencil]"
      [ngbTooltip]="'Shared.Drawing.Button.Pencil' | translate"
      tooltipClass="drawing-panel"
      [openDelay]="tooltipDelay"
      placement="left"
      triggers="hover touchstart"
    >
      <span class="konva-icon konva-icon__pencil"></span>
    </div>
    <div class="konva__control-divider"></div>
    <div
      class="konva-button"
      (click)="undo()"
      [ngbTooltip]="'Shared.Drawing.Button.Undo' | translate"
      tooltipClass="drawing-panel"
      [openDelay]="tooltipDelay"
      placement="left"
      triggers="hover touchstart"
    >
      <span class="konva-icon konva-icon__undo"></span>
    </div>
  </div>
  <div [class.d-none]="reuse" class="content-wrapper">
    <div *ngIf="isEditFlow" class="konva__undo-redo position-absolute z-index-2">
      <div class="konva-button mobile mr-16" (click)="undo()">
        <span class="konva-icon konva-icon__undo"></span>
      </div>
      <div class="konva-button mobile" (click)="redo()">
        <span class="konva-icon konva-icon__redo"></span>
      </div>
    </div>
    <div
      #konvaContainer
      atlClickOutside
      (clickOutside)="clickOutside()"
      id="konva-container"
      class="konva__container d-flex align-items-center justify-content-center"
      [ngClass]="{
        'cursor-pointer': activeButton && !loading,
        'cursor-not-allowed': loading
      }"
      [ngStyle]="{ 'background-image': 'url(' + previewPlan + ')' }"
    ></div>
    <div *ngIf="loader" class="loader">
      <span class="custom-spinner custom-spinner-48"></span>
    </div>
  </div>
  <div [class.d-none]="reuse" class="konva__footer d-flex align-items-center justify-content-end" *ngIf="isEditFlow">
    <ng-container>
      <button (click)="closeModal()" class="newest-button button-gray mr-16 h-40">
        {{ 'Shared.Button.Cancel' | translate }}
      </button>
      <atl-button-loader
        (clickButton)="saveDraw(konvaContainer)"
        data-cy="konva-save"
        [loading]="loading"
        [label]="drawVersion ? 'Shared.Entity.Save_alternative' : 'Shared.Button.Save_tags'"
        [newest]="true"
        [disabled]="!shapes.length"
      ></atl-button-loader>
    </ng-container>
  </div>
</div>
