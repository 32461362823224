import { Expose, Transform, Type } from 'class-transformer';
import dayjs from 'dayjs';

import { randomColor, transformUser } from '../helpies/random-color.helper';
import { AdminProjectModel } from './admin.model';
import { ContractorCompanyForReclamationsModel, ContractorForReclamationsModel } from './contractor.model';
import { FileModel } from './file.model';
import { ImageModel } from './image.model';
import { IReclamationCategoryExp, UnitTypeModelExp, UnitUserModelExp } from './rules.model';
import { ITablePagination } from './table-pagination.interface';
import { TermsConditionsDataModel } from './terms-conditions.model';
import { IThreadState } from './thread.model';
import { FloorModel, IUnit, IUnitMainBuyer, UnitFloorModel } from './unit.model';
import { IUser } from './user-model';

export enum EReclamationUserType {
  User = 'User',
  MainAdmin = 'MainAdmin',
}

export enum EReclamationReportType {
  PDF = 'pdf',
  XLS = 'xls',
}

export class ReclamationMeetingType {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
}

export class ReclamationMeeting {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'meeting_name' })
  meetingName!: string;
  @Expose({ name: 'meeting_type' })
  @Type(() => ReclamationMeetingType)
  meetingType!: ReclamationMeetingType;
}

export class ReclamationMessageThreadModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'unread_count' })
  unreadCount!: number;
}

export class ReclamationUpdatedByModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
}

export class ReclamationProjectModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
}

export class ReclamationActivitiesModel {
  @Expose({ name: 'event_type' })
  eventType!: string;
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'previous_localized_status' })
  previousLocalizedStatus!: string | null;
  @Expose({ name: 'previous_status' })
  previousStatus!: string | null;
  @Expose({ name: 'status' })
  status!: EReclamationStatusKey;
  @Expose({ name: 'text' })
  text!: string;
  @Expose({ name: 'updated_date' })
  updatedDate!: string;
  @Expose({ name: 'updated_by' })
  @Type(() => ReclamationUpdatedByModel)
  updatedBy!: ReclamationUpdatedByModel;

  created?: boolean;
  identifier?: string;
  userType?: string;
}

export class ReclamationContractorModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'email' })
  email!: string;
  @Expose({ name: 'contractor_company_role' })
  contractorCompanyRole!: string;
}

export class ReclamationsModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier?: string;
  @Expose({ name: 'unit' })
  unit!: IUnit;
  @Expose({ name: 'category' })
  category!: IReclamationCategory;
  @Expose({ name: 'type' })
  type!: IReclamationCategoryTypes;
  @Expose({ name: 'product' })
  product!: IReportProduct; //TODO: change model
  @Expose({ name: 'status' })
  status!: EReclamationStatusKey;
  @Expose({ name: 'responsible' })
  responsible!: IUser;
  @Expose({ name: 'reported' })
  reported!: string;
  @Expose({ name: 'room' })
  room?: IReclamationRoom;
  @Expose({ name: 'wishlist_item' })
  wishlistItem?: IReclamationProducts;
  @Expose({ name: 'unit_id' })
  unitId?: number;
  @Expose({ name: 'type_id' })
  typeId?: number;
  @Expose({ name: 'product_id' })
  productId?: number;
  @Expose({ name: 'description' })
  description?: string;
  @Expose({ name: 'room_id' })
  roomId?: string;
  @Expose({ name: 'due_date' })
  @Transform(({ value }) => (value ? dayjs(value).format('D.MM.YYYY') : null))
  dueDate?: string | null;
  @Expose({ name: 'responsible_id' })
  responsibleId?: number;
  @Expose({ name: 'attachments' })
  attachments?: any[];
  @Expose({ name: 'reported_date' })
  reportedDate?: string;
  @Expose({ name: 'file_resources' })
  fileResources?: (FileModel | ImageModel)[];
  @Expose({ name: 'floor' })
  @Type(() => FloorModel)
  floor?: FloorModel;
  @Expose({ name: 'floor_id' })
  floorId?: number;
  @Expose({ name: 'floor_type' })
  floorType?: string;
  @Expose({ name: 'floor_number' })
  floorNumber!: number;
  @Expose({ name: 'point_x' })
  pointX?: number;
  @Expose({ name: 'point_y' })
  pointY?: number;
  @Expose({ name: 'selected' })
  selected?: boolean;
  @Expose({ name: 'last_event_log' })
  lastEventLog?: IReclamationEventLog;
  @Expose({ name: 'localized_status' })
  localizedStatus?: string;
  @Expose({ name: 'message_thread_id' })
  messageThreadId?: number;
  @Expose({ name: 'message_thread_unread_count' })
  messageThreadUnreadCount?: number;
  @Expose({ name: 'notes_message_thread_id' })
  notesMessageThreadId?: number;
  @Expose({ name: 'notes_message_thread_unread_count' })
  notesMessageThreadUnreadCount?: number;
  @Expose({ name: 'only_admins_thread_id' })
  onlyAdminsThreadId?: number;
  @Expose({ name: 'only_admins_thread_unread_count' })
  onlyAdminsThreadUnreadCount?: number;
  @Expose({ name: 'meeting' })
  @Type(() => ReclamationMeeting)
  meeting?: ReclamationMeeting;
  @Expose({ name: 'message_thread' })
  @Type(() => ReclamationMessageThreadModel)
  messageThread!: ReclamationMessageThreadModel;
  @Expose({ name: 'activities' })
  @Type(() => ReclamationActivitiesModel)
  activities!: ReclamationActivitiesModel[];
  @Expose({ name: 'message_thread_has_messages' })
  messageThreadHasMessages!: boolean;
  @Expose({ name: 'message_thread_state' })
  messageThreadState!: IThreadState;
  @Expose({ name: 'available_statuses' })
  @Transform(({ value }) => value || [])
  availableStatuses!: EReclamationStatusKey[];
  @Expose({ name: 'current_user_controller' })
  currentUserController!: boolean;
  @Expose({ name: 'customer_review' })
  customerReview!: boolean;
  @Expose({ name: 'rule_comment' })
  ruleComment!: string;
  @Expose({ name: 'archived' })
  archived!: boolean;
  @Expose({ name: 'contractor' })
  @Type(() => ReclamationContractorModel)
  contractor?: ReclamationContractorModel;
  @Expose({ name: 'contractor_company' })
  contractorCompany?: { id: number; name: string };
  @Expose({ name: 'custom_note_id' })
  customNoteId!: number | null;
  @Expose({ name: 'project' })
  project?: ReclamationProjectModel;
  @Expose({ name: 'unread_timestamp' })
  unreadTimestamp!: string | null;
  @Expose({ name: 'last_message_timestamp' })
  lastMessageTimestamp!: string | null;
}

export type TReclamationsModelFormGroup = {
  [K in keyof ReclamationsModel]: any;
};

//TODO: remove when component is ready
interface IReportProduct {
  name: string;
  img: string;
}

export enum EReclamationStatusKey {
  Pending = 'pending',
  InProgress = 'in_progress',
  Fixed = 'fixed',
  Declined = 'declined',
  Draft = 'draft',
  Disputed = 'disputed',
  CustomerReview = 'review',
  InternalReview = 'internal_review',
  HasMessages = 'has_messages',
  WaitingContractor = 'waiting_for_contractor',
  WaitingCustomer = 'waiting_for_customer',
  WorkDone = 'work_done',
  OneYearInspection = 'one_year_inspection',
}

export enum EReclamationStatus {
  Pending = 'Shared.Reclamation.Status.New',
  InProgress = 'Shared.Reclamation.Status.In_progress',
  InProgressAdmin = 'Shared.Reclamation.Status.In_progress_admin',
  Fixed = 'Shared.Reclamation.Status.Fixed',
  FixedAdmin = 'Shared.Reclamation.Status.Fixed_admin',
  Declined = 'Shared.Reclamation.Status.Declined',
  Draft = 'Shared.Reclamation.Status.Draft',
  Disputed = 'Shared.Reclamation.Status.Disputed',
  CustomerReview = 'Shared.Reclamation.Status.Customer_review',
  InternalReview = 'Shared.Reclamation.Status.Internal_review',
  InternalReviewAdmin = 'Shared.Reclamation.Status.Internal_review_admin',
  WaitingContractor = 'Shared.Reclamation.Status.Waiting_contractor',
  WaitingCustomer = 'Shared.Reclamation.Status.Waiting_customer',
  OneYearInspection = 'Shared.Reclamation.Status.One_year_inspection',
}

export enum EReclamationScope {
  Units = 'units',
  Reclamations = 'reclamations',
  Protocols = 'protocols',
  ReclamationUnits = 'reclamation_units',
  Global = 'global',
  Meetings = 'meetings',
}

export interface IMark {
  floorId: number;
  floorNumber: number;
  floorType: UnitFloorModel | FloorModel;
  mark: {
    x: number;
    y: number;
  }[];
}

export interface MarkPosition {
  x: number;
  y: number;
  position: number;
}

export interface IUnitFloor {
  id: number;
  floors: FloorModel[];
}

export interface IReclamationCategory {
  id: number;
  name: string;
  kind: string;
  firm_id: number | null;
  selected?: boolean;
  types?: IReclamationCategoryTypes[];
}

export interface IReclamationCategoryTypes {
  id: number;
  name: string;
  kind: string;
  firm_id: number | null;
  category: IReclamationCategory;
}

export interface ISelectedReclamationFilters {
  statuses: string[];
  categoryIds: string[];
  typeIds: string[];
  unitIds: string[];
  responsibleIds: number[];
  layoutTypeIds: string[];
  meetingTypes: string[];
  threadState: string[];
  author: string[];
  contractorCompanies: string[];
  contractorsAndCompanies: (ContractorCompanyForReclamationsModel | ContractorForReclamationsModel)[];
  date: [{ from: string; to: string | null } | null] | [];
  protocolsTemplates: number[];
}

export interface ISelectGlobalReclamationFilters extends ISelectedReclamationFilters {
  projectIds: string[];
}

export const reclamationStatusList = {
  [EReclamationStatusKey.Pending]: {
    style: 'pending',
    title: EReclamationStatus.Pending,
  },
  [EReclamationStatusKey.InProgress]: {
    style: 'in_progress',
    title: EReclamationStatus.InProgressAdmin,
  },
  [EReclamationStatusKey.Fixed]: {
    style: 'fixed',
    title: EReclamationStatus.FixedAdmin,
  },
  [EReclamationStatusKey.Declined]: {
    style: 'declined',
    title: EReclamationStatus.Declined,
  },
  [EReclamationStatusKey.Draft]: {
    style: 'draft',
    title: EReclamationStatus.Draft,
  },
  [EReclamationStatusKey.Disputed]: {
    style: 'disputed',
    title: EReclamationStatus.Disputed,
  },
  [EReclamationStatusKey.CustomerReview]: {
    style: 'review',
    title: EReclamationStatus.CustomerReview,
  },
  [EReclamationStatusKey.InternalReview]: {
    style: 'internal_review',
    title: EReclamationStatus.InternalReviewAdmin,
  },
  [EReclamationStatusKey.WorkDone]: {
    style: 'internal_review',
    title: EReclamationStatus.InternalReviewAdmin,
  },
  [EReclamationStatusKey.HasMessages]: {
    style: 'internal_review',
    title: EReclamationStatus.InternalReviewAdmin,
  },
  [EReclamationStatusKey.WaitingCustomer]: {
    style: 'waiting_for_customer',
    title: EReclamationStatus.WaitingCustomer,
  },
  [EReclamationStatusKey.WaitingContractor]: {
    style: 'waiting_for_contractor',
    title: EReclamationStatus.WaitingContractor,
  },
  [EReclamationStatusKey.OneYearInspection]: {
    style: 'one_year_inspection',
    title: EReclamationStatus.OneYearInspection,
  },
};

export const clientReclamationStatusList = {
  [EReclamationStatusKey.Pending]: {
    style: 'pending',
    title: EReclamationStatus.Pending,
  },
  [EReclamationStatusKey.InProgress]: {
    style: 'in_progress',
    title: EReclamationStatus.InProgress,
  },
  [EReclamationStatusKey.Fixed]: {
    style: 'fixed',
    title: EReclamationStatus.Fixed,
  },
  [EReclamationStatusKey.Declined]: {
    style: 'declined',
    title: EReclamationStatus.Declined,
  },
  [EReclamationStatusKey.Draft]: {
    style: 'draft',
    title: EReclamationStatus.Draft,
  },
  [EReclamationStatusKey.Disputed]: {
    style: 'disputed',
    title: EReclamationStatus.Disputed,
  },
  [EReclamationStatusKey.CustomerReview]: {
    style: 'review',
    title: EReclamationStatus.CustomerReview,
  },
  [EReclamationStatusKey.InternalReview]: {
    style: 'internal_review',
    title: EReclamationStatus.InternalReview,
  },
  [EReclamationStatusKey.WorkDone]: {
    style: 'internal_review',
    title: EReclamationStatus.InternalReview,
  },
  [EReclamationStatusKey.HasMessages]: {
    style: 'internal_review',
    title: EReclamationStatus.InternalReview,
  },
  [EReclamationStatusKey.WaitingCustomer]: {
    style: 'waiting_for_customer',
    title: EReclamationStatus.WaitingCustomer,
  },
  [EReclamationStatusKey.WaitingContractor]: {
    style: 'waiting_for_contractor',
    title: EReclamationStatus.WaitingContractor,
  },
  [EReclamationStatusKey.OneYearInspection]: {
    style: 'one_year_inspection',
    title: EReclamationStatus.OneYearInspection,
  },
};

export interface IReclamationProductPreview {
  id: number;
  name: string;
  file_size: number | null;
  created_at: string;
  filename: {
    url: string;
    download_url: string;
    type: string;
    w260: {
      url: string;
      download_url: string;
    };
  };
}

export interface IReclamationProducts {
  id: number;
  status: string;
  layout_product_type_item_id: number;
  name: string;
  preview: IReclamationProductPreview;
}

export interface IReclamationRooms {
  id: number;
  name: string;
  size: string;
}

export enum IFloorType {
  Floor = 'Floor',
  UnitFloor = 'UnitFloor',
}

export interface IReclamationRoom {
  id: number;
  name: string;
  size: string;
}

export interface IReclamationReason {
  name: string;
  id?: string;
}

export class ReclamationStatusCountModel {
  count!: number;
  name!: string;
  @Expose({ name: 'localized_name' }) localizedName!: string;
}

export class ReclamationFilterCountModel {
  count!: number;
  name!: string;
  @Expose({ name: 'localized_name' }) localizedName!: string;
  id?: number;
  ids?: number[];
  hide?: boolean;
}

export interface IReclamationData {
  reclamations: ReclamationsModel[];
  status_stats: ReclamationStatusCountModel[];
  total_count: number;
}

const hideProtocolType = ['Overtagelse BoMer', 'Move-in-Meierihagen'];
const moveIn = 'Move-in';

export class ReclamationFiltersIndexCounterModel {
  @Expose({ name: 'author' })
  @Type(() => ReclamationFilterCountModel)
  author!: ReclamationFilterCountModel[];
  @Expose({ name: 'meeting_types' })
  @Type(() => ReclamationFilterCountModel)
  meetingTypes!: ReclamationFilterCountModel[];
  @Expose({ name: 'message' })
  @Type(() => ReclamationFilterCountModel)
  message!: ReclamationFilterCountModel[];
  @Expose({ name: 'other_filters' })
  @Type(() => ReclamationFilterCountModel)
  otherFilters!: ReclamationFilterCountModel[];
  @Expose({ name: 'status' })
  @Type(() => ReclamationFilterCountModel)
  status!: ReclamationFilterCountModel[];
  @Expose({ name: 'protocols_templates' })
  @Type(() => ReclamationFilterCountModel)
  @Transform(({value}) => {
    return value.map((item: any) => {
      if (!item.count) {
        item.count = 0;
      }
      if (!item.id) {
        item.id = 'none';
      }
      if (hideProtocolType.includes(item.name)) {
        const findMoveIn = value.find((i: any) => i.name === moveIn);
        if (findMoveIn) {
          findMoveIn.count += item.count;

          if (findMoveIn?.ids?.length) {
            findMoveIn?.ids.push(item.id);
          } else {
            findMoveIn.ids = [item.id];
          }
        }

        item.hide = true;
      }
      return item;
    }).sort((a: any, b: any) => {
      if (a.id === 'none' || b.id === 'none') return -1;
      return 1;
    })
  })
  protocolsTemplates!: ReclamationFilterCountModel[];
}

export interface IReclamationEventLog {
  comment: string;
  created_at: string;
  event_reason: IEventReason;
  file_resources: (FileModel | ImageModel)[];
  id: string;
  reclamation: string;
  status: EReclamationStatusKey;
  updated_by: any;
}

export interface IEventReason {
  firm_id: string;
  id: string;
  kind: string;
  localized_status: string;
  name: string;
  status: EReclamationStatusKey;
}

export const adminStatusesButtons = [
  {
    status: EReclamationStatusKey.Declined,
    title: EReclamationStatus.Declined,
  },
  {
    status: EReclamationStatusKey.InProgress,
    title: EReclamationStatus.InProgress,
  },
  {
    status: EReclamationStatusKey.Pending,
    title: EReclamationStatus.Pending,
  },
  {
    status: EReclamationStatusKey.CustomerReview,
    title: EReclamationStatus.CustomerReview,
  },
  {
    status: EReclamationStatusKey.InternalReview,
    title: EReclamationStatus.InternalReview,
  },
  {
    status: EReclamationStatusKey.Disputed,
    title: EReclamationStatus.Disputed,
  },
  {
    status: EReclamationStatusKey.Fixed,
    title: EReclamationStatus.Fixed,
  },
];

export interface IReclamationStatusButton {
  status: EReclamationStatusKey;
  title: string;
}

export enum EReclamationTab {
  General = 'Entity.General',
  Messages = 'Title.Messages',
}

export enum EReclamationTabAdmin {
  General = 'Shared.Reclamation.Details',
  Messages = 'Entity.Customer_dialogue',
  InternalNotes = 'Reclamation.Tab.Discussion',
  Activities = 'Title.Analytics',
  Notes = 'Shared.Entity.Notes',
  OnlyAdmins = 'Reclamation.Tab.Admins_only',
}

//TODO need to change InternalNotes to Discussion, and change it in whole admin panel (threads, reclamations)
export enum EReclamationTabKeyAdmin {
  General = 'general',
  Messages = 'messages',
  InternalNotes = 'internal_notes',
}

export enum ERecDiscussionTabId {
  AdminsOnly = 'only_admins',
  CompanyName = 'company',
}

export enum ERecDiscussionTabName {
  AdminsOnly = 'Reclamation.Tab.Admins_only',
  CompanyName = 'Entity.Admins_and_contractor',
}

export const reclamationTabList = {
  [EReclamationTabKeyAdmin.General]: {
    key: EReclamationTabKeyAdmin.General,
    title: EReclamationTabAdmin.General,
  },
  [EReclamationTabKeyAdmin.Messages]: {
    key: EReclamationTabKeyAdmin.Messages,
    title: EReclamationTabAdmin.Messages,
  },
  [EReclamationTabKeyAdmin.InternalNotes]: {
    key: EReclamationTabKeyAdmin.InternalNotes,
    title: EReclamationTabAdmin.InternalNotes,
  },
};

export enum EUnitReclamationView {
  TableView = 'Title.Table_view',
  FloorPlanView = 'Title.Floor_plan_view',
}

export class ReclamationUnitStatsModel {
  @Expose({ name: 'completed' })
  completed!: number;
  @Expose({ name: 'declined' })
  declined!: number;
  @Expose({ name: 'opened' })
  opened!: number;
  @Expose({ name: 'total' })
  total!: number;
}

export class ReclamationUnitPrimaryOwnersModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'email' })
  email!: string;
}

export class ReclamationUnitModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier!: string;
  @Expose({ name: 'reclamations_stats' })
  @Type(() => ReclamationUnitStatsModel)
  reclamationsStats!: ReclamationUnitStatsModel;
  @Expose({ name: 'primary_owners' })
  @Type(() => ReclamationUnitPrimaryOwnersModel)
  primaryOwners!: ReclamationUnitPrimaryOwnersModel[];
  @Expose({ name: 'co_owners' })
  coOwners!: IUnitMainBuyer[];
  @Expose({ name: 'main_primary_owner' })
  mainPrimaryOwner!: IUnitMainBuyer | null;
  @Expose({ name: 'project' })
  project?: ReclamationProjectModel;
}

export enum EAnalyticsQuery {
  Full = 'full',
  AverageResolutionStats = 'average_resolution_stats',
  OpenReclamationStats = 'open_reclamations_stats',
  AveragePerUnitStats = 'average_per_unit_stats',

  StatusStats = 'status_stats',
  TypesStats = 'types_stats',
  CategoriesStats = 'categories_stats',

  ResponsibleCountStats = 'responsible_count_stats',
  AverageAdminResolutionStats = 'average_admin_resolution_stats',
  InProgressPerAdminStats = 'in_progress_per_admin_stats',

  StatusGraphics = 'status_graphics',
  OpenReclamationsGraphic = 'open_reclamations_graphic',
  AverageResolutionGraphic = 'average_resolution_graphic',
  AveragePerUnitGraphic = 'average_per_unit_graphic',

  InProgressPerAdminGraphic = 'in_progress_per_admin_graphic',
  AverageAdminResolutionGraphic = 'average_admin_resolution_graphic',
}

export const analyticsRequestQuery: string[][] = [
  [EAnalyticsQuery.OpenReclamationStats, EAnalyticsQuery.AverageResolutionStats, EAnalyticsQuery.AveragePerUnitStats],
  [EAnalyticsQuery.StatusStats, EAnalyticsQuery.TypesStats, EAnalyticsQuery.CategoriesStats],
  [
    EAnalyticsQuery.StatusGraphics,
    EAnalyticsQuery.OpenReclamationsGraphic,
    EAnalyticsQuery.AverageResolutionGraphic,
    EAnalyticsQuery.AveragePerUnitGraphic,
  ],
  [
    EAnalyticsQuery.ResponsibleCountStats,
    EAnalyticsQuery.AverageAdminResolutionStats,
    EAnalyticsQuery.InProgressPerAdminStats,
  ],
  [EAnalyticsQuery.InProgressPerAdminGraphic, EAnalyticsQuery.AverageAdminResolutionGraphic],
];

export class AnalyticStatsModel {
  name!: string;
  value!: number;
  color?: string;
}

export interface ILineChartDataset {
  data: any;
  label: string;
  borderDash: boolean;
  fill: boolean;
  subData?: any[];
}

export class StatusGraphicModel {
  [key: string]: ILineChartDataset;
}

export class AnalyticsDiffGraphicModel {
  @Expose({ name: 'data' })
  @Transform(({ value }) => {
    if (!value) return value;
    return [
      {
        data: value,
        borderDash: false,
        borderColor: '#3377FF',
        fill: false,
      },
    ];
  })
  dataSet!: ILineChartDataset[];
  @Expose({ name: 'percent_diff' })
  @Transform(({ value }) => +value)
  percentDiff!: number;
}

export class AnalyticsModel {
  @Expose({ name: 'total_count' })
  totalCount?: number;
  @Expose({ name: 'open_reclamations_count' })
  openCount?: number;
  @Expose({ name: 'average_per_unit' })
  averagePerUnit?: number;
  @Expose({ name: 'average_resolution' })
  averageResolution?: number;
  @Expose({ name: 'status_stats' })
  statusStats?: AnalyticStatsModel[];
  @Expose({ name: 'types_stats' })
  @Type(() => AnalyticStatsModel)
  @Transform(({ value }) => {
    return Object?.keys(value).map((v) => ({ name: v, value: value[v] }));
  })
  typesStats?: AnalyticStatsModel[];
  @Expose({ name: 'categories_stats' })
  @Type(() => AnalyticStatsModel)
  @Transform(({ value }) => {
    return Object?.keys(value).map((v) => ({ name: v, value: value[v] }));
  })
  categoriesStats?: AnalyticStatsModel[];
  @Expose({ name: 'responsible_data' })
  @Type(() => AnalyticStatsModel)
  @Transform(({ value }) => {
    return Object?.keys(value).map((v) => ({ name: v, value: value[v] }));
  })
  responsibleCountStats?: AnalyticStatsModel[];

  @Expose({ name: 'average_admin_resolution_stats' })
  @Type(() => AnalyticStatsModel)
  @Transform(({ value, obj }) => {
    return Object?.keys(value).map((v) => {
      const name = obj.responsible_data[v];
      return {
        name: name,
        value: value[v],
        color: randomColor(name, +v),
      };
    });
  })
  averageAdminResolution?: AnalyticStatsModel[];

  @Expose({ name: 'in_progress_per_admin_stats' })
  @Type(() => AnalyticStatsModel)
  @Transform(({ value, obj }) => {
    return Object?.keys(value).map((v) => {
      const name = obj.responsible_data[v];
      return {
        name: name,
        value: value[v],
        color: randomColor(name, +v),
      };
    });
  })
  inProgressPerAdminStats?: AnalyticStatsModel[];

  @Expose({ name: 'status_graphics' })
  @Transform(({ value }) => {
    if (!value) return value;
    Object?.keys(value)?.map((key) => {
      value[key] = {
        data: value[key],
        label: false,
        borderDash: false,
        borderColor: '#3377FF',
        fill: true,
      };
    });
    return value;
  })
  statusGraphics?: StatusGraphicModel[];

  @Expose({ name: 'average_per_unit_graphic' })
  @Type(() => AnalyticsDiffGraphicModel)
  averagePerUnitGraphic?: AnalyticsDiffGraphicModel;

  @Expose({ name: 'average_resolution_graphic' })
  @Type(() => AnalyticsDiffGraphicModel)
  averageResolutionGraphic?: AnalyticsDiffGraphicModel;

  @Expose({ name: 'open_reclamations_graphic' })
  @Type(() => AnalyticsDiffGraphicModel)
  openReclamationsGraphic?: AnalyticsDiffGraphicModel;

  @Expose({ name: 'in_progress_per_admin_graphic' })
  @Transform(({ value, obj }) => {
    if (!value) return value;
    return transformUser(value, obj.responsible_data);
  })
  inProgressPerAdminGraphic!: ILineChartDataset[];

  @Expose({ name: 'average_admin_resolution_graphic' })
  @Transform(({ value, obj }) => {
    if (!value) return value;
    return transformUser(value, obj.responsible_data);
  })
  averageAdminResolutionGraphic!: ILineChartDataset[];
}

export class ReclamationRecipientModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'email' })
  email!: string;
}

export class ReclamationSettingsModel {
  @Expose({ name: 'project_id' })
  projectId!: number;
  @Expose({ name: 'customer_review' })
  customerReview!: boolean;
  @Expose({ name: 'reclamation_recipients' })
  reclamationRecipients!: ReclamationRecipientModel[];
  @Expose({ name: 'reclamation_conts' })
  reclamationControllers!: ReclamationRecipientModel[];
  @Expose({ name: 'terms_conditions' })
  termsConditions!: boolean;
  @Expose({ name: 'terms_conditions_data' })
  @Type(() => TermsConditionsDataModel)
  termsConditionsData!: TermsConditionsDataModel;
}

export enum EReclamationSettingsTabs {
  General = 'Entity.General',
  Rules = 'Entity.Rules',
  UserManagement = 'Title.User_management',
}

export enum EReclamationView {
  Reclamations = 'reclamations',
  Units = 'units',
  Threads = 'threads',
}

export enum EReclamationQuickFilterStatus {
  Open = 'open',
  Closed = 'closed',
}

export const reclamationOpenFilterStatuses = [
  EReclamationStatusKey.Pending,
  EReclamationStatusKey.InProgress,
  EReclamationStatusKey.WaitingContractor,
  EReclamationStatusKey.WaitingCustomer,
  EReclamationStatusKey.CustomerReview,
  EReclamationStatusKey.WorkDone,
  EReclamationStatusKey.InternalReview,
  EReclamationStatusKey.Disputed,
  EReclamationStatusKey.OneYearInspection,
];

export const reclamationClosednFilterStatuses = [EReclamationStatusKey.Fixed, EReclamationStatusKey.Declined];

export enum ESettingsBodyRequest {
  CustomerReview = 'customerReview',
  ReclamationRecipients = 'reclamationRecipients',
  ReclamationControllers = 'reclamationControllers',
  TermsConditions = 'termsConditions',
  TermsConditionsData = 'termsConditionsData',
}

export interface ICreateRuleForm {
  categoryId: number;
  typeId: number;
  responsibleId: number;
  comment: string;
}

export interface IUserShort {
  id: number;
  name: string;
}

export class ReclamationRuleModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'comment' })
  comment!: string;
  @Expose({ name: 'project_id' })
  projectId!: number;
  @Expose({ name: 'reclamation_category' })
  reclamationCategory!: IReclamationCategory;
  @Expose({ name: 'reclamation_type' })
  reclamationType!: IReclamationCategoryTypes;
  @Expose({ name: 'responsible' })
  responsible!: IUserShort;
  @Expose({ name: 'selected' })
  selected?: boolean;
}

export interface IReclamationPermissionDropdownModel {
  categories: IReclamationCategory[];
  users: AdminProjectModel[];
}

export interface IReclamationResponsibleDropdownModel {
  units: UnitUserModelExp[];
  layoutTypes: UnitTypeModelExp[];
  categories: IReclamationCategoryExp[];
}

export enum EReclamationPermissionDropdownTypes {
  Categories = 'categories',
  Users = 'users',
}

export enum EReclamationResponsibleDropdownTypes {
  Units = 'units',
  LayoutTypes = 'layoutTypes',
  Categories = 'categories',
}

export class SpecialPermissionsModel {
  @Expose({ name: 'id' })
  id?: number;
  @Expose({ name: 'project_id' })
  projectId!: number;
  @Expose({ name: 'model' })
  model!: string;
  @Expose({ name: 'holder_type' })
  holderType!: string; //type
  @Expose({ name: 'holder_id' })
  holderId!: number;
  @Expose({ name: 'access_level' })
  accessLevel!: string; //type
  @Expose({ name: 'restriction_name' })
  restrictionName!: string | null;
  @Expose({ name: 'restriction_value_type' })
  restrictionValueType?: string | null;
  @Expose({ name: 'restriction_value_id' })
  restrictionValueId!: number | null;
}

export enum EReclamationRestrictionsType {
  Responsible = 'responsible_id',
  Category = 'category_id',
}

export interface TClientReclamationHome {
  pagination: ITablePagination;
  value: ReclamationsModel[];
}

export interface IStatusChangeList {
  status: EReclamationStatusKey;
  title: string;
}

export class ReclFilterProjectModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'reclamation_count' })
  count!: number;
}

export class ReclFilterUnitModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier!: string;
  @Expose({ name: 'project_name' })
  projectName!: number;
}

export class ReclFilterUnitGroupModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'project_name' })
  projectName!: number;
}

export interface GetReclamationsProjectProps {
  projectId: number;
  sort: string;
  search: string;
  byUser: boolean;
  hasMessages: boolean;
  paginate?: ITablePagination;
  unitId?: number;
  selectedFilters?: ISelectedReclamationFilters;
  protocolsCounters?: ReclamationFilterCountModel[]
}
