import { Injectable } from '@angular/core';
import { ImmutableSelector } from '@atlas-workspace/shared/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';

import { defaultState } from '../default-state';
import { SetActiveUsers, SetOptionReports, SetOverviewAnalytics, SetProjectOverview, SetTimelineProgress } from './project-overview.action';
import {
  ActiveUsersStateModel,
  OptionReportsStateModel,
  OverviewAnalyticsStateModel,
  ProjectOverviewStateModel,
  TimelineProgressStateModel,
} from './project-overview.model';

@State({
  name: 'projectOverviewState',
  defaults: defaultState.projectOverviewState,
})
@Injectable()
export class ProjectOverviewState {
  @Selector()
  @ImmutableSelector()
  static selectStateData(state: ProjectOverviewStateModel): ProjectOverviewStateModel {
    return state;
  }

  @Selector()
  @ImmutableSelector()
  static selectStateTimelineData(state: TimelineProgressStateModel): TimelineProgressStateModel {
    return state;
  }

  @Selector()
  @ImmutableSelector()
  static selectStateActiveUsersData(state: ActiveUsersStateModel): ActiveUsersStateModel {
    return state;
  }

  @Selector()
  @ImmutableSelector()
  static selectStateOptionReportsData(state: OptionReportsStateModel): OptionReportsStateModel {
    return state;
  }

  @Selector()
  @ImmutableSelector()
  static selectAnalyticsStateData(state: OverviewAnalyticsStateModel): OverviewAnalyticsStateModel {
    return state;
  }

  @Action(SetProjectOverview)
  setProjectOverview(ctx: StateContext<ProjectOverviewStateModel>, { projectOverview }: SetProjectOverview): void {
    ctx.setState(patch<ProjectOverviewStateModel>({ projectOverview }));
  }

  @Action(SetTimelineProgress)
  setTimelineProgress(ctx: StateContext<TimelineProgressStateModel>, { timelineProgress }: SetTimelineProgress): void {
    ctx.setState(patch<TimelineProgressStateModel>({ timelineProgress }));
  }

  @Action(SetActiveUsers)
  setActiveUsers(ctx: StateContext<ActiveUsersStateModel>, { activeUsers }: SetActiveUsers): void {
    ctx.setState(patch<ActiveUsersStateModel>({ activeUsers }));
  }

  @Action(SetOptionReports)
  setOptionReports(ctx: StateContext<OptionReportsStateModel>, { optionReports }: SetOptionReports): void {
    ctx.setState(patch<OptionReportsStateModel>({ optionReports }));
  }

  @Action(SetOverviewAnalytics)
  setAnalytics(ctx: StateContext<OverviewAnalyticsStateModel>, {analytics}: SetOverviewAnalytics): void {
    ctx.setState(patch<OverviewAnalyticsStateModel>({analytics}));
  }
}
