<atl-multi-filter-dropdown-v2
  class="threads-list {{ scope }}"
  (resetEvent)="resetAllFilters()"
  [position]="filterDropdownPosition"
  [dropdownContentTop]="dropdownContentTop"
  [selectedItems]="totalFiltersSelected"
  atlWindowResize
  (browserZoomLevel)="setFiltersCount($event)"
  [ngbTooltip]="'Entity.Filters' | translate"
  [disableTooltip]="hideButtonTooltip"
  [openDelay]="tooltipOpenDelay"
  placement="bottom"
  triggers="hover touchstart"
  [resetButtonLabel]="'Shared.Button.Reset_all'"
  (expandEvent)="handleButtonTooltip($event)"
>
  <ng-container ngProjectAs="buttonContent">
    <div class="active-filter">
      <atl-custom-icon [icon]="filterIcon"></atl-custom-icon>
    </div>
    <span
      *ngIf="totalFiltersSelected"
      [class.w-20]="totalFiltersSelected > mediumCountFilter"
      [class.w-30]="totalFiltersSelected >= maxCountFilter"
      class="filter-counter"
      >{{ totalFiltersSelected >= maxCountFilter ? customDisplFilter : totalFiltersSelected }}</span
    >
  </ng-container>
  <ng-container *ngIf="filterDataList" ngProjectAs="dropdownContent">
    <ng-container *ngFor="let list of filterDataList | keyvalue: returnZeroForPipe; let i = index">
      <ng-container
        [ngTemplateOutletContext]="{ list: list.value, title: list.key, i: i }"
        [ngTemplateOutlet]="newFilterRow"
      >
      </ng-container>
    </ng-container>
  </ng-container>
</atl-multi-filter-dropdown-v2>

<ng-template #newFilterRow let-list="list" let-title="title">
  <div class="multi-filter__category-wrapper" *ngIf="showFilterCategory[title]">
    <div class="d-flex justify-content-between align-items-center multi-filter__category-header">
      <div class="cursor-pointer d-inline-flex align-items-center lh-24" (click)="expandFilterCategory(title)">
        <span class="multi-filter__category-title fz-14 text-gray-890">
          {{ title ? (filterLabelTranslate[title] | translate) : (title | upperCaseFirst) }}
        </span>
      </div>
    </div>

    <div *ngIf="title === filterTypes.Units && filterExpandState[title]" class="data-table-header__input-search-wr">
      <img src="/assets/input_search_icon.svg" alt="Search icon" />
      <input
        class="global_input data-table-header__input-search"
        type="text"
        atlReplaceInput
        atlPreventDoubleSpace
        [formControl]="search"
        [placeholder]="'Shared.Entity.Search' | translate"
      />
      <img *ngIf="search.value" src="/assets/input_search_clear.svg" alt="Clear icon" (click)="onClearInput($event)" />
    </div>

    <ng-container *ngIf="filterExpandState[title]">
      <atl-checkbox
        [checkboxLabel]="'Entity.All' | translate"
        [size]="18"
        (changed)="checkAllCategoryItems(title, $event)"
        [ngModel]="title === filterTypes.Units ? allUnitsSelected : allprojectsSelected"
        class="threads-list all-container cursor-pointer"
      ></atl-checkbox>
    </ng-container>

    <div
      *ngIf="filterExpandState[title]"
      [class]="
        'multi-filter__category-items-wrapper mt-14 v-scrollbar ' +
        title +
        (defaultFilterItems === 4 ? ' filter-items' : '')
      "
    >
      <ng-container *ngFor="let item of list; let index = index">
        <atl-checkbox
          (changed)="updateSelectedFilterList($event, title, item)"
          [size]="18"
          [ngModel]="item.selected"
          class="threads-list cursor-pointer"
        >
          <div class="custom-label">
            <p
              class="title fz-14 lh-24 ml-8 ellipsis"
              #filterItem
              [ngbTooltip]="filterItemTemplate"
              [container]="'body'"
              [openDelay]="tooltipOpenDelay"
              [disableTooltip]="filterItem.offsetWidth >= filterItem.scrollWidth"
              placement="right"
              triggers="hover touchstart"
            >
              <ng-template [ngTemplateOutlet]="filterItemTemplate"></ng-template>
            </p>
            <ng-template #filterItemTemplate>{{
              title === filterTypes.Units && (scope === scopes.Global || showProjectsFilter)
                ? item.title + dotDivider + item.projectName
                : (item['title'] | translate)
            }}</ng-template>
            <p class="subtitle fz-12 lh-16 ml-8" *ngIf="title === filterTypes.Units">{{ item['mainBuyer']?.name }}</p>
          </div>
        </atl-checkbox>
      </ng-container>
    </div>
  </div>
</ng-template>
