import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  BaseReportType,
  EDefaultOptionReportType,
  EPackQRCodeStatus,
  FileDownloadModel,
  IPackDocument, PackQRCodeModel,
  TDefaultOptionReportType
} from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  finalize,
  map,
  Observable,
  of,
  Subscription,
  take,
  takeWhile,
  tap
} from 'rxjs';
import { delay, expand, filter, mergeMap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private readonly reloadDelay = 2000;
  private readonly _showTooltipHandler$ = new BehaviorSubject<boolean>(false);
  public showTooltipHandler$ = this._showTooltipHandler$.asObservable();

  private _downloadSubscribe?: Subscription;

  constructor(@Inject('ENVIRONMENT') private environment: IEnvironment, private readonly http: HttpClient) { }

  /**
   * @note Get xls settlement report
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Reports/paths/~1api~1v1~1projects~1{project_id}~1reports~1xls_settlement/get
   */
  public getSettlementReport(projectId: number, includeUnsoldUnits = false): Observable<FileDownloadModel> {
    const params = {
      include_unsold_units: includeUnsoldUnits
    };
    return this.http
      .get<FileDownloadModel>(`${this.environment.apiBaseUrl}/api/v1/projects/${projectId}/reports/xls_settlement`, { params })
      .pipe(map((data) => plainToClass(FileDownloadModel, data)));
  }

  /**
   * @note Get xls economy report
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Reports/paths/~1api~1v1~1projects~1{project_id}~1reports~1xls_economy/get
   */
  public getEconomyReport(projectId: number, includeUnsoldUnits = false): Observable<FileDownloadModel> {
    const params = {
      include_unsold_units: includeUnsoldUnits
    };
    return this.http
      .get<FileDownloadModel>(`${this.environment.apiBaseUrl}/api/v1/projects/${projectId}/reports/xls_economy`, { params })
      .pipe(map((data) => plainToClass(FileDownloadModel, data)));
  }

  /**
   * @note Get PDF Broker report
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Unit-Reports/paths/~1api~1v1~1units~1{unit_id}~1reports~1pdf_broker/get
   */
  public getBrokerReport(unitid: number): Observable<FileDownloadModel> {
    return this.http
      .get<FileDownloadModel>(`${this.environment.apiBaseUrl}/api/v1/units/${unitid}/reports/pdf_broker`)
      .pipe(map((data) => plainToClass(FileDownloadModel, data)));
  }

  /**
   * @note Get xls document generation with Unit(s) wish list(s)
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Projects/paths/~1api~1v1~1projects~1%7Bid%7D~1xls_document/get
   */
  public downloadXls(projectId: number, unitId?: number, includeUnsoldUnits = false): Observable<FileDownloadModel> {
    const params = {
      ...(unitId && { unit_id: unitId.toString() }),
      include_unsold_units: includeUnsoldUnits
    };
    return this.http
      .get(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/xls_document`, {
        params,
      })
      .pipe(map((value) => plainToClass(FileDownloadModel, value)));
  }

  /**
   * @note Create large_pdf_documents
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/LargePdfDocuments/paths/~1api~1v1~1units~1%7Bunit_id%7D~1large_pdf_documents/post
   */
  public downloadPdf(unitId: number): Observable<FileDownloadModel> {
    return this.http
      .post(`${this.environment.apiBaseUrl}api/v1/units/${unitId}/large_pdf_documents`, null)
      .pipe(map((value) => plainToClass(FileDownloadModel, value)));
  }

  public downloadResponceCallback(projectId?: number, type?: EDefaultOptionReportType, unitId?: number, includeUsoldUnits = false): void {
    this._showTooltipHandler$.next(true);
    this._downloadSubscribe = this.downloadRequest(projectId, type, unitId, includeUsoldUnits)
      .pipe(
        tap((file) => {
          window.open(file.document.downloadUrl, '_self');
        }),
        catchError(() => EMPTY),
        finalize(() => {
          this.closeTooltip();
        })
      )
      .subscribe();
  }

  private downloadRequest(
    projId?: number,
    type?: EDefaultOptionReportType,
    unitId?: number,
    includeUsoldUnits = false
  ): Observable<FileDownloadModel> {
    switch (type) {
      case EDefaultOptionReportType.Settlement:
        return this.getSettlementReport(Number(projId), includeUsoldUnits);
      case EDefaultOptionReportType.Economy:
        return this.getEconomyReport(Number(projId), includeUsoldUnits);
      case EDefaultOptionReportType.ContractorEP:
        return this.downloadXls(Number(projId), unitId, includeUsoldUnits);
      case EDefaultOptionReportType.OrderConfirmation:
        return this.downloadPdf(unitId!);
      case EDefaultOptionReportType.BrokerReport:
        return this.getBrokerReport(unitId!);
      default:
        // TODO: it is assumed that there will be custom reports.
        return of(<FileDownloadModel>{});
    }
  }

  public closeTooltip(): void {
    this._downloadSubscribe?.unsubscribe();
    this._showTooltipHandler$.next(false);
  }

  public getTooltipTextByReportType(type?: EDefaultOptionReportType): string {
    return type === EDefaultOptionReportType.BrokerReport || type === EDefaultOptionReportType.OrderConfirmation
      ? 'Option.Newest_Downloading_process'
      : 'Option.Newest_Downloading_process_xls';
  }

  public filterUnitReportsTypes(alltypes: TDefaultOptionReportType[]): TDefaultOptionReportType[] {
    return alltypes.filter((type) => {
      return (
        type.type === EDefaultOptionReportType.BrokerReport ||
        type.type === EDefaultOptionReportType.OrderConfirmation ||
        type.type === EDefaultOptionReportType.ContractorEP
      );
    });
  }

  /**
   * @note Units Report
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Units/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1units~1report/post
   */
  public getUnitsReport(projectId: number, report_type: BaseReportType): void {
    const params: HttpParams = new HttpParams({
      fromObject: { report_type }
    });
    this._showTooltipHandler$.next(true);
    this._downloadSubscribe = this.http
      .post<FileDownloadModel>(`${this.environment.apiBaseUrl}/api/v1/projects/${projectId}/units/report`, {}, {
        params
      })
      .pipe(
        take(1),
        map((data) => plainToClass(FileDownloadModel, data)),
        tap(({ document }) => !document || window.open(document.downloadUrl, '_self')),
        catchError(() => EMPTY),
        finalize(() => this.closeTooltip())
      )
      .subscribe();
  }

  /**
   * @note Buyer Report
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Buyers/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1buyers~1report/post
   */
  public getProjectUsersReport(projectId: number, report_type: BaseReportType, ids?: number[], sort_by?: string): void {
    let params: HttpParams = new HttpParams({
      fromObject: { report_type, ...(sort_by && { sort_by }) }
    });
    if (ids?.length) {
      ids.forEach(id => params = params.append('ids[]', String(id)))
    }
    this._showTooltipHandler$.next(true);
    this._downloadSubscribe = this.http
      .post<FileDownloadModel>(`${this.environment.apiBaseUrl}/api/v1/projects/${projectId}/buyers/report`, {}, {
        params
      })
      .pipe(
        take(1),
        map((data) => plainToClass(FileDownloadModel, data)),
        tap(({ document }) => !document || window.open(document.downloadUrl, '_self')),
        catchError(() => EMPTY),
        finalize(() => this.closeTooltip())
      )
      .subscribe();
  }

  /**
   * @note Firm Buyers Report
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Firm-buyers/paths/~1api~1v1~1firm_buyers~1report/post
   */
  public getFirmUsersReport(report_type: BaseReportType, project_ids?: number[], ids?: number[], sort_by?: string): void {
    let params: HttpParams = new HttpParams({
      fromObject: { report_type, ...(sort_by && { sort_by }) }
    });
    if (ids?.length) {
      ids.forEach(id => params = params.append('ids[]', String(id)))
    }
    if (project_ids?.length) {
      project_ids.forEach(id => params = params.append('project_ids[]', String(id)))
    }
    this._showTooltipHandler$.next(true);
    this._downloadSubscribe = this.http
      .post<FileDownloadModel>(`${this.environment.apiBaseUrl}/api/v1/firm_buyers/report`, {}, { params })
      .pipe(
        take(1),
        map((data) => plainToClass(FileDownloadModel, data)),
        tap(({ document }) => !document || window.open(document.downloadUrl, '_self')),
        catchError(() => EMPTY),
        finalize(() => this.closeTooltip())
      )
      .subscribe();
  }

  createPackPDFDocuments(projectId: number, unitIds: number[] = []): void {
    this._showTooltipHandler$.next(true);
    this._downloadSubscribe = this.http
      .post<{ data: IPackDocument }>(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/pack_large_pdf_documents`,
        {
          unit_ids: unitIds
        }
      )
      .pipe(
        map((res: any) => res.data),
        delay(this.reloadDelay),
        map((data: PackQRCodeModel) => plainToClass(PackQRCodeModel, data)),
        mergeMap((pack: PackQRCodeModel) => this.showDownloadPack(projectId, pack.id)),
        expand((showPack: PackQRCodeModel) => {
          if (showPack.status !== EPackQRCodeStatus.Done && showPack.status !== EPackQRCodeStatus.Error) {
            return of(null).pipe(
              delay(this.reloadDelay),
              mergeMap(() => this.showDownloadPack(projectId, showPack.id))
            );
          }

          return EMPTY;
        }),
        takeWhile((showPack: PackQRCodeModel) => !(showPack.status === EPackQRCodeStatus.Error)),
        filter((showPack: PackQRCodeModel) => showPack.status === EPackQRCodeStatus.Done),
        finalize(() => this.closeTooltip()),
        tap((showPack: PackQRCodeModel) => {
          if (showPack.downloadUrl) {
            window.open(showPack.downloadUrl, '_self');
          }
        })
      ).subscribe();
  }

  public showDownloadPack(projectId: number, id: number): Observable<PackQRCodeModel> {
    return this.http
      .get<{ data: PackQRCodeModel }>(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/pack_large_pdf_documents/${id}`
      )
      .pipe(
        map((res: any) => res.data),
        map((data: PackQRCodeModel) => plainToClass(PackQRCodeModel, data)),
      );
  }
}
