<atl-ngb-modal-header
  atlWindowInnerHeight
  (closeModalEvent)="modalRef.close()"
  [title]="'Button.New_reclamation'"
  [isClient]="true"
></atl-ngb-modal-header>

<div class="reclamation-create__container v-scrollbar p-24">
  <div *ngIf="showBanner" class="reclamation-create__banner-wrapper">
    <div class="reclamation-create__banner-info">
      <img width="16" height="25" src="/assets/currency-dollar.svg" alt="Icon description" />
      <p class="reclamation-create__banner-description">
        <span>{{ 'Entity.Warning' | translate }}:</span> {{ 'Reclamation.Description.Warning' | translate }}
      </p>
    </div>
    <div class="reclamation-create__banner-link-wr">
      <span class="reclamation-create__banner-link" (click)="hideBannerEvent.emit()">{{
        'Entity.Got_it' | translate
      }}</span>
    </div>
  </div>

  <form class="reclamation-create__form" [formGroup]="form">
    <!-- Description -->
    <div class="reclamation-create__control">
      <div class="reclamation-create__control-title position-relative">
        <img [src]="'assets/text-align-left-gray.svg'" alt="icon" />{{
          'Shared.Reclamation.Input.Description.Label' | translate
        }}
      </div>
      <div class="reclamation-create__control-input">
        <atl-textarea-autoresize
          class="newest-textarea reclamation-description"
          data-cy="cy-reclamation-description"
          formControlName="description"
          [placeholder]="'Shared.Reclamation.Input.Description.Placeholder' | translate"
          [stringMode]="true"
          [maxLength]="descriptionMaxLength"
          [autoresizeOffset]="2"
          [tabindex]="0"
          id="reclamation-description"
          [capitalize]="false"
          [absolutePosition]="true"
        ></atl-textarea-autoresize>
      </div>
    </div>
    <!-- Category -->
    <div class="reclamation-create__control">
      <div class="reclamation-create__control-title">
        <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{
          'Shared.Reclamation.Input.Category.Label' | translate
        }}
      </div>
      <div class="reclamation-create__control-input">
        <atl-drop-down-v2
          class="multiple-select-component category"
          data-cy="cy-reclamation-create-select-category"
          formControlName="category"
          [listOfItems]="categories"
          [bindLabel]="'name'"
          [placeholder]="'Shared.Reclamation.Input.Category.Placeholder'"
          [notFoundText]="'Shared.Entity.Not_found' | translate"
          [useCustomItemView]="true"
          [useCustomLabelView]="true"
          [createNotFound]="false"
          [disabled]="conditionModalOpened"
          [class.selected]="form.controls.category.value.length"
        >
          <ng-template atlInputLabelTemplate atlInputTemplate let-item="item">
            <div class="multiple-select-user-card-wrapper">
              <div>
                <span>{{ item?.name }}</span>
                <img
                  class="multiple-select-user-card-remove"
                  src="/assets/multiple_remove_icon.svg"
                  alt="Remove icon"
                  (click)="onRemoveSelectedItem('category', item)"
                />
              </div>
              <img
                class="multiple-select-user-card-checkbox"
                src="/assets/multiple_select_checkbox.svg"
                alt="Check state"
              />
            </div>
          </ng-template>
        </atl-drop-down-v2>
      </div>
    </div>
    <!-- Type -->
    <div class="reclamation-create__control">
      <div class="reclamation-create__control-title">
        <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{ 'Shared.Reclamation.Input.Type.Label' | translate }}
      </div>
      <div class="reclamation-create__control-input">
        <atl-drop-down-v2
          class="multiple-select-component type"
          data-cy="cy-reclamation-create-select-type"
          formControlName="type"
          [listOfItems]="types"
          [bindLabel]="'name'"
          [placeholder]="'Shared.Reclamation.Input.Type.Placeholder'"
          [notFoundText]="'Shared.Entity.Not_found' | translate"
          [useCustomItemView]="true"
          [useCustomLabelView]="true"
          [createNotFound]="false"
          [disabled]="conditionModalOpened"
          [class.selected]="form.controls.category.value.length"
        >
          <ng-template atlInputLabelTemplate atlInputTemplate let-item="item">
            <div class="multiple-select-user-card-wrapper">
              <div>
                <span>{{ item?.name }}</span>
                <img
                  class="multiple-select-user-card-remove"
                  src="/assets/multiple_remove_icon.svg"
                  alt="Remove icon"
                  (click)="onRemoveSelectedItem('type', item)"
                />
              </div>
              <img
                class="multiple-select-user-card-checkbox"
                src="/assets/multiple_select_checkbox.svg"
                alt="Check state"
              />
            </div>
          </ng-template>
        </atl-drop-down-v2>
      </div>
    </div>
    <!-- Room -->
    <div class="reclamation-create__control d-none" *ngIf="rooms.length">
      <div class="reclamation-create__control-title">
        <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{ 'Shared.Reclamation.Input.Room.Label' | translate }}
      </div>
      <div class="reclamation-create__control-input">
        <atl-drop-down-v2
          class="multiple-select-component"
          data-cy="cy-reclamation-create-select-room"
          formControlName="room"
          [listOfItems]="rooms"
          [bindLabel]="'name'"
          [placeholder]="'Shared.Reclamation.Input.Room.Placeholder'"
          [notFoundText]="'Shared.Entity.Not_found' | translate"
          [useCustomItemView]="true"
          [useCustomLabelView]="true"
          [disabled]="conditionModalOpened"
        >
          <ng-template atlInputLabelTemplate atlInputTemplate let-item="item">
            <div class="multiple-select-user-card-wrapper">
              <div>
                <span>{{ item?.name }}</span>
                <img
                  class="multiple-select-user-card-remove"
                  src="/assets/multiple_remove_icon.svg"
                  alt="Remove icon"
                  (click)="onRemoveSelectedItem('room', item)"
                />
              </div>
              <img
                class="multiple-select-user-card-checkbox"
                src="/assets/multiple_select_checkbox.svg"
                alt="Check state"
              />
            </div>
          </ng-template>
        </atl-drop-down-v2>
      </div>
    </div>
    <!-- Product -->
    <div class="reclamation-create__control d-none" *ngIf="products.length">
      <div class="reclamation-create__control-title">
        <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{
          'Shared.Reclamation.Input.Product.Label' | translate
        }}
      </div>
      <div class="reclamation-create__control-input">
        <atl-drop-down-v2
          class="multiple-select-component product"
          data-cy="cy-reclamation-create-select-product"
          formControlName="product"
          [listOfItems]="products"
          [bindLabel]="'name'"
          [placeholder]="'Shared.Reclamation.Input.Product.Placeholder'"
          [notFoundText]="'Shared.Entity.Not_found' | translate"
          [useCustomItemView]="true"
          [useCustomLabelView]="true"
          (emitValueSearch)="onSearchProducts($event)"
          [disabled]="conditionModalOpened"
        >
          <ng-template atlInputLabelTemplate let-item="item">
            <div class="multiple-select-user-card-wrapper">
              <div class="w-100">
                <div
                  #productName
                  [ngbTooltip]="item?.name"
                  [disableTooltip]="productName.offsetWidth >= productName.scrollWidth"
                  [openDelay]="tooltipDelay"
                  triggers="hover touchstart"
                  class="fw-400 lh-24 fz-14 ellipsis"
                >
                  <img
                    [src]="item?.preview ? item.preview.filename?.w260?.url : 'assets/no_images.png'"
                    class="reclamation-create-dropdown-label__product br-8 mr-8 object-fit-cover"
                    alt="Image item"
                  />
                  <span class="text-black-960 ellipsis">{{ item?.name }}</span>
                </div>
                <img
                  class="multiple-select-user-card-remove"
                  src="/assets/multiple_remove_icon.svg"
                  alt="Remove icon"
                  (click)="onRemoveSelectedItem('product', item)"
                />
              </div>
            </div>
          </ng-template>
          <ng-template atlInputTemplate let-item="item">
            <div class="multiple-select-user-card-wrapper">
              <div>
                <div class="fw-400 lh-24 fz-14">
                  <img
                    [src]="item?.preview ? item.preview.filename.w260.url : 'assets/no_images.png'"
                    class="reclamation-create-dropdown-item__product br-8 mr-8 object-fit-cover"
                    alt="Image item"
                  />
                  <span class="text-black-960">{{ item?.name }}</span>
                </div>
                <img
                  class="multiple-select-user-card-remove"
                  src="/assets/multiple_remove_icon.svg"
                  alt="Remove icon"
                  (click)="onRemoveSelectedItem('product', item)"
                />
              </div>
              <img
                class="multiple-select-user-card-checkbox"
                src="/assets/multiple_select_checkbox.svg"
                alt="Check state"
              />
            </div>
          </ng-template>
        </atl-drop-down-v2>
      </div>
    </div>
    <!-- Attachments -->
    <div class="reclamation-create__control">
      <div class="reclamation-create__control-title">
        <img [src]="'assets/attachment.svg'" alt="icon" />{{ 'Shared.Reclamation.Input.Attachments.Label' | translate }}
      </div>
      <div class="reclamation-create__control-input lh-32 fz-14">
        <atl-dropdown-input-file
          id="file-input"
          [placeholder]="'Shared.Entity.Add_attachments' | translate"
          [newOnly]="true"
          [autoSave]="true"
          [format]="acceptedExtensions"
          (addNewFilesToExisting)="setAttachmentCount($event)"
          (addCreateNewFilesToExisting)="tempFileUpload($event)"
          [isLoading]="isLoadingFiles"
          (updateFilePositionsEmitter)="updateFilePositions($event)"
          (viewTempFilesEmitter)="openPreview($event)"
          (deleteTempFile)="removeTempFile($event)"
          [disabled]="conditionModalOpened"
        ></atl-dropdown-input-file>
      </div>
    </div>
    <!-- Reported -->
    <div class="reclamation-create__control">
      <div class="reclamation-create__control-title">
        <img [src]="'assets/calendar-gray.svg'" alt="icon" />{{ 'Shared.Reclamation.Input.Reported.Label' | translate }}
      </div>
      <div class="reclamation-create__control-input">
        <span class="ml-8 lh-32 fz-14">{{ form.controls['reported'].value | date: 'dd.MM.yyyy · H:mm' }}</span>
      </div>
    </div>

    <div>
      <atl-plan-mark [client]="true" [floorPlan]="floorPlanData" (setMarkEvent)="setMark($event)"></atl-plan-mark>
    </div>
  </form>
</div>

<atl-ngb-modal-footer>
  <div class="w-100 d-flex justify-content-end">
    <button
      (click)="modalRef.close()"
      class="newest-button button-gray mr-16"
      data-cy="close-create-reclamation-button"
    >
      {{ 'Button.Cancel' | translate }}
    </button>
    <div [ngbTooltip]="createTooltipText" [disableTooltip]="form.valid" triggers="hover touchstart">
      <button
        (click)="createReclamation()"
        class="newest-button newest-button-spinner"
        [ngClass]="{ 'loading pointer-events-none': isLoading }"
        [disabled]="form.invalid"
        data-cy="create-reclamation-button"
        type="button"
      >
        {{ 'Shared.Reclamation.Button.Create' | translate }}
      </button>
    </div>
  </div>
</atl-ngb-modal-footer>

<ng-template #createTooltipText>
  <p class="tooltip-title">{{ 'Shared.Reclamation.Required_fields' | translate }}:</p>
  <ul class="tooltip-ul">
    <li>{{ 'Shared.Reclamation.Input.Description.Label' | translate }}</li>
    <li>{{ 'Shared.Reclamation.Input.Category.Label' | translate }}</li>
    <li>{{ 'Shared.Reclamation.Input.Type.Label' | translate }}</li>
    <li>{{ 'Shared.Entity.Floor_plan_pin' | translate }}</li>
  </ul>
</ng-template>
