<div *ngIf="step" class="modal-arrow" [class.modal-arrow__bottom-0]="step === 2 || step === 3"></div>
<div class="onboarding">
  <ng-container [ngSwitch]="step">
    <ng-container *ngSwitchCase="0">
      <img [src]="'/assets/logo-icon.svg'" alt="Icon" />
      <h3 class="fz-16 fw-500 mt-12 lh-24">{{ 'Title.Welcome_to_journey' | translate }}!</h3>
      <div class="onboarding__desc mt-8 lh-24">
        {{ 'Description.Purpose_of_journey' | translate }}
      </div>
      <div class="onboarding__footer d-flex align-items-center justify-content-between mt-16 w-100">
        <p class="fz-14 lh-24 text-black-810 lh-24">
          <span *ngIf="!isSwedishLang">4</span>
          {{ 'Title.Quick_steps' | translate }}
        </p>
        <button (click)="nextStep()" class="newest-button h-px-32 fz-14 fw-500 next-button">
          {{ 'Title.Start' | translate }}
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="1">
      <img src="/assets/menu-icon-new.svg" alt="Icon" />
      <h3 class="fz-16 fw-500 mt-12 lh-24">{{ 'Title.Menu' | translate }}</h3>

      <div class="onboarding__desc mt-4 lh-24">{{ 'Description.Global_menu' | translate }}</div>
      <div class="onboarding__footer d-flex align-items-center justify-content-between mt-24 w-100">
        <ng-container [ngTemplateOutlet]="currentStep"> </ng-container>
        <button (click)="nextStep()" class="newest-button h-px-32 fz-14 fw-500 next-button">
          {{ 'Title.Next' | translate }}
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="2">
      <img src="/assets/help-center-icon.svg" alt="Icon" />
      <h3 class="fz-16 fw-500 mt-12 lh-24">{{ 'Title.Help_center' | translate }}</h3>

      <div class="onboarding__desc mt-4 lh-24">{{ 'Description.Help_center' | translate }}</div>
      <div class="onboarding__footer d-flex align-items-center justify-content-between mt-24 w-100">
        <ng-container [ngTemplateOutlet]="currentStep"> </ng-container>
        <ng-container [ngTemplateOutlet]="navSteps"> </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="3">
      <img src="/assets/profile-icon.svg" alt="Icon" />
      <h3 class="fz-16 fw-500 mt-12 lh-24">{{ 'Title.Profile_settings' | translate }}</h3>

      <div class="onboarding__desc mt-4 lh-24">{{ 'Description.Profile_intro' | translate }}</div>
      <div class="onboarding__footer d-flex align-items-center justify-content-between mt-24 w-100">
        <ng-container [ngTemplateOutlet]="currentStep"> </ng-container>
        <ng-container [ngTemplateOutlet]="navSteps"> </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="4">
      <img src="/assets/building-icon.svg" alt="Icon" />
      <h3 class="fz-16 fw-500 mt-16 lh-24">{{ 'Title.Projects' | translate }}</h3>

      <div class="onboarding__desc mt-4 lh-24">{{ 'Description.Projects_intro' | translate }}</div>
      <div class="onboarding__footer d-flex align-items-center justify-content-between mt-24 w-100">
        <ng-container [ngTemplateOutlet]="currentStep"> </ng-container>
        <ng-container [ngTemplateOutlet]="navStepsDone"> </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #currentStep>
  <p class="fz-14 lh-24 text-black-810 lh-24">
    {{ step }} <span class="lowercase">{{ 'Entity.Of' | translate }}</span> 4
    <ng-container *ngIf="step !== 4">
      &bull;
      <span (click)="skipAllSteps()" class="cursor-pointer underline"> {{ 'Title.Skip_all' | translate }}</span>
    </ng-container>
  </p>
</ng-template>

<ng-template #navStepsDone>
  <div class="d-inline-flex">
    <button (click)="prevStep()" class="newest-button newest-button--gray h-px-32 mr-8 fz-14 fw-500">
      {{ 'Title.Previous' | translate }}
    </button>
    <button (click)="skipAllSteps()" class="newest-button h-px-32 fz-14 fw-500 next-button">
      {{ 'Shared.Button.Done' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #navSteps>
  <div class="d-inline-flex">
    <button (click)="prevStep()" class="newest-button newest-button--gray h-px-32 mr-8 fz-14 fw-500">
      {{ 'Title.Previous' | translate }}
    </button>
    <button (click)="nextStep()" class="newest-button h-px-32 fz-14 fw-500 next-button">
      {{ 'Title.Next' | translate }}
    </button>
  </div>
</ng-template>
