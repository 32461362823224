import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ELang, ELocalStorageKeys } from '@atlas-workspace/shared/models';
import { LocalStorageService, OnboardingService } from '@atlas-workspace/shared/service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'atl-onboarding-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingModalComponent implements OnInit {
  @Input() public step = 0;

  public isSwedishLang = false;

  constructor(
    protected readonly ngbActiveModal: NgbActiveModal,
    protected readonly localStorageService: LocalStorageService,
    protected readonly onboardingService: OnboardingService
  ) {}

  ngOnInit(): void {
    const lang = this.localStorageService.get(ELocalStorageKeys.Language);
    this.isSwedishLang = lang === ELang.Swedish;
  }

  public nextStep(): void {
    this.step++;
    this.saveStepToStorage();
    this.onboardingService.setCurrentStep(this.step);
  }

  public prevStep(): void {
    this.step--;
    this.saveStepToStorage();
    this.onboardingService.setCurrentStep(this.step);
  }

  public skipAllSteps(): void {
    this.ngbActiveModal.close();
    this.onboardingService.setCompleteOnboarding();
    this.localStorageService.remove(ELocalStorageKeys.GlobalOnboardingStep);
  }

  private saveStepToStorage(): void {
    this.localStorageService.set(ELocalStorageKeys.GlobalOnboardingStep, this.step);
  }
}
