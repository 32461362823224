<!-- 
  ******************************************************
  @Cypress
  - interaction with All units btn
  - interaction with folder item
  - interaction with submit button
  ******************************************************
-->
<div class="move-to-modal">
  <atl-ngb-modal-header
    class="configure-builder-modal__header"
    [title]="title"
    (closeModalEvent)="closeModal()"
  ></atl-ngb-modal-header>

  <div class="move-to-modal__content v-scrollbar">
    <div class="move-to-modal__folders">
      <ng-container [ngTemplateOutlet]="breadcrumbsBlock"> </ng-container>
      <div
        *ngFor="let folder of folders; trackBy: trackByFn"
        class="d-flex move-to-modal__folder align-items-center justify-content-between"
        data-cy="move-to-modal-folder-item"
        [ngClass]="{
          'move-to-modal__selected': selectedFolderId === folder?.id,
          'move-to-modal__current-folder': folder?.id === currentDocument?.id || folder.disabled
        }"
        [attr.data-cy-id]="folder?.id"
        (click)="selectFolder(folder)"
        (dblclick)="openNestedFolder(folder)"
      >
        <div class="d-flex" [class.radio-icon-hover]="selectedFolderId !== folder?.id">
          <img
            class="mr-8 cursor-pointer"
            [src]="selectedFolderId === folder?.id ? '/assets/radio_button_blue.svg' : '/assets/message-folder.svg'"
            alt="icon"
          />
          <p class="fz-16 lh-24 folder-title ellipsis">{{ folder.localizedTitle }}</p>
        </div>
        <img
          (click)="stopPropagation($event); openInnerFolder(folder)"
          class="invisible arrow-icon"
          src="/assets/arrow-right.svg"
          alt="icon"
        />
      </div>

      <div *ngIf="!folders?.length" class="move-to-modal__empty d-flex w-100 align-items-center justify-content-center">
        <img src="/assets/folder-icon.svg" alt="icon" />
        <p class="mt-16 fz-16 lh-24 text-black-810">{{ 'Shared.Description.This_folder_is_empty' | translate }}</p>
      </div>
    </div>
  </div>

  <div class="move-to-modal__footer d-flex justify-content-between align-items-center">
    <p class="invisible text-blue-955 cursor-pointer fz-16 lh-24 fw-500">+ {{ 'Button.Add_folder' | translate }}</p>

    <div class="d-inline-flex align-items_center">
      <button (click)="closeModal()" class="newest-button button-gray mr-16">
        {{ 'Shared.Button.Cancel' | translate }}
      </button>
      <button (click)="moveSelectedToFolder()" [disabled]="disabledMoveIn" class="newest-button" data-cy="move-modal-button">
        {{ 'Shared.Button.Move' | translate }}
      </button>
    </div>
  </div>
</div>

<ng-template #breadcrumbsBlock>
  <div class="move-to-modal__breadcrumbs mb-16 ml-12 d-inline-flex">
    <p (click)="openRootFolder()" class="fz-16 lh-24 fw-500 mr-4 cursor-pointer" data-cy="move-to-all-units">
      {{ parentDirectory | translate }}
    </p>
    <div *ngFor="let item of breadcrumbs; let last = last; let i = index" class="d-flex align-items-center">
      <atl-default-dropdown *ngIf="dropdownDocuments?.length && i === 1" class="d-inline-flex align-items-center">
        <img width="16" height="16" class="mr-4" src="/assets/arrow-right.svg" alt="Arrow" />
        <div ngbDropdown container="body" class="d-flex">
          <div
            class="cursor-pointer dropdown-trigger d-flex align-items-center justify-content-center mr-6"
            id="dropdownMenu"
            ngbDropdownToggle
          >
            <img src="/assets/black-dots.svg" alt="icon" />
          </div>

          <div ngbDropdownMenu aria-labelledby="dropdownMenu" class="dropdown-menu-280 folder-dropdown">
            <div *ngFor="let item of dropdownDocuments; let last = last; trackBy: trackByFn" class="w-100">
              <button
                class="fz-14 ellipsis"
                (click)="loadParetFolder(item.id)"
                ngbDropdownItem
                [class.last-item]="last"
              >
                {{ item.title }}
              </button>
            </div>
          </div>
        </div>
      </atl-default-dropdown>

      <ng-container *ngIf="!hasDocumentsInDropdown(item.id)">
        <img width="16" height="16" class="mr-4" src="/assets/arrow-right.svg" alt="Arrow" />
        <span
          [class.pointer-events-none]="last"
          (click)="loadParetFolder(item.id)"
          class="fz-16 lh-24 fw-500 mr-4 cursor-pointer"
        >
          {{ item.title | truncate: 14 }}
        </span>
      </ng-container>
    </div>
  </div>
</ng-template>
