import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RestoreScrollDirective } from '@atlas-workspace/shared/directives';
import { NewestConfirmModalComponent } from '@atlas-workspace/shared/modals';
import {
  EThreadFolders,
  EThreadQuickFilterKey,
  EThreadScope,
  IDraftRemoveData,
  ITablePagination,
  IThreadFilterModel,
  IThreadInboxSubFoldersFilter,
  ThreadDraftModel,
  ThreadModel,
  ThreadsFilterItemModel,
  ThreadsReclamationFilters,
  ThreadType,
} from '@atlas-workspace/shared/models';
import {
  AuthAdminService,
  CableService,
  ModalFacadeService,
  RestThreadsService,
  SharedUiStorageService,
  ThreadsHelperService,
} from '@atlas-workspace/shared/service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, take } from 'rxjs/operators';

import emptyIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/empty_threads_icon.svg';

import { filterDataType } from '../../helpers/threads';
import { getEmptyFolderTranslateKey, getFolderTitleTranslateKey } from '../../helpers/threads-transtate-keys.helper';
import { CreateThreadComponent } from '../create-thread/create-thread.component';
import { ListOfThreadsDataSource } from './list-of-threads.data-source';

@UntilDestroy()
@Component({
  selector: 'atl-list-of-threads',
  templateUrl: './list-of-threads.component.html',
  styleUrls: ['./list-of-threads.component.scss'],
})
export class ListOfThreadsComponent implements OnInit, OnDestroy {
  @ViewChild(RestoreScrollDirective) restoreScrollDirective?: RestoreScrollDirective;
  @Input() inputScope?: EThreadScope;
  @Input() showProjectsFilter = false;

  public scope!: EThreadScope;
  public readonly scopes: typeof EThreadScope = EThreadScope;
  listType: EThreadFolders = EThreadFolders.Inbox;
  threadTypes: Array<ThreadType> = ['inbox', 'done', 'drafts', 'sent', 'all'];
  emptyFolderText = '';
  listOfThreads: ThreadModel[] | ThreadDraftModel[] = [];
  folderTitle!: string;
  public tableDataSource!: ListOfThreadsDataSource;
  isThreadViewComponentEmbedded = false;
  foldersType = EThreadFolders;

  public firmId?: number;
  public projectId?: number;
  public readonly dotDivider = ' · ';
  public readonly tooltipOpenDelay = 500;
  private readonly searchInterval = 300;
  public readonly emptyThreadIcon = emptyIcon;
  public defaultPagination: ITablePagination = {
    currentPage: 1,
    pageItems: 20,
    totalCount: 0,
    totalPages: 1,
  };
  public noResult = {
    icon: 'assets/glass-no-result.svg',
    title: 'Shared.No_results',
    description: 'Shared.No_search_result',
  };
  private pagination: ITablePagination | undefined;
  public form!: FormGroup;
  public hideSearchTooltip = false;
  public searchParam = '';
  public sortParam = '';
  public filters: { [key: string]: ThreadsFilterItemModel[] | IThreadInboxSubFoldersFilter } = {
    state: [],
    subFolders: {},
  };
  private filterData!: ThreadsFilterItemModel[];
  public activeThreadId!: number | null;
  public currentUser!: { [key: string]: string | number };

  private modalRef: NgbModalRef | null = null;
  private deleteModalRef: NgbModalRef | undefined;
  public focusedSearch = false;

  constructor(
    public threadsHelperService: ThreadsHelperService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private restThreadsService: RestThreadsService,
    private cableService: CableService,
    private authService: AuthAdminService,
    private modalFacadeService: ModalFacadeService,
    private sharedUiStorageService: SharedUiStorageService,
  ) {
    const isReclamationThreadScope = this.route.snapshot.data?.type === EThreadScope.ReclamationThreadView;
    if (!isReclamationThreadScope && !this.threadTypes.includes(this.route?.snapshot.params['list_type'])) {
      void this.router.navigate([this.router.url, 'inbox']);
    }
    this.scope = this.route?.snapshot.data.type;
  }

  ngOnInit(): void {
    if (!this.scope && this.inputScope) {
      this.scope = this.inputScope;
    }
    if (this.authService.getUserInfo) this.currentUser = this.authService.getUserInfo;
    if (this.scope === EThreadScope.Project) {
      this.projectId = +this.route.snapshot.parent?.parent?.parent?.params['projectId'];
      this.initDefaultFilters();
    } else if (this.scope === EThreadScope.ReclamationThreadView) {
      this.projectId = +this.route.snapshot.parent?.parent?.parent?.parent?.params['projectId'];
      this.initDefaultFilters();
    } else if (this.scope === EThreadScope.Global) {
      this.firmId = this.authService.firm?.firmId;
    }
    this.route.children.length
      ? (this.activeThreadId = this.route.children[0].snapshot.params['id'])
      : (this.activeThreadId = null);

    this.tableDataSource = new ListOfThreadsDataSource();
    if (this.scope !== EThreadScope.ReclamationThreadView) {
      this.formTitlesData();
      this.subscribeToRouterNav();
    } else {
      this.listType = EThreadFolders.All;
    }
    this.subscribeToPagination();
    this.initSearchForm();
    this.subscribeToThreadReadStateChange();
    this.subscribeToCableThreadsSnapshots();
    this.subscribeToSingleFilter();
    this.subscribeToCustomFilter();

    if (this.scope !== EThreadScope.Global && this.scope !== EThreadScope.Project) this.getListOfThreads();
    this.subscribeToListUpdate();
  }

  private initDefaultFilters(): void {
    this.filterData = cloneDeep(filterDataType.type);
    if (this.scope === EThreadScope.Project) {
      const filters = this.filterData.filter((i) => i.selected);
      this.threadsHelperService.setCustomFilter({ type: filters });
    } else {
      const filters = this.filterData.filter((i) => i.value === EThreadQuickFilterKey.Reclamation);
      //TODO added next line as a hot fix for reclamation thread view
      if (this.scope === EThreadScope.ReclamationThreadView) filters[0].value = 'reclamation';
      this.threadsHelperService.setCustomFilter({ type: filters });
    }
  }

  private subscribeToListUpdate(): void {
    this.threadsHelperService.updateList$
      .pipe(untilDestroyed(this))
      .subscribe((thread: ThreadModel | ThreadDraftModel) => {
        if (this.listType === EThreadFolders.Drafts && thread) {
          (this.listOfThreads as ThreadDraftModel[]).unshift(thread as ThreadDraftModel);
        }
      });
  }

  private subscribeToCableThreadsSnapshots(): void {
    this.cableService.threads$.pipe(untilDestroyed(this)).subscribe((thread) => {
      if (!thread.state) {
        return;
      }
      if (this.scope === EThreadScope.Project && this.projectId !== thread.project?.id) {
        return;
      }
      if (
        (this.listType == EThreadFolders.Inbox && !thread.state.done) ||
        (this.listType == EThreadFolders.Done && thread.state.done) ||
        (this.listType == EThreadFolders.Sent && thread.state.answered) ||
        this.listType === EThreadFolders.All
      ) {
        this.getListOfThreads(true);
      } else {
        const index = (<ThreadModel[]>this.listOfThreads).findIndex((item) => item.id === thread.id);
        if (index !== -1) {
          this.listOfThreads.splice(index, 1);
          this.tableDataSource.loadTable(this.listOfThreads);
        }
      }
    });

    this.cableService.updateThreadLastMessage$
      .pipe(untilDestroyed(this))
      .subscribe(({ messageThreadId, lastMessage }) => {
        if (this.listType === EThreadFolders.Drafts) {
          return;
        }
        const updatedThreadInstance = (<ThreadModel[]>this.listOfThreads).find((item) => item.id === messageThreadId);
        if (updatedThreadInstance) {
          updatedThreadInstance.lastMessage = lastMessage;
          !updatedThreadInstance.unreadCount || --updatedThreadInstance.unreadCount;
        }
      });
  }

  private subscribeToThreadReadStateChange(): void {
    this.threadsHelperService
      .getThreadStateReadId()
      .pipe(untilDestroyed(this))
      .subscribe((id) => {
        const itemIndexToChange = (this.listOfThreads as ThreadModel[]).findIndex(
          (item: ThreadModel) => item.id === id,
        );
        if (itemIndexToChange !== -1) {
          const updatedItem = {
            ...(this.listOfThreads as ThreadModel[])[itemIndexToChange],
            state: { ...(this.listOfThreads as ThreadModel[])[itemIndexToChange].state, read: true },
          };
          (this.listOfThreads as ThreadModel[])[itemIndexToChange] = updatedItem;
          this.cableService.updateFolderCounters$.next(true);
        }
      });
  }

  private subscribeToSingleFilter(): void {
    this.threadsHelperService
      .getSingleFilter()
      .pipe(untilDestroyed(this))
      .subscribe((filterObj) => {
        if (ThreadsReclamationFilters.All in filterObj || ThreadsReclamationFilters.MyReclamations in filterObj) {
          this.filters = {
            ...this.filters,
            reclamationView: filterObj,
          };
          this.getListOfThreads();
        } else {
          this.filters = {
            ...this.filters,
            subFolders: filterObj,
          };
          if (this.listType === EThreadFolders.Inbox) this.getListOfThreads();
        }
      });
  }

  private subscribeToCustomFilter(): void {
    this.threadsHelperService
      .getCustomFilter()
      .pipe(untilDestroyed(this))
      .subscribe((filterObj) => {
        if (this.listType === EThreadFolders.Drafts) {
          this.getListOfDrafts();
        } else {
          this.filterThreadList(filterObj as IThreadFilterModel);
        }
      });
  }

  private subscribeToRouterNav(): void {
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route.children.length
          ? (this.activeThreadId = this.route.children[0].snapshot.params['id'])
          : (this.activeThreadId = null);
        if (this.listType === this.route?.snapshot.params['list_type']) return;
        this.formTitlesData();
        this.listOfThreads = [];
        this.getListOfThreads();
      }
    });
  }

  formTitlesData(): void {
    this.listType = this.route?.snapshot.params['list_type'];
    this.folderTitle = this.translateService.instant(getFolderTitleTranslateKey(this.listType));
    this.folderTitle = this.folderTitle.toLowerCase();
    this.emptyFolderText = getEmptyFolderTranslateKey(this.listType);
  }

  private subscribeToPagination(): void {
    this.restThreadsService.pagination$.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value) {
        this.pagination = value;
      }
    });
  }

  private initSearchForm(): void {
    this.form = this.restThreadsService.threadForm;

    this.form
      .get('search')
      ?.valueChanges.pipe(debounceTime(this.searchInterval), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((val) => {
        this.searchParam = val;
        this.getListOfThreads();
      });
  }

  private resetSearch(): void {
    this.form.get('search')?.setValue('');
  }

  public handleSearchTooltip(value: boolean): void {
    this.hideSearchTooltip = value;
    this.focusedSearch = value;
  }

  filterThreadList(filters: IThreadFilterModel): void {
    const filterObj = {
      ...this.filters,
      ...filters,
    };

    this.filters = filterObj;

    this.restThreadsService
      .getThreads(
        this.projectId || null,
        this.listType,
        this.searchParam,
        this.sortParam,
        this.defaultPagination,
        this.filters,
      )
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.listOfThreads = res.messageThreads;
        this.threadsHelperService.setReclamationFiltersCounters(res.indexCounter);
        this.tableDataSource.loadTable(this.listOfThreads);
      });
  }

  getListOfThreads(skipPagination = false): void {
    if (this.listType === EThreadFolders.Drafts) {
      this.getListOfDrafts(skipPagination);
      return;
    }
    const pagination: ITablePagination = !skipPagination
      ? this.defaultPagination
      : {
          ...this.defaultPagination,
          ...(this.pagination && {
            pageItems: this.pagination.pageItems * this.pagination.currentPage,
          }),
        };

    this.restThreadsService
      .getThreads(
        this.projectId || null,
        this.listType,
        this.searchParam,
        this.sortParam,
        pagination,
        this.filters,
        skipPagination,
      )
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.listOfThreads = res.messageThreads;
        this.tableDataSource.loadTable(this.listOfThreads);
        if (skipPagination) {
          this.restoreScrollDirective?.restore();
        } else {
          queueMicrotask(() => this.restoreScrollDirective?.fire());
        }
      });
  }

  getListOfDrafts(skipEmittingPagination = false): void {
    this.restThreadsService
      .getDraftThreads(
        this.projectId || null,
        this.searchParam,
        this.sortParam,
        this.defaultPagination,
        undefined,
        skipEmittingPagination,
      )
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.listOfThreads = res;
        this.tableDataSource.loadTable(this.listOfThreads);
      });
  }

  loadMoreThreads(last: boolean): void {
    if (last) {
      if (this.pagination?.currentPage && this.pagination.currentPage + 1 > this.pagination?.totalPages) {
        return;
      } else {
        !this.pagination?.currentPage || this.pagination.currentPage++;
      }
      if (this.listType === EThreadFolders.Drafts) {
        this.restThreadsService
          .getDraftThreads(this.projectId || null, this.searchParam, this.sortParam, this.defaultPagination)
          .pipe(untilDestroyed(this))
          .subscribe((res) => {
            this.listOfThreads = [...(this.listOfThreads as ThreadDraftModel[]), ...res];
            this.tableDataSource.loadTable(this.listOfThreads);
          });
      } else {
        this.restThreadsService
          .getThreads(
            this.projectId || null,
            this.listType,
            this.searchParam,
            this.sortParam,
            this.pagination,
            this.filters,
          )
          .pipe(untilDestroyed(this))
          .subscribe((res) => {
            this.listOfThreads = [...(this.listOfThreads as ThreadModel[]), ...res.messageThreads];
            this.tableDataSource.loadTable(this.listOfThreads);
          });
      }
    }
  }

  goToThreadView(id: string): void {
    if (this.scope === EThreadScope.ReclamationThreadView) {
      this.threadsHelperService.setCustomThreadId(id);
      this.activeThreadId = +id;
    } else {
      void this.router.navigate([id], { relativeTo: this.route });
    }
  }

  openDraftThreadModal(thread: ThreadDraftModel): void {
    this.modalFacadeService.closeModal();

    this.modalRef = this.modalFacadeService.openModal(CreateThreadComponent, {
      ...this.sharedUiStorageService.modalCreateThread,
      windowClass: `pointer-events-none ${this.sharedUiStorageService.modalCreateThread.windowClass}`,
      modalDialogClass: `${this.sharedUiStorageService.modalCreateThread.modalDialogClass} ${this.scope}`,
    });
    this.modalRef.componentInstance.modalRef = this.modalRef;
    if (this.scope === EThreadScope.Global) {
      this.modalRef.componentInstance.firmId = this.firmId;
    }
    if (this.scope === EThreadScope.Project) {
      this.modalRef.componentInstance.projectId = this.projectId;
    }
    this.modalRef.componentInstance.scope = this.scope;
    this.modalRef.componentInstance.draftData = thread;
    this.modalRef.componentInstance.isDraft = true;
    this.activeThreadId = thread.id;
    this.modalRef.componentInstance.closeModal.subscribe(() => {
      this.modalRef?.close();
    });

    this.modalRef.componentInstance.removeDraft.pipe(untilDestroyed(this)).subscribe((removeData: IDraftRemoveData) => {
      this.openRemoveDraft(removeData.id, removeData.confirm);
    });

    this.modalRef.componentInstance.createDraftEvent.pipe(untilDestroyed(this)).subscribe((val: ThreadDraftModel) => {
      if (this.listType === EThreadFolders.Drafts) (this.listOfThreads as ThreadDraftModel[]).unshift(val);
    });

    this.modalRef.componentInstance.updateDraftEvent.pipe(untilDestroyed(this)).subscribe((val: ThreadDraftModel) => {
      const draftIndex = this.listOfThreads.findIndex((m: ThreadDraftModel | ThreadModel) => +m.id === +val.id);
      (this.listOfThreads as ThreadDraftModel[])[draftIndex] = val;
    });

    this.listenCloseEvent(this.modalRef);
  }

  private listenCloseEvent(modalRef: NgbModalRef): void {
    modalRef.dismissed.pipe(untilDestroyed(this)).subscribe(() => {
      this.destroyModal();
    });
    modalRef.closed.pipe(untilDestroyed(this)).subscribe(() => {
      this.destroyModal();
    });
    modalRef.result.then(() => {
      this.destroyModal();
    });
  }

  public openRemoveDraft(id: number, confirm = true): void {
    const confirmation$ = confirm ? this.getConfirmationComponentEmitter() : of(true);

    confirmation$
      .pipe(
        switchMap(() => {
          if (this.modalRef?.componentInstance) this.modalRef.close();
          this.deleteModalRef?.close();
          return this.restThreadsService.deleteDraft(id);
        }),
        take(1),
      )
      .subscribe(() => {
        const removableIndex = this.listOfThreads.findIndex((m: ThreadDraftModel | ThreadModel) => m.id === id);
        this.listOfThreads.splice(removableIndex, 1);
        this.cableService.updateFolderCounters$.next(true);
      });
  }

  private getConfirmationComponentEmitter(): EventEmitter<boolean> {
    this.deleteModalRef = this.modalFacadeService.openModal(NewestConfirmModalComponent, {
      centered: true,
      windowClass: 'confirm-action-modal-v2',
    });
    const instance = <NewestConfirmModalComponent>this.deleteModalRef.componentInstance;
    instance.title = this.translateService.instant(`Shared.Threads.Delete_draft`);
    instance.description = this.translateService.instant(`Shared.Threads.Delete_draft_description`);
    instance.button.text = this.translateService.instant('Shared.Button.Yes_delete');
    return instance.passConfirm;
  }

  private destroyModal(): void {
    this.modalRef = null;
    this.activeThreadId = null;
  }

  public trackBylistOfThreads(_: number, item: ThreadModel | ThreadDraftModel): number {
    return item.id;
  }

  ngOnDestroy(): void {
    this.resetSearch();
  }
}
