import {ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {cloneDeep} from 'lodash';

//@ts-ignore
import arrowDownIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/arrow-down-sm.svg';

@Component({
  selector: 'atl-custom-filter-dropdown',
  templateUrl: './custom-filter-dropdown.component.html',
  styleUrls: ['./custom-filter-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFilterDropdownComponent),
      multi: true,
    },
  ],
})
export class CustomFilterDropdownComponent implements ControlValueAccessor {
  @Input() set setListOfItems(value: any) {
    this.listOfItems = cloneDeep(value);
  }
  @Input() listOfItems!: any[];
  @Input() disabled = false;
  @Input() clearable = false;
  @Input() bindLabel!: string;
  @Input() bindValue!: string;
  @Input() showArrow = false;
  @Input() placeholder = '';
  @Input() title = '';
  @Input() asButton = true;
  @Input() searchable = true
  @Input() countViewSelectItems = 1;
  @Input() singleSelect = false;
  @Input() allPlaceholder = '';
  @Input() chips = false;

  @Output() selectHandler = new EventEmitter();

  public selectValue: any[] = [];
  public readonly arrowDownIcon = arrowDownIcon;
  public readonly tooltipOpenDelay = 400;

  public expanded = false;
  public search = '';

  protected onChange: (value: any[]) => void = () => null;
  protected onTouched: () => void = () => null;

  constructor(private readonly cdr: ChangeDetectorRef, private readonly translateService: TranslateService) {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any[] | null): void {
    this.selectValue = value ?? [];
  }

  eventStop(e: Event): void {
    e.stopPropagation();
  }

  expandHandler(): void {
    if (this.disabled) return;
    this.expanded = !this.expanded;
  }

  closeExpand(): void {
    this.expanded = false;
    this.search = '';
  }

  itemSelect(item: any): void {
    const itemSelect = item.selected;
    if (this.singleSelect) {
      this.allSelectList(false);
    }
    item.selected = !itemSelect;
    this.selectValue = this.getSelectList;
    this.changeValue();
  }

  clearList(e: Event): void {
    e.stopPropagation();
    if (!this.selectValue.length) return;
    this.clearAll();
    this.changeValue();
  }

  private clearAll(): void {
    this.selectValue = [];
    this.listOfItems.map((item: any) => (item.selected = false));
  }

  searchHandler(e: Event): void {
    this.eventStop(e);
    this.search = (e.target as HTMLInputElement)?.value || '';
    this.cdr.detectChanges();
  }

  get listFiltered(): any[] {
    this.listOfItems?.map(item => {
      item.selected = this.selectValue.some(s => s[this.bindValue] === item[this.bindValue])
      return item;
    });
    return this.listOfItems?.filter((item: any) => item[this.bindLabel].toLowerCase().includes(this.search.toLowerCase()));
  }

  get label(): string {
    return this.selectValue.length
      ? this.selectValue.slice(0, this.countViewSelectItems)
        .map(item => this.translateService.instant(item[this.bindLabel])).join(', ')
      : this.translateService.instant(this.placeholder);
  }

  get arrLabel(): string[] {
    return this.selectValue.length
      ? this.selectValue.slice(0, this.countViewSelectItems)
        .map(item => this.translateService.instant(item[this.bindLabel]))
      : [this.translateService.instant(this.placeholder)];
  }

  private get getSelectList(): number[] {
    return this.listOfItems.filter((item: any) => item.selected);
  }

  private allSelectList(select: boolean): void {
    this.listOfItems.map((item: any) => item.selected = select)
  }

  private changeValue(): void {
    // @ts-ignore
    const value = this.bindValue ? this.selectValue.map(item => item[this.bindValue]) : this.selectValue;
    this.selectHandler.emit(value);
    this.onChange(value);
  }
}
