import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  Type,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import { DateAdapterService, DropdownInputFileComponent, FileValidators } from '@atlas-workspace/shared/form';
import { ModalHelpersService } from '@atlas-workspace/shared/modals';
import {
  acceptedGlobalExtensions,
  AdminProjectModel,
  adminStatusesButtons,
  ContractorCompany,
  EAccessTag,
  EFileType,
  EFirmRoles,
  EMeetingTypeId,
  EPositionControl,
  EProjectRoles,
  ERecDiscussionTabId,
  ERecDiscussionTabName,
  EReclamationScope,
  EReclamationStatusKey,
  EReclamationTabAdmin,
  EThreadScope,
  EUserTypeAdmin,
  FileModel,
  FirmContractorModel,
  FloorModel,
  ImageModel,
  IMark,
  IReclamationCategory,
  IReclamationCategoryTypes,
  IReclamationStatusButton,
  ISettingsMenu,
  IStatusChangeList,
  ITablePagination,
  IThreadState,
  IUnitMainBuyer,
  ProductModel,
  ReclamationSettingsModel,
  ReclamationsModel,
  reclamationStatusList,
  RoomModel,
  ThreadModel,
  TReclamationsModelFormGroup,
  UnitFloorModel,
  UnitUserModel,
} from '@atlas-workspace/shared/models';
import {
  AdminProfileService,
  CableService,
  CompaniesService,
  ContractorService,
  CustomNotesService,
  LockFieldService,
  MessageBannerService,
  ModalFacadeService,
  NetworkService,
  PageHelperService,
  PaginationUtil,
  ProjectMembersService,
  ProjectService,
  RandomColorService,
  ReclamationAdminService,
  ReclamationHelperService,
  RestThreadsService,
  RouterUtil,
  SharedUiStorageService,
  ThreadsHelperService,
} from '@atlas-workspace/shared/service';
import { TextEditorData } from '@atlas-workspace/shared/ui';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, expand, map, reduce, take, tap } from 'rxjs/operators';

import messagesIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/admin-header/messages.svg';
import activitiesSvg from '!!raw-loader?!@atlas-workspace/shared/assets/lib/analytics-icon.svg';
import arrowDownIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/arrow-gray-down.svg';
import arrowUpIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/arrow-gray-up.svg';
import dotsIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/dropdown-dot-2.svg';
import messageIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/message-icon-gray-sm.svg';
import closeIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/unit_close.svg';
import warningIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/warning-icon-tringle.svg';

import { INavMenuId, navigationMenu } from '../../../helpers/reclamations.helper';
import { ReclamationsCounter } from '../../../helpers/reclamations-counter.helper';
import { ReclamationAdminDeclineComponent } from '../reclamation-admin-decline/reclamation-admin-decline.component';

@UntilDestroy()
@Component({
  selector: 'atl-reclamation-admin-detail',
  templateUrl: './reclamation-admin-detail.component.html',
  styleUrls: ['./reclamation-admin-detail.component.scss'],
  providers: [ReclamationAdminService, CompaniesService, CustomNotesService],
})
export class ReclamationAdminDetailComponent implements AfterViewInit, OnInit, OnChanges {
  @Input() public componentView = false;
  @Input() public pinMode = false;
  @Input() public messageShow = true;
  @Input() private unitModalId?: number;
  @Input() public openTab?: EReclamationTabAdmin;
  @Input() public openSubTab?: ERecDiscussionTabName;
  @Input() public modalRef!: NgbModalRef;
  @Input() public isLoading = false;
  @Input() public disableSwitchingReclamation = false;
  @Input() public disableOpenReclamation = false;
  @Input() public readonly protocolId: number | undefined;
  @Input() public readonly reclamationScope: EReclamationScope | undefined;
  @Input() public threadViewComponent!: Type<any>;
  @Input() public projectId!: string;

  @Input() set setProjectId(_projectId: number) {
    this.projectId = String(_projectId);
    this.reclamationLoading = true;
    this.firstCategoryLoad = true;
    this.getCategories();
    this.getReclamation();
  }

  @Input() set reclamationId(value: string) {
    if (value) {
      this.reclamationLoading = true;
      this.firstCategoryLoad = true;
      this._reclamationId = +value;
      this.ngZone.onStable.pipe(take(1)).subscribe(() => {
        if (this.reclamationScope !== EReclamationScope.Global) this.getSettings();
        this.getCategories();
        this.getReclamation();
      });
    }
  }

  @Input() isModalNavigation = true;

  @Output() public readonly changeStatusEmit = new EventEmitter();
  @Output() public readonly removeReclamationEmit = new EventEmitter<number>();
  @Output() private readonly changeReclamationHandler = new EventEmitter<EPositionControl>();
  @Output() private readonly openMessagesTabEmit = new EventEmitter<number | null>();
  @Output() private readonly changeThreadStateEvent = new EventEmitter<IThreadState>();
  @Output() public readonly closeReclamationEmit = new EventEmitter();
  @Output() private readonly updateReclamationHandler = new EventEmitter<ReclamationsModel>();
  @Output() private readonly updateReclamationPlan = new EventEmitter<ReclamationsModel>();

  @ViewChild('fileDropdownRef') fileDropdownRef!: DropdownInputFileComponent;

  protected _reclamationId!: number;
  public messagesIcon = messagesIcon;
  public scope!: EThreadScope;
  public readonly routeNavigation = false;
  public activeTab = EReclamationTabAdmin.General;
  public reclamationTabs = EReclamationTabAdmin;
  public navigationMenu: ISettingsMenu[] = cloneDeep(navigationMenu);
  public viewNavigationMenu!: ISettingsMenu[];
  public readonly positionControl = EPositionControl;
  public reclamationLoading!: boolean;
  public statusList = reclamationStatusList;
  public statusKeys = EReclamationStatusKey;
  public status!: EReclamationStatusKey;
  public statusButtonsList = adminStatusesButtons;
  public statusButtons!: IReclamationStatusButton[];
  public statusChangeList!: IStatusChangeList[];

  public reclamation!: ReclamationsModel | null;
  public form!: FormGroup;
  public formStatus!: FormGroup;
  public formNotes!: FormGroup;
  readonly acceptedExtensions = acceptedGlobalExtensions;
  public currentUserId?: number;
  public projectName?: string;
  private unitId!: number;
  public units: UnitUserModel[] = [];
  public categories: IReclamationCategory[] = [];
  public types?: IReclamationCategoryTypes[] = [];
  public rooms: RoomModel[] = [];
  public products: Partial<ProductModel>[] = [];
  public contractors: (ContractorCompany | FirmContractorModel)[] = [];
  public users: AdminProjectModel[] = [];
  public floorPlanData!: UnitFloorModel[] | FloorModel[] | undefined;
  public selectedUnit!: UnitUserModel;
  public preloadFiles!: (FileModel | ImageModel)[];
  public customerMessageCount!: number | undefined;
  public expanded = false;
  private firstReclamationLoad = true;
  private firstCategoryLoad = true;
  private firstContractorLoad = true;
  private projectRole!: string;
  private firmRole?: string;

  public mark?: IMark[];

  public today: Date = new Date();

  public messageIcon = messageIcon;
  private disableFormUpdate = false;

  private readonly daysDelay = 21;
  public defaultDueDate = dayjs(dayjs().add(this.daysDelay, 'day')).format('D.MM.YYYY');
  public mobileView = false;
  public readonly activitiesIcon = activitiesSvg;
  public readonly arrowUpIcon = arrowUpIcon;
  public readonly arrowDownIcon = arrowDownIcon;
  public readonly dotsIcon = dotsIcon;
  public readonly closeIcon = closeIcon;
  public readonly warningIcon = warningIcon;

  public readonly tooltipDelay = 500;
  public longNameTruncate!: number;
  public longEmailTruncate!: number;

  private readonly perPage = 50;

  public reclamationScopesList = EReclamationScope;
  public settings!: ReclamationSettingsModel;
  public ownersList!: IUnitMainBuyer[];
  public readonly apiKey = this.environment.tinyMceApiKey;
  public editorInitConfig = {
    ...TextEditorData.wideEditorConfig(this.translateService),
    placeholder: this.translateService.instant('Entity.Start_writing'),
  };

  public internalActiveTab!: ERecDiscussionTabId | null;
  public showUnitIdentifier!: boolean;
  public isOnline = true;
  public showContractorDetails = false;
  public readThreadIdSubscribe = false;
  private previousThreadId: number | null = null;

  constructor(
    protected modalService: NgbActiveModal,
    protected modalHelpersService: ModalHelpersService,
    protected fb: FormBuilder,
    protected reclamationService: ReclamationAdminService,
    protected randomColorService: RandomColorService,
    protected dateAdapter: DateAdapterService,
    protected modalFacadeService: ModalFacadeService,
    protected sharedUiStorageService: SharedUiStorageService,
    protected cdr: ChangeDetectorRef,
    protected translateService: TranslateService,
    protected projectService: ProjectService,
    protected pageHelperService: PageHelperService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected injector: Injector,
    protected restThreadsService: RestThreadsService,
    protected threadsHelperService: ThreadsHelperService,
    protected cableService: CableService,
    protected routerUtil: RouterUtil,
    protected contractorService: ContractorService,
    protected companiesService: CompaniesService,
    protected profileService: AdminProfileService,
    protected readonly ngZone: NgZone,
    @Inject('ENVIRONMENT') protected environment: IEnvironment,
    protected customNotesService: CustomNotesService,
    protected readonly projectMembersService: ProjectMembersService,
    protected readonly network: NetworkService,
    protected readonly lockFieldService: LockFieldService,
    protected readonly messageBannerService: MessageBannerService,
    protected readonly reclamationCountService: ReclamationsCounter,
    protected readonly reclamationHelperService: ReclamationHelperService,
  ) {
    this.route.data.pipe(untilDestroyed(this)).subscribe((v) => {
      this.scope = v['type'];
      if (!this.projectId && this.scope !== EThreadScope.Global) {
        const segments = this.router.url.split('/');
        this.projectId = segments[segments.indexOf('specific_project') + 1];
      }
    });
  }

  ngOnInit(): void {
    this.networkStatus();
    this.getProfile();
    this.longNameTruncate = this.pinMode ? 20 : 33;
    this.longEmailTruncate = this.pinMode ? 30 : 33;
    this.checkProjectAccess();
    if (this.disableOpenReclamation || !this.reclamation) {
      this.navigationMenu = this.navigationMenu.filter((nav) => nav.id !== INavMenuId.Customer);
      this.cloneNavMenu();
    }
    this.subscribeToUnreadEvent();
    this.subscribeForReclamationUpdates();
    if (!this.messageShow && this.openTab && this.openTab === EReclamationTabAdmin.Messages) return;
    if (this.openTab) this.activeTab = this.openTab;

    if (
      this.reclamationScope ||
      this.reclamationScope === this.reclamationScopesList.ReclamationUnits ||
      this.reclamationScope === this.reclamationScopesList.Units ||
      this.reclamationScope === this.reclamationScopesList.Protocols ||
      this.reclamationScope === this.reclamationScopesList.Meetings
    ) {
      this.showUnitIdentifier = false;
    } else {
      this.showUnitIdentifier = true;
    }
    this.subscribeToTabEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectId) {
      this.projectId = changes.projectId.currentValue;
    }
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  private subscribeToTabEvents(): void {
    this.reclamationHelperService.openDetailsTabEvent$.pipe(untilDestroyed(this)).subscribe(() => {
      this.activeTab = EReclamationTabAdmin.General;
    });
  }

  private networkStatus(): void {
    this.network.isOnline.pipe(untilDestroyed(this)).subscribe((isOnline) => {
      this.isOnline = isOnline;
    });
  }

  private subscribeForReclamationUpdates(): void {
    this.reclamationService.updateReclamation$.pipe(untilDestroyed(this)).subscribe((rec) => {
      this.reclamation = cloneDeep(rec);
      this.form.get('description')?.setValue(this.reclamation.description, { emitEvent: true });
      this.form
        .get('category')
        ?.setValue(this.reclamation.type?.category ? [this.reclamation.type.category] : [], { emitEvent: true });
      this.form.get('type')?.setValue(this.reclamation.type ? [this.reclamation.type] : [], { emitEvent: false });

      this.cdr.detectChanges();
      this.updateReclamationHandler.emit(this.reclamation);
    });
  }

  private subscribeToUnreadEvent(): void {
    this.threadsHelperService.unreadThreadEvent.pipe(untilDestroyed(this)).subscribe(() => {
      this.activeTab = EReclamationTabAdmin.General;
    });
  }

  public get isDisabledMark(): boolean {
    return this.reclamation?.archived || this.isContractorRole || !!this.reclamation?.pointX;
  }

  private initNotesForm(): void {
    this.formNotes = this.fb.group({
      notes: new FormControl(''),
    });

    if (this.reclamation?.archived) {
      this.formNotes.controls.notes.disable();
    }

    this.formNotes
      .get('notes')
      ?.valueChanges.pipe(untilDestroyed(this), debounceTime(500), distinctUntilChanged())
      .subscribe((v: string) => {
        if (!this.reclamation?.archived) this.setNotes(v);
      });
  }

  private setNotes(notes: string): void {
    if (this.reclamation) {
      this.reclamationService.setNotes(this.projectId, this.reclamation.id, notes).pipe(take(1)).subscribe();
    }
  }

  private initNotes(): void {
    if (!this.reclamation?.customNoteId) return;
    this.customNotesService
      .getNote(+this.projectId, this.reclamation.customNoteId)
      .pipe(take(1))
      .subscribe((note) => {
        this.formNotes.get('notes')?.setValue(note, { emitEvent: false, onlySelf: true });
      });
  }

  public isMobile(v: boolean): void {
    this.mobileView = v;
    this.cloneNavMenu();
  }

  private cloneNavMenu(): void {
    if (this.mobileView) {
      this.viewNavigationMenu = cloneDeep(this.navigationMenu.filter((m) => m.id !== INavMenuId.Customer));
    } else {
      this.viewNavigationMenu = cloneDeep(this.navigationMenu);
    }

    this.cdr.detectChanges();
  }

  public setInternalActiveTab(tabId: ERecDiscussionTabId): void {
    this.internalActiveTab = tabId;
  }

  private subscribeToThreadReadStateChange(): void {
    if (this.readThreadIdSubscribe) return;
    this.readThreadIdSubscribe = true;
    this.threadsHelperService
      .getThreadStateReadId()
      .pipe(untilDestroyed(this))
      .subscribe((currentThreadId: number) => {
        if (this.previousThreadId !== currentThreadId) {
          this.previousThreadId = currentThreadId;
          this.updateReclamationCounters();
        }
      });
  }

  private subscribeToCableThreadsSnapshots(): void {
    this.cableService.threads$.pipe(untilDestroyed(this)).subscribe((thread) => {
      if (Number(this.projectId) !== thread.project?.id) {
        return;
      }
      if (thread.id === this.reclamation?.messageThreadId && this.activeTab !== EReclamationTabAdmin.Messages) {
        if (this.reclamation) {
          this.reclamation.messageThreadHasMessages = true;
          this.reclamation.messageThreadUnreadCount = Number(thread.unreadCount);
          this.reclamation.messageThreadState.answered = false;
          this.reclamation.messageThreadState.read = false;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.navigationMenu.find((nav) => nav.id === INavMenuId.Customer)!.counter = thread.unreadCount;
          this.customerMessageCount = thread.unreadCount;
          this.cloneNavMenu();
        }
      }
      if (
        thread.id === this.reclamation?.notesMessageThreadId &&
        this.internalActiveTab !== ERecDiscussionTabId.CompanyName
      ) {
        const countToAdd = this.isContractorRole
          ? thread.unitsCount
          : (thread.unreadCount as number) + (this.reclamation?.onlyAdminsThreadUnreadCount as number);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.navigationMenu.find((nav) => nav.id === INavMenuId.Internal)!.counter = countToAdd;
        if (this.reclamation) this.reclamation.notesMessageThreadUnreadCount = thread.unreadCount;
        this.cloneNavMenu();
      }
      if (
        thread.id === this.reclamation?.onlyAdminsThreadId &&
        this.internalActiveTab !== ERecDiscussionTabId.AdminsOnly
      ) {
        const countToAdd = this.isContractorRole
          ? thread.unitsCount
          : (thread.unreadCount as number) + (this.reclamation?.notesMessageThreadUnreadCount as number);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.navigationMenu.find((nav) => nav.id === INavMenuId.Internal)!.counter = countToAdd;
        if (this.reclamation) this.reclamation.onlyAdminsThreadUnreadCount = thread.unreadCount;
        this.cloneNavMenu();
      }
      if (thread.id && this.activeTab === EReclamationTabAdmin.Messages) {
        if (this.reclamation && thread.id === this.reclamation?.messageThreadId) {
          if (thread instanceof ThreadModel) {
            this.reclamation.messageThreadHasMessages = true;
            this.reclamation.messageThreadState.read = true;
            this.reclamation.messageThreadState.answered = false;
          } else {
            this.reclamation.messageThreadState = thread.state as IThreadState;
            this.reclamation.messageThreadHasMessages = true;
          }
          this.changeThreadStateEvent.emit(this.reclamation.messageThreadState);
        }
      }
    });
  }

  private updateReclamationCounters(): void {
    if (!this.reclamation?.id) return;
    this.reclamationService
      .getReclamation(this.projectId.toString(), this.reclamation.id)
      .pipe(untilDestroyed(this))
      .subscribe((recl) => {
        this.reclamation = recl;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.navigationMenu.find((nav) => nav.id === INavMenuId.Internal)!.counter = this.isContractorRole
          ? this.reclamation.notesMessageThreadUnreadCount
          : (this.reclamation.onlyAdminsThreadUnreadCount as number) +
            (this.reclamation.notesMessageThreadUnreadCount as number);

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.navigationMenu.find((nav) => nav.id === INavMenuId.Customer)!.counter =
          this.reclamation.messageThreadUnreadCount;
        this.customerMessageCount = this.reclamation.messageThreadUnreadCount;

        this.cloneNavMenu();
      });
  }

  private filterStatusButton(statuses: EReclamationStatusKey[]): IReclamationStatusButton[] {
    if (!statuses) return [];
    return statuses
      .map((status) => {
        const foundObject = this.statusButtonsList.find((obj) => obj.status === status);
        if (foundObject) {
          return foundObject;
        }
      })
      .filter((obj) => obj !== undefined) as IReclamationStatusButton[];
  }

  private initStatusChangeList(reclamation: ReclamationsModel): void {
    const statuses = [reclamation.status, ...reclamation.availableStatuses];
    this.statusChangeList = statuses.map((status: EReclamationStatusKey) => ({
      status,
      title: this.translateService.instant(this.statusList[status].title),
    }));
  }

  private markReclamationAsRead(): void {
    this.reclamationService
      .setProjectReclamationsAsRead(this.projectId, [this._reclamationId])
      .pipe(take(1))
      .subscribe(() => {
        if (this.reclamation) {
          this.reclamation.unreadTimestamp = null;
          this.updateReclamationHandler.emit(this.reclamation);
          this.cdr.detectChanges();
          this.getCountReclamation();
        }
      });
  }

  private getCountReclamation(): void {
    this.reclamationCountService.getCount(+this.projectId).pipe(take(1)).subscribe();
  }

  public getReclamation(): void {
    if (!this.projectId || !this.firstReclamationLoad) {
      return;
    }
    this.firstReclamationLoad = false;
    this.reclamationLoading = true;
    this.reclamationService
      .getReclamation(this.projectId, this._reclamationId)
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.projectService.adminProject$.pipe(untilDestroyed(this)).subscribe((project) => {
            this.projectRole = project.currentRole;
            !project || (this.projectName = project.name);
          });
        }),
      )
      .subscribe((reclamation: ReclamationsModel) => {
        this.reclamation = reclamation;
        this.markReclamationAsRead();
        this.initStatusChangeList(this.reclamation);
        this.updateReclamationHandler.emit(reclamation);
        this.status = this.reclamation.status;
        this.statusButtons = this.filterStatusButton(this.reclamation.availableStatuses);
        this.initForm(this.reclamation);
        this.initFormListeners();
        this.initNotesForm();

        this.getUnits();
        if (!this.isContractorRole) {
          this.getUsers();
        }
        this.initNotes();
        this.listenerForFormUpdate();
        this.listenerForStatusFormUpdate();
        if (this.reclamation?.contractorCompany?.id) {
          this.getContractorFieldData(this.reclamation.contractorCompany.id);
        } else {
          this.getContractorFieldData();
        }
        if (this.reclamation.status === this.statusKeys.Draft) {
          this.navigationMenu = this.navigationMenu.filter((x) => x.id === INavMenuId.General);
          this.cloneNavMenu();
        }
        if (this.disableOpenReclamation) {
          this.navigationMenu = this.navigationMenu.filter((nav) => nav.id !== INavMenuId.Customer);
          this.cloneNavMenu();
        }
        if (!this.disableOpenReclamation && this.reclamation.status !== this.statusKeys.Draft) {
          this.navigationMenu = navigationMenu;
          const countToAdd = this.isContractorRole
            ? this.reclamation.notesMessageThreadUnreadCount
            : (this.reclamation.notesMessageThreadUnreadCount as number) +
              (this.reclamation.onlyAdminsThreadUnreadCount as number);
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.navigationMenu.find((nav) => nav.id === INavMenuId.Internal)!.counter = countToAdd;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.navigationMenu.find((nav) => nav.id === INavMenuId.Customer)!.counter =
            this.reclamation.messageThreadUnreadCount;
          this.customerMessageCount = this.reclamation.messageThreadUnreadCount;
          this.cloneNavMenu();

          this.subscribeToThreadReadStateChange();
          this.subscribeToCableThreadsSnapshots();
          this.cdr.detectChanges();
        }
        const customerMenuItem = this.navigationMenu.find((m) => m.id === INavMenuId.Customer)!;
        const isPreInspectionType = this.reclamation.meeting?.meetingType?.id === EMeetingTypeId.PreInspection;
        Object.assign(customerMenuItem, {
          disabled: isPreInspectionType,
          tooltip: isPreInspectionType ? 'Reclamation.No_available_pre_inspection_meeting' : undefined,
        });
        this.cloneNavMenu();
        if (this.protocolId) {
          return;
        }
        this.routerUtil.removeQueryParamsWithoutEmittingEvents();
      });
  }

  private getSettings(): void {
    this.reclamationService
      .getReclamationSettings(+this.projectId)
      .pipe(take(1))
      .subscribe((settings) => {
        this.settings = settings;
      });
  }

  private checkProjectAccess(): void {
    this.projectService.adminProject$.pipe(untilDestroyed(this)).subscribe((project) => {
      this.messageShow =
        project.currentRole !== EUserTypeAdmin.Custom ||
        project.projectAccess?.some((x) => x.accTag === EAccessTag.Reclamations);

      if (!this.messageShow) this.navigationMenu = this.navigationMenu.filter((x) => x.id !== INavMenuId.Customer);
      if (!this.messageShow && this.openTab && this.openTab === EReclamationTabAdmin.Messages) return;
      if (this.openTab) this.activeTab = this.openTab;
    });
  }

  private getProfile(): void {
    this.profileService.profile.pipe(take(1)).subscribe((profile) => {
      this.firmRole = profile?.role;
      this.currentUserId = profile?.id;
    });
  }

  public get isContractorRole(): boolean {
    return (
      this.firmRole === EFirmRoles.Contractor ||
      (this.firmRole === EFirmRoles.Member && this.projectRole === EUserTypeAdmin.Contractor)
    );
  }

  // eslint-disable-next-line complexity
  private initForm(reclamation: ReclamationsModel): void {
    this.formStatus = this.fb.group(<TReclamationsModelFormGroup>{
      status: [reclamation.status, [Validators.required]],
    });

    this.form = this.fb.group(<TReclamationsModelFormGroup>{
      contractor: [null],
      description: [
        {
          value: reclamation.description || '',
          disabled: this.isContractorRole || reclamation.archived,
        },
        Validators.maxLength(500),
      ],
      unit: [{ value: reclamation.unit, disabled: true }, Validators.required],
      category: [
        {
          value: reclamation.type?.category ? [reclamation.type.category] : [],
          disabled: this.isContractorRole || reclamation.archived,
        },
        [Validators.required],
      ],
      type: [
        {
          value: reclamation.type ? [reclamation.type] : [],
          disabled: this.isContractorRole || reclamation.archived,
        },
        [Validators.required],
      ],
      room: [
        {
          value: reclamation.room ? [reclamation.room] : [],
          disabled: this.isContractorRole || reclamation.archived,
        },
      ],
      product: [
        {
          value: reclamation.wishlistItem ? [reclamation.wishlistItem] : [],
          disabled: this.isContractorRole || reclamation.archived,
        },
      ],
      responsible: [
        {
          value: reclamation.responsible ? [reclamation.responsible] : [],
          disabled: this.isContractorRole || reclamation.archived,
        },
      ],
      reported: [{ value: reclamation.reportedDate, disabled: this.isContractorRole || reclamation.archived }, []],
      dueDate: [
        {
          value: reclamation.dueDate,
          disabled: this.isContractorRole || reclamation.archived,
        },
        Validators.required,
      ],
      attachments: [
        {
          value: reclamation.fileResources,
          disabled: false,
        },
        [],
      ],
      floorId: [reclamation.floor ? reclamation.floor.id : null, []],
      floorType: [reclamation.floor ? reclamation.floor.type : '', []],
      pointX: [reclamation.floor ? reclamation.floor.pointX : null, []],
      pointY: [reclamation.floor ? reclamation.floor.pointY : null, []],
    });
    this.preloadFiles = cloneDeep(this.form.get('attachments')?.value);
    if (this.reclamation?.archived) {
      this.form.disable();
    }
  }

  private initFormListeners(): void {
    this.form
      .get('unit')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((v: any[]) => {
        if (v.length) {
          this.unitId = v[0].id;
          this.floorPlanData = [];
          this.getUnitDetails(v[0].id);
          if (this.isContractorRole || this.reclamation?.archived) {
            this.form.get('room')?.disable({ emitEvent: false, onlySelf: true });
            this.form.get('category')?.disable({ emitEvent: true, onlySelf: true });
            this.form.get('type')?.disable({ emitEvent: true, onlySelf: true });
            this.form.get('product')?.disable({ emitEvent: false, onlySelf: true });
          } else {
            this.form.get('room')?.enable({ emitEvent: false, onlySelf: true });
            this.form.get('category')?.enable({ emitEvent: true, onlySelf: true });
            this.form.get('product')?.enable({ emitEvent: false, onlySelf: true });
          }
        } else {
          this.form.get('room')?.disable();
          this.form.get('room')?.setValue([]);
          this.rooms = [];
          this.form.get('product')?.disable();
          this.form.get('product')?.setValue([]);
        }
        this.cdr.detectChanges();
      });

    this.form
      .get('category')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((v: any[]) => {
        const reclamationCopy = cloneDeep(this.reclamation);
        if (reclamationCopy && reclamationCopy.type?.category && v.length) {
          reclamationCopy.type.category.name = v[0].name;
          this.updateReclamationHandler.emit(reclamationCopy);
        }

        if (v.length) {
          this.form.get('type')?.setValue([], { emitEvent: false, onlySelf: true });
          this.types = this.categories.find((c) => c.id === v[0].id)?.types;
          if (this.reclamation && this.reclamation.type?.id && this.firstCategoryLoad) {
            const typesArr = this.types?.filter((t) => t.id === this.reclamation?.type.id);
            this.form.get('type')?.setValue(typesArr, { emitEvent: false });
          } else {
            if (this.types?.length === 1)
              this.form.get('type')?.setValue(this.types, { emitEvent: true, onlySelf: true });
          }
          this.reclamationLoading = false;
          this.firstCategoryLoad = false;

          if (this.isContractorRole) {
            this.form.get('type')?.disable({
              onlySelf: true,
              emitEvent: false,
            });
          } else {
            this.form.get('type')?.enable({
              onlySelf: true,
              emitEvent: false,
            });
          }
        } else {
          if (this.firstCategoryLoad) {
            this.reclamationLoading = false;
            this.firstCategoryLoad = false;
          }
          this.form.get('type')?.disable({
            onlySelf: true,
            emitEvent: false,
          });
          this.types = [];
          this.form.get('type')?.setValue([], { onlySelf: true, emitEvent: false });
        }
      });

    this.form
      .get('description')
      ?.valueChanges.pipe(untilDestroyed(this), debounceTime(500))
      .subscribe((value: string) => {
        if (value) {
          this.form.get('category')?.setValidators([]);
          this.form.get('type')?.setValidators([]);
        } else {
          this.form.get('category')?.setValidators([Validators.required]);
          this.form.get('type')?.setValidators([Validators.required]);
        }
      });
  }

  private listenerForFormUpdate(): void {
    Object.keys(this.form.controls).forEach((key) => {
      if (key !== 'attachments' && key !== 'description' && key !== 'unit' && key !== 'status') {
        this.form
          .get(key)
          ?.valueChanges.pipe(untilDestroyed(this))
          .subscribe(() => {
            if (
              (key === 'category' || key === 'type') &&
              this.form.get('category')?.value.length &&
              !this.form.get('type')?.value.length
            ) {
              this.disableFormUpdate = true;
            }
            if (
              this.form.touched &&
              !this.disableFormUpdate &&
              !this.reclamationLoading &&
              !this.firstCategoryLoad &&
              !this.reclamation?.archived &&
              !this.firstContractorLoad
            ) {
              this.updateReclamation();
            }
            this.disableFormUpdate = false;
          });
      }
    });
  }

  private listenerForStatusFormUpdate(): void {
    this.formStatus
      .get('status')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((status) => {
        if (status !== this.reclamation?.status) {
          this.changeReclamationStatus(status);
        }
      });
  }

  private createFormData(): FormData {
    const formValue: Partial<TReclamationsModelFormGroup> = this.form.getRawValue();
    const due_date = this.dateAdapter.toModelYYYYMMDD(formValue.dueDate);

    const body = new FormData();

    if (this.protocolId) {
      body.append('reclamation[protocol_id]', this.protocolId.toString());
    }
    if (formValue.contractor) {
      if (formValue.contractor instanceof ContractorCompany && formValue.contractor.defaultRecipient) {
        body.append('reclamation[contractor_id]', String(formValue.contractor.defaultRecipient.id));
        body.append('reclamation[contractor_company_id]', String(formValue.contractor.id));
      } else if (formValue.contractor?.firstKeyContact) {
        body.append('reclamation[contractor_id]', formValue.contractor.firstKeyContact.mainAdminId);
        body.append('reclamation[contractor_company_id]', formValue.contractor.id);
      } else {
        body.append('reclamation[contractor_id]', formValue.contractor.adminId);
        body.append('reclamation[contractor_company_id]', formValue.contractor.companyId);
      }
    } else {
      body.append('reclamation[contractor_id]', '');
      body.append('reclamation[contractor_company_id]', '');
    }

    if (formValue.status) body.append('reclamation[status]', formValue.status);
    body.append('reclamation[description]', formValue.description);
    body.append('reclamation[unit_id]', formValue.unit[0]?.id);
    body.append('reclamation[type_id]', formValue.type[0]?.id || '');
    body.append('reclamation[room_id]', formValue.room[0]?.id || '');
    body.append('reclamation[wishlist_item_id]', formValue.product[0]?.id || '');
    body.append('reclamation[rem_responsible_in_hand_mode]', 'true');
    body.append('reclamation[responsible_id]', formValue.responsible[0]?.id || '');
    body.append('reclamation[due_date]', due_date || '');
    if (formValue.floorId && formValue.floorType) {
      body.append('reclamation[floor_id]', formValue.floorId);
      body.append('reclamation[floor_type]', formValue.floorType);
      body.append('reclamation[point_x]', formValue.pointX);
      body.append('reclamation[point_y]', formValue.pointY);
    }

    return body;
  }

  public redirectToUnit(): void {
    const url = `/base-layout/projects/specific_project/${this.projectId}/units/view/units?unitId=${this.unitId}`;
    void this.router.navigateByUrl(url);
  }

  public updateReclamation(): void {
    if (this.reclamation?.archived) return;
    this.reclamationService
      .updateProjectReclamation(this.projectId, this._reclamationId, this.createFormData())
      .pipe(take(1))
      .subscribe((reclamation: ReclamationsModel) => {
        this.reclamation = reclamation;
        this.initStatusChangeList(reclamation);
        this.updateReclamationHandler.emit(reclamation);
        this.updateMark();
      });
  }

  public updateReclamationAttachments(files: (FileModel | ImageModel)[], isDelete = false, isPosition = false): void {
    const body = new FormData();

    if (isDelete) {
      body.append('reclamation[file_resources_attributes][][_destroy]', 'true');
      body.append('reclamation[file_resources_attributes][][id]', files[0].id.toString());
    } else {
      files.forEach((file: FileModel | ImageModel) => {
        const formFile = file as unknown as File;

        if (!isPosition) body.append('reclamation[file_resources_attributes][][filename]', formFile, file.name);
        body.append(
          'reclamation[file_resources_attributes][][position]',
          file.position ? file.position.toString() : '',
        );
        body.append('reclamation[file_resources_attributes][][id]', file.id ? file.id.toString() : '');
      });
    }

    this.reclamationService
      .updateProjectReclamation(this.projectId, this._reclamationId, body)
      .pipe(untilDestroyed(this))
      .subscribe((reclamation: ReclamationsModel) => {
        reclamation.fileResources = reclamation.fileResources?.map((file) => {
          if (FileValidators.isImageExtension(file)) file.type = EFileType.Image;
          file = plainToClass(ImageModel, file);
          return file;
        });
        this.form.get('attachments')?.setValue(reclamation.fileResources);
      });
  }

  private getUnits(page = 1, allUnits: UnitUserModel[] = []): void {
    this.reclamationService
      .getUnits(this.projectId, page)
      .pipe(untilDestroyed(this))
      .subscribe((units: UnitUserModel[]) => {
        allUnits = allUnits.concat(units);
        if (units.length === this.perPage) {
          this.getUnits(page + 1, allUnits);
        } else {
          this.units = allUnits;

          if (this.reclamation && this.reclamation.unit.id) {
            const unitsArr = this.units.filter((u) => u.id === this.reclamation?.unit.id);
            if (unitsArr.length) {
              this.form.get('unit')?.setValue(unitsArr);
            } else {
              this.form.get('unit')?.setValue([this.reclamation.unit]);
            }
          }
        }
      });
  }

  private getUnitDetails(id: number): void {
    this.reclamationService
      .getUnitDetails(this.projectId, id)
      .pipe(untilDestroyed(this))
      .subscribe((unit) => {
        this.selectedUnit = unit;
        this.unitId = unit.id;
        this.setOwnersList(unit);
        this.getRooms(unit);
        this.getFloorPlan(unit);
      });
  }

  private setOwnersList(unit: UnitUserModel): void {
    this.ownersList = [];
    if (unit.mainPrimaryOwner) {
      unit.mainPrimaryOwner.role = this.translateService.instant('Entity.Main_Owner_Buyer');
      this.ownersList.push(unit.mainPrimaryOwner);
    }
    if (unit?.coOwners?.length) {
      unit.coOwners.forEach((owner) => {
        owner.role = this.translateService.instant('Shared.Entity.Co_Primary_owner');
      });
      this.ownersList.push(...unit.coOwners);
    }
  }

  private getCategories(): void {
    this.reclamationService
      .getCategories(true)
      .pipe(untilDestroyed(this))
      .subscribe((categories: IReclamationCategory[]) => {
        this.categories = categories;
      });
  }

  private getRooms(unit_user: UnitUserModel): void {
    this.rooms = [];
    if (!unit_user.layoutType) return;
    if (unit_user.rooms) this.rooms = unit_user.rooms;
  }

  getProjectAdminsRequest(pagination: ITablePagination | undefined): Observable<AdminProjectModel[]> {
    return this.projectMembersService.getProjectAdmins(
      this.projectId.toString(),
      undefined,
      undefined,
      pagination,
      [EAccessTag.Reclamations],
      [
        EProjectRoles.Custom,
        EProjectRoles.ProjectOwner,
        EProjectRoles.ProjectAdmin,
        EFirmRoles.Admin,
        EFirmRoles.PrimaryOwner,
      ],
      true,
    );
  }

  private getUsers(): void {
    const usersPagination = { ...PaginationUtil.defaultPagination };
    this.getProjectAdminsRequest(usersPagination)
      .pipe(
        untilDestroyed(this),
        expand((admins) => {
          if (admins.length === usersPagination.pageItems) {
            usersPagination.currentPage++;
            return this.getProjectAdminsRequest(usersPagination);
          }
          return EMPTY;
        }),
        map((users: AdminProjectModel[]) =>
          users.map((x) => {
            const name = x.name || x.email;
            const { iconBorderColor, iconColor } = this.randomColorService.getUserColors(name, 55, 50, true);
            return {
              ...x,
              name,
              color: iconColor,
              borderColor: iconBorderColor,
            };
          }),
        ),
        reduce(
          (allAdmins: AdminProjectModel[], currentAdmins: AdminProjectModel[]) => allAdmins.concat(currentAdmins),
          [],
        ),
      )
      .subscribe((users) => {
        this.users = users;
        if (this.reclamation && this.reclamation.responsible) {
          const currentUserId = this.reclamation.responsible.id;
          const currentUserIndex = this.users.findIndex((u) => u.id == currentUserId);
          if (currentUserId && currentUserIndex != -1) {
            this.form
              .get('responsible')
              ?.setValue([this.users[currentUserIndex]], { emitEvent: false, onlySelf: true });
          }
        }
      });
  }

  private getFloorPlan(unit_user: UnitUserModel): void {
    this.floorPlanData = [];
    this.mark = [];
    this.floorPlanData = unit_user?.mixinFloors;
    this.updateMark();
    this.form.markAllAsTouched();
  }

  private getContractors(id?: number): Observable<FirmContractorModel[]> {
    const perPage = 100;
    let page = 1;
    const contractorMembers: FirmContractorModel[] = [];
    return this.contractorService.getFirmContractors(page, perPage, id).pipe(
      untilDestroyed(this),
      expand((data) => {
        if (data.length === perPage) {
          ++page;
          return this.contractorService.getFirmContractors(page, perPage, id);
        }
        return EMPTY;
      }),
      map((val) => val.map((v) => ({ ...v, id: v.adminId.toString() + '_' + +v.companyId }))),
      map((data) => {
        contractorMembers.push(...data);
        return contractorMembers;
      }),
    );
  }

  private getCompanies(): Observable<ContractorCompany[]> {
    return this.companiesService.getCompaniesList(1, 1000, undefined, undefined, true).pipe(
      untilDestroyed(this),
      map((data) => data.filter((d) => d.firstKeyContact)),
    );
  }

  private getContractorFieldData(id?: number): void {
    forkJoin({
      companies: this.getCompanies(),
      contractors: this.getContractors(id).pipe(
        map((value) => {
          if (!this.isContractorRole) return value;
          const companyIds = [
            ...new Set(value.filter((item) => item.adminId === this.currentUserId).map((item) => item.companyId)),
          ];
          return value.filter((item) => companyIds.includes(item.companyId));
        }),
      ),
    })
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.contractors = this.isContractorRole ? data.contractors : [...data.companies, ...data.contractors];
        const contractor = this.contractors.filter((c) => {
          // @ts-ignore
          if (c?.adminId && c?.companyId) {
            // @ts-ignore
            return (
              // @ts-ignore
              c.adminId === this.reclamation?.contractor?.id && c.companyId === this.reclamation?.contractorCompany?.id
            );
          }
          return false;
        });
        if (contractor?.length) {
          this.form.get('contractor')?.setValue(contractor[0], { emitEvent: !this.firstContractorLoad });
        }
        this.firstContractorLoad = false;
        this.cdr.detectChanges();
      });
  }

  private updateMark(): void {
    if (this.reclamation && this.reclamation.floor) {
      if (this.reclamation.floor.pointX === null || this.reclamation.floor.pointY === null) {
        this.mark = [];
        return;
      }
      const markObj = {
        floorId: this.reclamation.floor?.id,
        floorNumber: this.reclamation.floor?.floorNumber,
        floorType: this.reclamation.floor.type,
        mark: [
          {
            x: this.reclamation.floor?.pointX,
            y: this.reclamation.floor?.pointY,
          },
        ],
      };

      this.mark = [markObj as unknown as IMark];
      this.updateReclamationPlan.emit(this.reclamation);
    }
  }

  public onRemoveSelectedItem(control: string, item: any, compareProp = 'id'): void {
    const items: any[] = this.form.get(control)?.value;
    const foundIndex = items.findIndex((a) => a[compareProp] === item[compareProp]);
    if (foundIndex !== -1) {
      items.splice(foundIndex, 1);
      this.form.get(control)?.setValue([...items]);
    }
  }

  updateFilePositions(files: (FileModel | ImageModel)[]): void {
    const filesArr = files.map((f: FileModel | ImageModel, index: number) => {
      return {
        ...f,
        position: index + 1,
      };
    });
    this.form.get('attachments')?.setValue(filesArr);
    this.updateReclamationAttachments(filesArr as (FileModel | ImageModel)[], false, true);
  }

  removeExistingFile(file: FileModel | ImageModel): void {
    this.updateReclamationAttachments([file], true);
  }

  public openActivities(): void {
    this.activeTab = EReclamationTabAdmin.Activities;
  }

  public openCustomerMessages(): void {
    this.activeTab = EReclamationTabAdmin.Messages;
  }

  public selectAnotherTab(e: { tabName: EReclamationTabAdmin }, upload = false): void {
    if (e.tabName === EReclamationTabAdmin.Messages && this.reclamation?.status === this.statusKeys.Draft) return;
    if (e.tabName === this.activeTab) {
      return;
    }
    this.activeTab = e.tabName;
    if (this.activeTab !== EReclamationTabAdmin.InternalNotes) {
      this.internalActiveTab = null;
    }
    if (this.activeTab === EReclamationTabAdmin.General) {
      this.disableFormUpdate = true;
      of(true)
        .pipe(delay(0))
        .subscribe(() => {
          this.disableFormUpdate = false;
          if (upload) this.fileDropdownRef.handleUpload();
        });
    }
    if (this.activeTab === EReclamationTabAdmin.Messages) {
      this.openMessagesTabEmit.emit(this._reclamationId);
    } else {
      this.openMessagesTabEmit.emit(null);
    }
    this.lockFieldService.setLocked(false);
  }

  public closeModal(): void {
    if (this.componentView) {
      this.closeReclamationEmit.emit();
    } else {
      if (this.modalRef) {
        this.modalRef?.close();
      } else {
        this.modalService?.close();
      }
    }
  }

  public onChangeReclamation(state: EPositionControl): void {
    if (this.disableSwitchingReclamation || this.reclamationLoading || this.firstContractorLoad) {
      return;
    }
    if (this.reclamation) this.reclamation.activities = [];
    this.reclamation = null;
    this.floorPlanData = [];
    this.firstCategoryLoad = true;
    this.firstReclamationLoad = true;
    this.firstContractorLoad = true;

    this.mark = [];
    if (this.activeTab !== EReclamationTabAdmin.General) {
      this.selectAnotherTab({ tabName: this.reclamationTabs.General });
    }
    this.changeReclamationHandler.next(state);
  }

  public openPreview(index: number): void {
    const files = this.form.controls.attachments.value ?? [];
    this.modalHelpersService.previewImages(files, index);
  }

  public setMark(markArr: IMark[]): void {
    if (markArr.length && this.floorPlanData) {
      this.form.markAllAsTouched();

      this.form.patchValue(
        {
          floorNumber: markArr[0].floorNumber,
          floorId: markArr[0].floorId,
          floorType: markArr[0].floorType,
          pointX: markArr[0].mark[0].x,
          pointY: markArr[0].mark[0].y,
        },
        { emitEvent: false },
      );

      this.updateReclamation();
    }
  }

  public changeReclamationStatus(status: EReclamationStatusKey, force = false): void {
    if (status === EReclamationStatusKey.Declined || status === EReclamationStatusKey.Disputed) {
      this.openCommentModal(false, status, force);
    } else {
      this.changeReclamationStatusRequest(status, force);
    }
    this.close();
  }

  changeReclamationStatusRequest(status: EReclamationStatusKey, force = false): void {
    this.isLoading = true;
    const previousStatus = this.reclamation?.status;
    this.reclamationService
      .changeReclamationStatus(+this.projectId, this._reclamationId, status, force)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          if (this.reclamation) {
            this.updateReclamationHandler.emit(response);
            if (
              this.reclamation.status == EReclamationStatusKey.Pending &&
              !this.reclamation.dueDate &&
              !this.reclamation?.archived
            ) {
              this.setDueDate();
            }
            this.reclamation = cloneDeep(response);
            this.initStatusChangeList(response);
            this.statusButtons = this.filterStatusButton(this.reclamation.availableStatuses);
            if (previousStatus === EReclamationStatusKey.Draft) {
              this.navigationMenu = navigationMenu;
              this.cloneNavMenu();
            }
            this.changeStatusEmit.emit();
          }
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        },
      );
  }

  setDueDate(): void {
    this.disableFormUpdate = true;
    const fd = new FormData();
    fd.append('reclamation[due_date]', this.defaultDueDate || '');
    this.reclamationService
      .updateProjectReclamation(this.projectId, this._reclamationId, fd)
      .pipe(untilDestroyed(this))
      .subscribe((reclamation) => {
        if (this.reclamation) {
          this.reclamation.dueDate = reclamation.dueDate;
        }
        this.form.get('dueDate')?.setValue(this.dateAdapter.fromModel(this.defaultDueDate), { emitEvent: false });

        this.disableFormUpdate = false;
      });
  }

  openCommentModal(onlyView = false, status: EReclamationStatusKey, force = false): void {
    const modalRef = this.modalFacadeService.openModal(ReclamationAdminDeclineComponent, {
      ...this.sharedUiStorageService.modalDeclineReclamation,
      injector: this.injector,
    });
    modalRef.componentInstance.projectId = this.projectId;
    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.reclamationId = this._reclamationId;
    modalRef.componentInstance.onlyView = onlyView;
    modalRef.componentInstance.status = status;
    modalRef.componentInstance.force = force;
    if (onlyView) modalRef.componentInstance.reclamationEventLog = this.reclamation?.lastEventLog;
    modalRef.componentInstance.declineEvent.pipe(untilDestroyed(this)).subscribe(() => {
      this.firstCategoryLoad = true;
      this.firstReclamationLoad = true;
      this.getReclamation();
    });
  }

  public close(): void {
    this.expanded = false;
  }

  public toggle(): void {
    this.expanded = !this.expanded;
  }

  public copyUrl(): void {
    let params = '';
    if (this.reclamationScope === this.reclamationScopesList.Units) {
      params = '?unitId=' + this.unitModalId + '&reclamations=' + this._reclamationId;
    } else if (this.reclamationScope === this.reclamationScopesList.Global) {
      params = '?projectId=' + this.projectId + '&reclamationId=' + this._reclamationId;
    } else {
      params = '?reclamationId=' + this._reclamationId;
    }
    this.pageHelperService.copyToClipboard(params);
    this.messageBannerService.triggerShowBanner('Shared.Entity.Link_copied');
    this.close();
  }

  public openUpload(): void {
    if (this.reclamation?.archived) return;
    if (this.activeTab !== EReclamationTabAdmin.General) {
      this.selectAnotherTab({ tabName: this.reclamationTabs.General }, true);
    } else {
      this.fileDropdownRef.handleUpload();
    }
  }
}
