import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapterService, InlineDateTimePickerComponent, MessageModalComponent } from '@atlas-workspace/shared/form';
import { ModalFacadeService, ModalHelpersService, NewestConfirmModalComponent } from '@atlas-workspace/shared/modals';
import {
  ChangeRequestModel,
  ChangeRequestOfferModel,
  EChangeRequestStatus,
  FloorModel,
  IFloorType,
  offerStatusList,
  UnitFloorModel,
  UnitUserModel,
} from '@atlas-workspace/shared/models';
import { ChangeRequestService, SharedUiStorageService } from '@atlas-workspace/shared/service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { finalize, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'atl-offer-detail',
  templateUrl: './offer-detail.component.html',
  styleUrls: ['./offer-detail.component.scss', '../../../styles/status.component.scss'],
})
export class OfferDetailComponent {
  @Input() set editOffer(offer: ChangeRequestOfferModel) {
    this.offer = offer;
    if (!this.form) {
      this.initForm();
    }
    this.setForm();
  }

  @Input() public readonly changeRequest!: ChangeRequestModel;
  @Input() private readonly projectId!: string;
  @Input() readonly disabledNewOffer!: boolean;
  @Input() requestIsDeclined!: boolean;
  @Input() floorPlanData!: UnitFloorModel[] | FloorModel[] | any;
  @Input() unit!: UnitUserModel;
  @Input() readonly unitId!: number;

  @Output() private readonly back = new EventEmitter<ChangeRequestOfferModel>();
  @Output() private readonly updateOffer = new EventEmitter<ChangeRequestOfferModel>();
  @Output() private readonly removeOfferHandler = new EventEmitter<number>();
  @Output() private readonly duplicateHandler = new EventEmitter<ChangeRequestOfferModel>();

  @ViewChild('datePicker') datePicker!: InlineDateTimePickerComponent;

  public readonly floorType = IFloorType;
  public offer!: ChangeRequestOfferModel;
  public form!: FormGroup;
  public buttonDisable = true;
  public isLoading = false;
  public isShowMessageBanner = false;
  public readonly titleMaxLength = 50;
  public readonly descriptionMaxLength = 800;
  public readonly statusClasses = offerStatusList;
  public readonly messageBannerText = 'Change_request.Offers.Date_changed';
  public readonly statusKeys = EChangeRequestStatus;
  public readonly currentDate = new Date();

  constructor(
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly dateAdapter: DateAdapterService,
    private readonly changeRequestService: ChangeRequestService,
    private readonly modalHelpersService: ModalHelpersService,
    private readonly modalFacadeService: ModalFacadeService,
    private readonly translateService: TranslateService,
    private readonly sharedUiStorageService: SharedUiStorageService
  ) {}

  public backOffersList(): void {
    this.back.emit(this.offer);
  }

  private initForm(): void {
    this.form = this.fb.group({
      title: ['', [Validators.maxLength(this.titleMaxLength), Validators.required]],
      description: [{ value: '', disabled: true }, Validators.maxLength(this.descriptionMaxLength)],
      price: ['', Validators.required],
      expirationDate: [''],
      fileResources: [[]],
    });

    this.dateChangeDetection();
  }

  private setForm(): void {
    this.form.patchValue({
      title: this.offer.title,
      description: this.offer.description,
      price: this.offer.price || '0',
      expirationDate: this.offer.expirationDate,
      fileResources: this.offer.fileResources,
    });

    if (this.offer.status !== EChangeRequestStatus.OfferExpired) {
      this.form.get('expirationDate')?.disable();
    } else {
      this.form.get('expirationDate')?.enable();
    }
    this.cdr.detectChanges();
  }

  private dateChangeDetection(): void {
    this.form
      .get('expirationDate')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((date) => {
        const expirationDate = this.dateAdapter.toModel(date);
        const status = this.offer.status !== EChangeRequestStatus.OfferExpired;
        const changed = this.offer.expirationDate === expirationDate;
        this.buttonDisable = status ? status : changed;
      });
  }

  public saveOffer(): void {
    const date = this.dateAdapter.toModelYYYYMMDD(this.form.get('expirationDate')?.value);

    this.isLoading = true;

    this.changeRequestService
      .updateOffer(this.projectId, this.changeRequest.id, this.offer.id, date)
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((offer) => {
        this.offer = offer;
        this.setForm();
        this.datePicker.changeValue(this.offer.expirationDate);
        this.displayMessageBanner();
      });
  }

  private displayMessageBanner(): void {
    this.isShowMessageBanner = true;
  }

  public hideMessageBanner(): void {
    this.isShowMessageBanner = false;
  }

  public removeOffer(): void {
    this.removeOfferHandler.emit(this.offer.id);
  }

  public setDuplicate(): void {
    this.duplicateHandler.emit(this.offer);
  }

  public openPreview(index: number): void {
    const files = this.form.get('fileResources')?.value ?? [];
    this.modalHelpersService.previewImages(files, index);
  }

  public confirmCancelOffer(): void {
    const confirmModalRef = this.modalFacadeService.openModal(NewestConfirmModalComponent, {
      centered: true,
      windowClass: 'confirm-action-modal-v2',
    });

    confirmModalRef.componentInstance.title = `${this.translateService.instant('Button.Cancel_offer')}?`;
    confirmModalRef.componentInstance.description = this.translateService.instant('Change_request.Offers.Cancel.Title');
    confirmModalRef.componentInstance.button.text = this.translateService.instant('Button.Yes_cancel');
    confirmModalRef.componentInstance.icon = 'basket.svg';

    this.canselOfferHandler(confirmModalRef);
  }

  private canselOfferHandler(modalRef: NgbModalRef): void {
    modalRef.componentInstance.passConfirm.pipe(untilDestroyed(this)).subscribe((v: boolean) => {
      if (v) {
        modalRef.close();
        this.cancelOffer(EChangeRequestStatus.OfferCanceled);
      }
    });
  }

  private cancelOffer(status: EChangeRequestStatus): void {
    this.changeRequestService
      .setStatusOffer(this.projectId, this.changeRequest.id, this.offer.id, status)
      .pipe(take(1))
      .subscribe((offer) => {
        this.offer = offer;
        this.updateOffer.emit(offer);
        this.setForm();
        this.datePicker.changeValue(this.offer.expirationDate);
      });
  }

  public showComment(): void {
    const modalRef = this.modalFacadeService.openModal(
      MessageModalComponent,
      this.sharedUiStorageService.modalCreateOffer
    );

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.title = 'Shared.Title.Offer_was_declined';
    modalRef.componentInstance.button = 'Shared.Yes_decline';
    modalRef.componentInstance.placeholder = 'Change_request.Decline.Modal.Placeholder';
    modalRef.componentInstance.comment = this.offer.title;
    modalRef.componentInstance.onlyView = true;
  }
}
