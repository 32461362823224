import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class SharedUiStorageService {
  modalRightSideSetting: NgbModalOptions = {
    scrollable: true,
    backdrop: false,
    windowClass: 'custom-modal-main custom-modal-main--without-backdrop-small',
    modalDialogClass: 'custom-modal--right',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalRightSideContact: NgbModalOptions = {
    scrollable: true,
    backdrop: false,
    windowClass: 'custom-modal-main custom-modal-main--without-backdrop-contact',
    modalDialogClass: 'custom-modal--right',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalAdminRightSideSetting: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    keyboard: false,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-admin',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    backdropClass: 'transparent-overlay',
  };

  modalAdminRightSideProjectSetting: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    keyboard: false,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-admin-medium',
    backdropClass: 'transparent-overlay',
  };

  modalAdminUpdateAddTemplate: NgbModalOptions = {
    scrollable: true,
    backdrop: false,
    windowClass: 'custom-modal-main',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    modalDialogClass: 'custom-modal--right custom-modal--right-admin-large',
    backdropClass: 'transparent-overlay',
  };

  modalAdminUnitModal: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-admin-unit-modal',
    backdropClass: 'transparent-overlay',
  };

  modalOptionStoreModal: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    keyboard: false,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-admin-option-store-modal',
    backdropClass: 'transparent-overlay',
  };

  modalOptionStoreModalOldWidth: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    keyboard: false,
    windowClass: 'custom-modal-main',
    // eslint-disable-next-line max-len
    modalDialogClass:
      'custom-modal--right custom-modal--right-admin-option-store-modal custom-modal--right-admin-option-store-modal-old-width',
    backdropClass: 'transparent-overlay',
  };

  modalClientRightSide: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    keyboard: false,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-mobile custom-modal--right-admin-client-right-side',
    backdropClass: 'transparent-overlay',
  };

  modalRoomsTemplatePreviewModal: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    keyboard: false,
    centered: true,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'centered-modal-768',
  };

  modalCenteredModal: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    keyboard: false,
    centered: true,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'centered-modal-admin-768',
  };

  modalOptionStoreModalCreation: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    keyboard: false,
    centered: true,
    windowClass: 'custom-modal-main',
    modalDialogClass:
      'custom-modal--right custom-modal--right-admin-option-store-modal custom-modal--right-admin-option-store-modal-creation',
  };

  modalOptionPackage: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    keyboard: false,
    centered: true,
    windowClass: 'custom-modal-main',
    modalDialogClass:
      'custom-modal--right custom-modal--right-admin-option-store-modal custom-modal--right-admin-option-store-modal-package',
  };

  modalClientBookTime: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    keyboard: false,
    centered: true,
    windowClass: 'custom-modal-main',
    modalDialogClass:
      'custom-modal--right custom-modal--right-admin-option-store-modal custom-modal--right-admin-meeting-book-time',
  };

  modalAdminRightSideMiddle: NgbModalOptions = {
    scrollable: true,
    backdrop: false,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-admin-large',
    backdropClass: 'custom-modal-overlay custom-modal-overlay',
  };

  modalAdminDeleteSetting: NgbModalOptions = {
    centered: true,
    backdrop: false,
    windowClass: 'modal-delete',
    modalDialogClass: 'custom-modal--delete',
  };

  modalResetSent: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-reset-password ',
  };

  modalImportProductConfig: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-import-product',
  };

  modalConfirmDesignLineConfig: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-confirm-design-line',
  };

  modalPriceSummary: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-price-summary',
  };

  modalNotification: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-notification',
  };

  modalNotificationNew: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-notification-new',
  };

  modalAdminRightInviteSetting: NgbModalOptions = {
    scrollable: true,
    backdrop: false,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-invite',
    backdropClass: 'white-overlay',
  };

  modalAdminRightMembersSetting: NgbModalOptions = {
    scrollable: true,
    backdrop: false,
    windowClass: 'custom-modal-main',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    modalDialogClass: 'custom-modal--right custom-modal--right-member',
    backdropClass: 'default-overlay',
  };

  modalAdminRightSubModalMedium: NgbModalOptions = {
    scrollable: true,
    backdrop: false,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-member',
    backdropClass: 'default-overlay',
  };

  modalAdminRightSubModalMd: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-member',
    backdropClass: 'default-overlay-md',
  };

  modalAdminRightTransferSetting: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-member',
    backdropClass: 'transparent-overlay',
  };

  modalClientConstructionSetting: NgbModalOptions = {
    centered: true,
    scrollable: false,
    backdrop: true,
    windowClass: 'custom-modal-construction',
  };

  modalClientHomeSetting: NgbModalOptions = {
    centered: true,
    scrollable: false,
    backdrop: true,
    windowClass: 'custom-modal-construction custom-modal-home',
  };

  modalClientHomeConstruction: NgbModalOptions = {
    centered: true,
    scrollable: false,
    backdrop: true,
    windowClass: 'custom-modal-construction custom-modal-home custom-modal-home-camera',
  };

  modalAdminRightSideWideSetting: NgbModalOptions = {
    scrollable: true,
    backdrop: false,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-wide',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalAdminRightSideTemplateSetting: NgbModalOptions = {
    scrollable: true,
    backdrop: false,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-template',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalRightClientSetting: NgbModalOptions = {
    scrollable: true,
    backdrop: false,
    windowClass: 'custom-modal-main custom-modal-main--without-backdrop-large',
    modalDialogClass: 'custom-modal--right custom-modal--right-client',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalRightFullHeightSetting: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-admin custom-modal--shadow',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalProductCardSettingMedium: NgbModalOptions = {
    backdrop: true,
    keyboard: false,
    windowClass: 'custom-modal-main custom-modal-product',
    modalDialogClass: 'custom-modal--right custom-modal--right-product custom-modal--shadow',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalProductLibrarySetting: NgbModalOptions = {
    backdrop: true,
    keyboard: false,
    windowClass: 'custom-modal-main custom-modal-product-library',
    modalDialogClass: 'custom-modal--right custom-modal--right-product-library custom-modal--shadow',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalProductLibraryPreview: NgbModalOptions = {
    backdrop: true,
    keyboard: false,
    windowClass: 'custom-modal-main custom-modal-product-library',
    modalDialogClass: 'custom-modal custom-modal--product-library-preview custom-modal--shadow',
  };

  modalAdminRightSetting: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    windowClass: 'custom-modal-main modal-admin--left',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalClientRightSetting: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    windowClass: 'custom-modal-main modal-admin--left client-radius',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalAdminCenteredSettingLg: NgbModalOptions = {
    centered: true,
    scrollable: true,
    backdrop: true,
    windowClass: 'custom-modal-main centered-modal-admin-1080',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalAdminCenteredSettingSm: NgbModalOptions = {
    centered: true,
    scrollable: true,
    backdrop: false,
    windowClass: 'custom-modal-main centered-modal-admin-825',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalChatDocuments: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    windowClass: 'custom-modal-main custom-modal-chat-documents',
    modalDialogClass: 'custom-modal--right custom-modal--right-chat-documents',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalProjectChatDocuments: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    windowClass: 'custom-modal-main custom-modal-chat-documents',
    modalDialogClass: 'custom-modal--right custom-modal--right-chat-project-documents',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalUnitChatDocuments: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    windowClass: 'custom-modal-main custom-modal-chat-documents',
    modalDialogClass: 'custom-modal--right custom-modal--right-unit-chat-documents',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalReclamationChatDocuments: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    windowClass: 'custom-modal-main custom-modal-chat-documents',
    modalDialogClass: 'custom-modal--right custom-modal--right-reclamation-chat-documents',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalClientCenteredLg: NgbModalOptions = {
    centered: true,
    scrollable: true,
    backdrop: true,
    windowClass: 'custom-modal-main centered-modal-1152',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    backdropClass: 'custom-modal-overlay',
  };

  modalClientCenteredMd: NgbModalOptions = {
    centered: true,
    scrollable: true,
    backdrop: true,
    windowClass: 'custom-modal-main centered-modal-944',
    backdropClass: 'custom-modal-overlay',
  };

  modalClientCenteredELg: NgbModalOptions = {
    centered: true,
    scrollable: true,
    backdrop: true,
    windowClass: 'custom-modal-main centered-modal-1344',
    backdropClass: 'custom-modal-overlay',
  };

  modalCreateSurvey: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-admin-medium',
    backdropClass: 'transparent-overlay',
  };

  modalResultSurvey: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-admin-large',
    backdropClass: 'transparent-overlay',
  };

  modalChatSelection: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-admin-medium custom-modal--right-chat-multi-select',
    backdropClass: 'transparent-overlay',
  };

  modalCreateThread: NgbModalOptions = {
    scrollable: false,
    backdrop: false,
    keyboard: false,
    windowClass: 'custom-modal-main new-thread-window',
    modalDialogClass: 'custom-modal--right new-thread-modal new-thread-modal-client',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalCreateThreadClient: NgbModalOptions = {
    scrollable: false,
    backdrop: false,
    keyboard: false,
    windowClass: 'custom-modal-main new-thread-window-V2',
    modalDialogClass: 'custom-modal--right new-thread-modal new-thread-modal-client-V2',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalCreateThreadUnits: NgbModalOptions = {
    scrollable: false,
    windowClass: 'custom-modal-main w-100 h-100',
    modalDialogClass: 'custom-modal--right new-thread-units-modal',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalCreateThreadProject: NgbModalOptions = {
    scrollable: false,
    keyboard: false,
    backdrop: false,
    windowClass: 'custom-modal-main w-100 h-100 project-window-picker',
    modalDialogClass: 'custom-modal--right new-thread-project-modal',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalCreateThreadMobile: NgbModalOptions = {
    scrollable: false,
    backdrop: false,
    windowClass: 'custom-modal-main new-thread-window-mobile new-thread-modal-client',
    modalDialogClass: 'custom-modal--right new-thread-modal',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalThreadAttachments: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    windowClass: 'custom-modal-main custom-modal-thread-attachments',
    modalDialogClass: 'custom-modal--right custom-modal--right-thread-attachments custom-modal--shadow',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalReclamation: NgbModalOptions = {
    backdrop: true,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-reclamation custom-modal--shadow',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalProtocollReclamation: NgbModalOptions = {
    backdrop: true,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-protocol-reclamation custom-modal--shadow zoom-element',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalProtocollDetailReclamation: NgbModalOptions = {
    backdrop: true,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-protocol-detail-reclamation custom-modal--shadow',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalDeclineReclamation: NgbModalOptions = {
    backdrop: true,
    centered: true,
    windowClass: 'confirm-action-modal-v2',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    modalDialogClass: 'custom-modal custom-modal--shadow',
    backdropClass: 'custom-modal-overlay',
  };

  modalReclamationsList: NgbModalOptions = {
    backdrop: true,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-reclamation unit-list custom-modal--shadow',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalCreateOffer: NgbModalOptions = {
    backdrop: true,
    centered: true,
    windowClass: 'new-offer-modal',
    modalDialogClass: 'custom-modal custom-modal--shadow',
    backdropClass: 'custom-modal-overlay',
  };

  modalClientCreateOffer: NgbModalOptions = {
    backdrop: true,
    centered: true,
    windowClass: 'new-offer-modal client-radius',
    modalDialogClass: 'custom-modal custom-modal--shadow',
    backdropClass: 'custom-modal-overlay',
  };

  modalEditor: NgbModalOptions = {
    scrollable: true,
    backdrop: true,
    keyboard: false,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--left custom-modal--left__modal-text-editor',
    backdropClass: 'transparent-overlay',
  };

  modalConnectedUnits: NgbModalOptions = {
    backdrop: true,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--right custom-modal--right-connected-units custom-modal--shadow',
    backdropClass: 'custom-modal-overlay custom-modal-overlay--hidden',
  };

  modalCenterRules: NgbModalOptions = {
    backdrop: true,
    windowClass: 'custom-modal-main',
    modalDialogClass: 'custom-modal--center custom-modal--rules-list custom-modal--shadow',
    backdropClass: 'custom-modal-overlay',
  };

  modalRulesEdit: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-rules-edit ',
  };
}
