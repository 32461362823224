import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DocumentModel, UpdateModel } from '@atlas-workspace/shared/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ModalHelpersService } from '../../services/modal-helpers.service';

interface ICustomFile extends File {
  fileExtension: string;
}
@Component({
  selector: 'atl-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewModalComponent implements OnInit {
  @Input() public readonly update!: UpdateModel;
  @Input() public readonly unsavedFiles: ICustomFile[] = [];
  @Input() public readonly title = 'Title.Newsletter_preview';

  public readonly maxNameLength = 12;
  public readonly tooltipDelay = 500;
  constructor(
    private activeModal: NgbActiveModal,
    private modalHelpersService: ModalHelpersService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initFileAdaptor();
    this.cdr.markForCheck();
  }

  closeModal(): void {
    this.activeModal.close();
  }

  onPreviewDocument(document: DocumentModel): void {
    if (!document.id) {
      return;
    }
    this.modalHelpersService.previewDocument(document);
  }

  private initFileAdaptor(): void {
    if (!this.update.id) {
      this.update.fileResources?.forEach((file: any) => {
        const extension = file.name.split('.').pop();
        file.fileExtension = extension;
      });
    }
    for (const file of this.unsavedFiles) {
      if (!file.fileExtension) {
        const extension = file.name.split('.').pop();
        file.fileExtension = extension as string;
      }
    }
  }
}
